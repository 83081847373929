import { useEffect, useState } from "react";
import { API_URL } from "../../opl-utilities/constants";
import ButtonTextIcon from "../../components/common/ui/ButtonTextIcon";
import LoaderIcon from "../../components/common/ui/LoaderIcon";
import { NavLink } from "react-router-dom";
import ButtonTertiary from "../../components/common/ui/ButtonTertiary";
import { decodeModuleAccessFromToken, formatDate, getUserTypeInfo } from "../../opl-utilities/helpers";
import ButtonLightTextIcon from "../../components/common/ui/ButtonLightTextIcon";
import FileViewer from "../../components/common/ui/FileViewer";
import CustomModal from "../../components/common/CustomModal";
import DefaultUserImage from "../../static/default-image.png";

const UserView = ({ ueserid, setViewActivity, redirectUrl, editbutton=true }) => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const permissions = decodeModuleAccessFromToken("users");

  const fetchUserData = async (id) => {
    try {
      const resp = await fetch(API_URL + `/api/1.0.0/users/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-JWT-Assertion": localStorage.getItem("token"),
        },
      });
      if (resp.status === 401 || resp.status === 403) {
        if (resp.status === 401) {
          localStorage.clear();
          window.location.href = "/login";
        } else if (resp.status === 403) {
          setLoading(false);
          setError("Access denied. You do not have permission to perform this action.");
        }
        return false;
      }
      if (resp.ok) {
        const response = await resp.json();
        if (response) {
          setUserData(response.data); 
        }
      }
    } catch (e) {
      setError("Failed to fetch activity data.");

      console.log("Error:", e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserData(ueserid);
  }, [ueserid]);

 
  const InfoItem = ({ icon, label, name, ...rest }) => {
    return (
      <div {...rest}>
        <div className="flex center-left" style={{ gap: "10px" }}>
          <div>{icon}</div>
          <div style={{ overflow: "hidden" }}>
            <p
              className="f-w-300"
              style={{
                color: "#aaaaaa",
                fontSize: "10px",
                lineHeight: 1,
                paddingBottom: "2px",
              }}
            >
              {label}
            </p>
            <p
              style={{
                width: "200px",
                lineHeight: 1.2,
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {name}
            </p>
          </div>
        </div>
      </div>
    );
  };
  const getRandomColor = () => {
    const array = ["#84B8A4", "#72B5E5", "#CBA5DB", "#CFA96D", "#E8823D"];
    const randomIndex = Math.floor(Math.random() * array.length);
    return array[randomIndex];
  };

  return (
    <section id="opl_coremax">
      <section id="custom-modal">
        <div className="inner">
          <div className="box" id="activity-detail-box">
            <div className="flex center-left">
              <div>
                <h6 className="color-primary f-w-300">User Details</h6>
              </div>
              <div style={{ marginLeft: "auto" }}>
                <ButtonLightTextIcon
                  title="Cancel"
                  onClick={() => {
                    setViewActivity(false);
                  }}
                  icon={
                    <svg
                      clipRule="evenodd"
                      fillRule="evenodd"
                      strokeLinejoin="round"
                      strokeMiterlimit="2"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      width='19.995'
                      height='19.996'
                    >
                      <path
                        d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 1.5c-4.69 0-8.497 3.807-8.497 8.497s3.807 8.498 8.497 8.498 8.498-3.808 8.498-8.498-3.808-8.497-8.498-8.497zm0 7.425 2.717-2.718c.146-.146.339-.219.531-.219.404 0 .75.325.75.75 0 .193-.073.384-.219.531l-2.717 2.717 2.727 2.728c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.384-.073-.53-.219l-2.729-2.728-2.728 2.728c-.146.146-.338.219-.53.219-.401 0-.751-.323-.751-.75 0-.192.073-.384.22-.531l2.728-2.728-2.722-2.722c-.146-.147-.219-.338-.219-.531 0-.425.346-.749.75-.749.192 0 .385.073.531.219z"
                        fillRule="nonzero" 
                      />
                    </svg>
                  }
                />
              </div>
            </div>
            <div
              style={{
                height: "60vh",
                margin: "10px",
                overflowY: "scroll",
                padding: "20px 5px",
                overflowX: "hidden",
                scrollbarWidth: "thin",
              }}
            >
              {loading && (
                <div
                  className="col-1-1"
                  style={{
                    height: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoaderIcon
                    style={{
                      width: "20px",
                      height: "20px",
                    }}
                  />
                </div>
              )}
              {!loading && error && (
                <p
                  className="f-w-300 color-primary"
                  style={{ color: "#c0392b" }}
                >
                  {error}
                </p>
              )}
              {userData && !loading && !error && (
                <div className="activity-card pos-rel">
                  <div
                    style={{
                      position: "absolute",
                      content: "",
                      height: "4px",
                      left: "-1px",
                      right: "-1px",
                      bottom: "-2px",
                      borderBottomLeftRadius: "4px",
                      borderBottomRightRadius: "4px",
                    }}
                  ></div>
                  <div>
                    <div
                      className="info-row flex center-center"
                      style={{
                        gap: "10px",
                        paddingBottom: "20px",
                      }}
                    >
                      <InfoItem
                        className="col-1-1 info-row-column"
                        icon={
                          <img
                            src={
                            	userData?.userDAO?.imageS3Url ||DefaultUserImage
                            }
                            alt={userData?.userDAO.name}
                            style={{
                              width: "55px",
                              height: "55px",
                              borderRadius: "50%",
                              objectFit: "cover",
                            }}
                          />
                        }
                        label={"Name"}
                        name={userData?.userDAO?.name + " " + userData?.userDAO?.lastName || "NA"}
                      />
                    </div>
                  </div>
                  <div>
                    <div
                      className="info-row flex center-center"
                      style={{
                        gap: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      <InfoItem
                        className="col-1-1 info-row-column"
                        icon={
                          <svg
                            color={getRandomColor()}
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            style={{
                              height: "20px",
                              width: "20px",
                            }}
                          >
                            <path d="M24 21h-24v-18h24v18zm-23-16.477v15.477h22v-15.477l-10.999 10-11.001-10zm21.089-.523h-20.176l10.088 9.171 10.088-9.171z" />
                          </svg>
                        }
                        label={"Email"}
                        name={userData?.userDAO?.email || "NA"}
                      />
                      <InfoItem
                        className="col-1-1 info-row-column"
                        icon={
                          <svg
                            color={getRandomColor()}
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            style={{
                              height: "20px",
                              width: "20px",
                            }}
                          >
                            {" "}
                            <path d="M19 7V4a1 1 0 0 0-1-1H5a2 2 0 0 0 0 4h15a1 1 0 0 1 1 1v4h-3a2 2 0 0 0 0 4h3a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1"></path>
                            <path d="M3 5v14a2 2 0 0 0 2 2h15a1 1 0 0 0 1-1v-4"></path>
                          </svg>
                        }
                        label={"User Type"}
                        name={getUserTypeInfo(userData?.userDAO?.type)?.label || "--"}//userData?.userDAO?.type || "NA"}
                      />
                    </div>
                  </div>
                  <div>
                    <div
                      className="info-row flex center-center"
                      style={{
                        gap: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      <InfoItem
                        className="col-1-1 info-row-column"
                        icon={
                          <svg
                            color={getRandomColor()}
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            style={{
                              height: "20px",
                              width: "20px",
                            }}
                          >
                            <path d="M8 2v4"></path>
                            <path d="M16 2v4"></path>
                            <rect
                              width="18"
                              height="18"
                              x="3"
                              y="4"
                              rx="2"
                            ></rect>
                            <path d="M3 10h18"></path>
                          </svg>
                        }
                        label={"Active Date"}
                        name={userData?.userDAO?.activeStart?formatDate(userData.userDAO.activeStart) : "NA"}
                      />
                      <InfoItem
                        className="col-1-1 info-row-column"
                        icon={
                          <svg
                            color={getRandomColor()}
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            style={{
                              height: "20px",
                              width: "20px",
                            }}
                          >
                            <path d="M8 2v4"></path>
                            <path d="M16 2v4"></path>
                            <rect
                              width="18"
                              height="18"
                              x="3"
                              y="4"
                              rx="2"
                            ></rect>
                            <path d="M3 10h18"></path>
                          </svg>
                        }
                        label={"Active End"}
                        // name={userData?.userDAO?.activeEnd || "NA"}
                        name={userData?.userDAO?.activeEnd?formatDate(userData.userDAO.activeEnd) : "NA"}
                      />
                    </div>
                  </div>
                  <div>
                    <div
                      className="info-row flex center-center"
                      style={{
                        gap: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      <InfoItem
                        className="col-1-1 info-row-column"
                        icon={
                          <svg
                            color={getRandomColor()}
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            style={{
                              height: "20px",
                              width: "20px",
                            }}
                          >
                            <path
                              d="M15 3.51212C17.5687 4.42004 19.6022 6.461 20.5 9.03451M11 3.05493C6.50005 3.55238 3 7.36745 3 12C3 13.7711 3.51156 15.4226 4.39499 16.815L3 21L7.18497 19.605C8.57736 20.4884 10.2289 21 12 21C16.6326 21 20.4476 17.5 20.9451 13"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                            />
                            <path
                              d="M12 17C9.23858 17 7 14.7614 7 12M8.99951 8C9.83526 7.37209 10.8742 7 12 7C14.7614 7 17 9.23858 17 12C17 13.1258 16.6279 14.1647 16 15.0005"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                            />
                            <circle
                              cx="12"
                              cy="12"
                              r="1"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                            />
                          </svg>
                        }
                        label={"status"}
                        name={userData?.userDAO?.isActive === "1" ? "Active" : "Inactive"}
                      />
                      <InfoItem
                        className="col-1-1 info-row-column"
                        icon={
                          <svg
                            color={getRandomColor()}
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            style={{
                              height: "20px",
                              width: "20px",
                            }}
                          >
                            <circle cx="12" cy="12" r="10"></circle>
                            <path d="M12 16v-4"></path>
                            <path d="M12 8h.01"></path>
                          </svg>
                        }
                        label={"notes"}
                        name={userData?.userDAO?.notes || "NA"}
                      />
                    </div>
                  </div>
                  <div></div>
                  <div className="col-1-1 info-row-column">
                    <div
                      className="flex center-left"
                      style={{
                        gap: "10px",
                        alignItems: "flex-start",
                      }}
                    >
                      <div>
                        <svg
                          color={getRandomColor()}
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="lucide lucide-info h-4 w-4 text-muted-foreground"
                          data-id="30"
                          style={{
                            height: "20px",
                            width: "20px",
                          }}
                        >
                          <circle cx="12" cy="12" r="10"></circle>
                          <path d="M12 16v-4"></path>
                          <path d="M12 8h.01"></path>
                        </svg>
                      </div>
                      <div
                        style={{
                          overflow: "hidden",
                        }}
                      >
                        <p
                          className="f-w-300"
                          style={{
                            color: "#aaaaaa",
                            fontSize: "10px",
                            lineHeight: 1,
                            paddingBottom: "2px",
                          }}
                        >
                          Practice
                        </p>
                        <p
                          style={{
                            whiteSpace: "normal",
                            overflowWrap: "break-word",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            width: "100%",
                          }}
                        >
                          {userData?.practice && userData?.practice.length > 0
                            ? userData?.practice.map((Practice) => (
                                <li
                                  style={{
                                    listStyle: "none",
                                    marginRight: "4px",
                                    marginBottom: "4px",
                                    borderRadius: "2px",
                                    lineHeight: "32px",
                                    padding: "10px 12px",
                                    verticalAlign: "middle",
                                  }}
                                  className=" bg-color-faded dis-i-b cursor-pointer color-primary hover-background-secondary transition"
                                >
                                  <div className="flex center-left">
                                    <p
                                      className="of-hid"
                                      style={{
                                        whiteSpace: "nowrap",
                                        width: "108px",
                                        textOverflow: "ellipsis",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {Practice.payToName}
                                    </p>
                                  </div>
                                </li>
                              ))
                            : "NA"}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="col-1-1 info-row-column">
                      <div
                        className="flex center-left"
                        style={{
                          gap: "10px",
                          alignItems: "flex-start",
                        }}
                      >
                        <div>
                          <svg
                            color={getRandomColor()}
                            width="24"
                            height="24"
                            viewBox="0 0 128 128"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="lucide lucide-info h-4 w-4 text-muted-foreground"
                            data-id="30"
                            style={{
                              height: "20px",
                              width: "20px",
                            }}
                          >
                            <g>
                              <path d="M36,117c-1.66,0-3-1.34-3-3v-6c0-2.76-2.24-5-5-5H12c-2.76,0-5,2.24-5,5v6c0,1.66-1.34,3-3,3s-3-1.34-3-3v-6    c0-6.07,4.93-11,11-11h16c6.07,0,11,4.93,11,11v6C39,115.66,37.66,117,36,117z" />
                            </g>
                            <g>
                              <path d="M20,91c-7.17,0-13-5.83-13-13s5.83-13,13-13s13,5.83,13,13S27.17,91,20,91z M20,71c-3.86,0-7,3.14-7,7s3.14,7,7,7    s7-3.14,7-7S23.86,71,20,71z" />
                            </g>
                            <g>
                              <path d="M80,117c-1.66,0-3-1.34-3-3v-6c0-2.76-2.24-5-5-5H56c-2.76,0-5,2.24-5,5v6c0,1.66-1.34,3-3,3s-3-1.34-3-3v-6    c0-6.07,4.93-11,11-11h16c6.07,0,11,4.93,11,11v6C83,115.66,81.66,117,80,117z" />
                            </g>
                            <g>
                              <path d="M64,91c-7.17,0-13-5.83-13-13s5.83-13,13-13s13,5.83,13,13S71.17,91,64,91z M64,71c-3.86,0-7,3.14-7,7s3.14,7,7,7    s7-3.14,7-7S67.86,71,64,71z" />
                            </g>
                            <g>
                              <path d="M124,117c-1.66,0-3-1.34-3-3v-6c0-2.76-2.24-5-5-5h-16c-2.76,0-5,2.24-5,5v6c0,1.66-1.34,3-3,3s-3-1.34-3-3v-6    c0-6.07,4.93-11,11-11h16c6.07,0,11,4.93,11,11v6C127,115.66,125.66,117,124,117z" />
                            </g>
                            <g>
                              <path d="M108,91c-7.17,0-13-5.83-13-13s5.83-13,13-13s13,5.83,13,13S115.17,91,108,91z M108,71c-3.86,0-7,3.14-7,7s3.14,7,7,7    s7-3.14,7-7S111.86,71,108,71z" />
                            </g>
                            <g>
                              <path d="M42,59c-7.17,0-13-5.83-13-13s5.83-13,13-13s13,5.83,13,13S49.17,59,42,59z M42,39c-3.86,0-7,3.14-7,7s3.14,7,7,7    s7-3.14,7-7S45.86,39,42,39z" />
                            </g>
                            <g>
                              <path d="M86,59c-7.17,0-13-5.83-13-13s5.83-13,13-13s13,5.83,13,13S93.17,59,86,59z M86,39c-3.86,0-7,3.14-7,7s3.14,7,7,7    s7-3.14,7-7S89.86,39,86,39z" />
                            </g>
                            <g>
                              <path d="M64,27c-7.17,0-13-5.83-13-13S56.83,1,64,1s13,5.83,13,13S71.17,27,64,27z M64,7c-3.86,0-7,3.14-7,7s3.14,7,7,7    s7-3.14,7-7S67.86,7,64,7z" />
                            </g>
                          </svg>
                        </div>
                        <div
                          style={{
                            overflow: "hidden",
                          }}
                        >
                          <p
                            className="f-w-300"
                            style={{
                              color: "#aaaaaa",
                              fontSize: "10px",
                              lineHeight: 1,
                              paddingBottom: "2px",
                            }}
                          >
                            Groups
                          </p>
                          <p
                            style={{
                              whiteSpace: "normal",
                              overflowWrap: "break-word",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              width: "100%",
                            }}
                          >
                            {userData?.groups && userData?.groups.length > 0
                              ? userData?.groups.map((groups) => (
                                  <li
                                    style={{
                                      listStyle: "none",
                                      marginRight: "4px",
                                      marginBottom: "4px",
                                      borderRadius: "2px",
                                      lineHeight: "32px",
                                      padding: "10px 12px",
                                      verticalAlign: "middle",
                                    }}
                                    className=" bg-color-faded dis-i-b cursor-pointer color-primary hover-background-secondary transition"
                                  >
                                    <div className="flex center-left">
                                      <p
                                        className="of-hid"
                                        style={{
                                          whiteSpace: "nowrap",
                                          width: "108px",
                                          textOverflow: "ellipsis",
                                          fontSize: "12px",
                                        }}
                                      >
                                        {groups.groupName}
                                      </p>
                                    </div>
                                  </li>
                                ))
                              : "NA"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div
              style={
                editbutton
                  ? {
                      display: "flex",
                    }
                  : {
                      display: "none",
                    }
              }
            >
              {permissions?.isUpdateAllowed && (
                <NavLink
                  to={
                    ueserid
                      ? {
                          pathname: "/edit-user",
                          search: `?next=${window.location.pathname + window.location.search}`,
                          state: {
                            id: ueserid,
                          },
                        }
                      : "#"
                  }
                >
                  <ButtonLightTextIcon
                    title="Edit Details"
                    icon={
                      <svg
                        width="15"
                        height="15"
                        style={{
                          height: "15px",
                          width: "15px",
                        }}
                        viewBox="0 0 24 24"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M21.1213 2.70705C19.9497 1.53548 18.0503 1.53547 16.8787 2.70705L15.1989 4.38685L7.29289 12.2928C7.16473 12.421 7.07382 12.5816 7.02986 12.7574L6.02986 16.7574C5.94466 17.0982 6.04451 17.4587 6.29289 17.707C6.54127 17.9554 6.90176 18.0553 7.24254 17.9701L11.2425 16.9701C11.4184 16.9261 11.5789 16.8352 11.7071 16.707L19.5556 8.85857L21.2929 7.12126C22.4645 5.94969 22.4645 4.05019 21.2929 2.87862L21.1213 2.70705ZM18.2929 4.12126C18.6834 3.73074 19.3166 3.73074 19.7071 4.12126L19.8787 4.29283C20.2692 4.68336 20.2692 5.31653 19.8787 5.70705L18.8622 6.72357L17.3068 5.10738L18.2929 4.12126ZM15.8923 6.52185L17.4477 8.13804L10.4888 15.097L8.37437 15.6256L8.90296 13.5112L15.8923 6.52185ZM4 7.99994C4 7.44766 4.44772 6.99994 5 6.99994H10C10.5523 6.99994 11 6.55223 11 5.99994C11 5.44766 10.5523 4.99994 10 4.99994H5C3.34315 4.99994 2 6.34309 2 7.99994V18.9999C2 20.6568 3.34315 21.9999 5 21.9999H16C17.6569 21.9999 19 20.6568 19 18.9999V13.9999C19 13.4477 18.5523 12.9999 18 12.9999C17.4477 12.9999 17 13.4477 17 13.9999V18.9999C17 19.5522 16.5523 19.9999 16 19.9999H5C4.44772 19.9999 4 19.5522 4 18.9999V7.99994Z"
                          fill="#283f54"
                        />
                      </svg>
                    }
                  />
                </NavLink>
              )}
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default UserView;
