import ButtonLightTextIcon from "../../components/common/ui/ButtonLightTextIcon";
import { withRouter } from "react-router-dom";
import ButtonTextIcon from "../../components/common/ui/ButtonTextIcon";
import ResultsPerPage from "../../components/common/ResultsPerPage";
import { useEffect, useRef, useState } from "react";
import ConfirmationModal from "../../components/common/ui/ConfirmationModal";
import CustomModal from "../../components/common/CustomModal";
import { decodeModuleAccessFromToken } from "../../opl-utilities/helpers";

const Label = ({ title }) => {
    return (
        <p
            className='color-primary f-w-300 l-h-1'
            style={{ fontSize: "12px", paddingBottom: "6px" }}>
            &nbsp;{title}
        </p>
    );
};

const ProcedureFilter = ({ history, location, resetFilters, callBack, isDeleteDisabled }) => {
    const isFirstRender = useRef(true);
    const queryParams = new URLSearchParams(location.search);
    const [searchValue, setSearchValue] = useState(queryParams.get("name") || "");
    const permissions = decodeModuleAccessFromToken("procedures");

    // Redirect to login if no permissions
    useEffect(() => {
        if (!permissions) {
            history.push("/login");
        }
    }, [permissions, history]);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        const handler = setTimeout(() => {
            handleQueryParamChange("name", searchValue);
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [searchValue]);

    const handleQueryParamChange = (key, value) => {
        queryParams.set("pageNo", 0);
        value ? queryParams.set(key, value) : queryParams.delete(key);
        history.push({ search: queryParams.toString() });
    };

    return (
        <div className='col-1-1 flex center-left filters'>
            <div>
                <Label title='Procedure Name/CPT Code' />
                <input className="primary" type="text" value={searchValue} placeholder="Eg: ANTXXXX" onChange={(e) => setSearchValue(e.target.value)} />
            </div>
            <div className='flex center-left' style={{ gap: "12px" }}>
                <div>
                    <Label title='Reset' />
                    <ButtonLightTextIcon
                        title='Reset'
                        className='light'
                        icon={
                            <svg width='800px' height='800px' viewBox='0 0 512 512'>
                                <path d='M64,256H34A222,222,0,0,1,430,118.15V85h30V190H355V160h67.27A192.21,192.21,0,0,0,256,64C150.13,64,64,150.13,64,256Zm384,0c0,105.87-86.13,192-192,192A192.21,192.21,0,0,1,89.73,352H157V322H52V427H82V393.85A222,222,0,0,0,478,256Z' />
                            </svg>
                        }
                        onClick={() => {
                            setSearchValue("");
                            resetFilters();
                        }}
                    />
                </div>
                <div className='mar-l-a'>
                    <Label title='Per page' />
                    <ResultsPerPage />
                </div>
            </div>
            <div>
                {permissions?.isDeleteAllowed && (
                    <CustomModal
                        selector={
                          
                            <span>
                <Label title='&nbsp;' />
                                <ButtonTextIcon
                                    title='Delete Procedure/s'
                                    style={{width:"100%" }}
                                    className=''
                                    disabled={isDeleteDisabled} // Disable 
                                    icon={
                                        <svg
                                            height="20px"
                                            width="20px"
                                            style={{ height: '10px', width: '10px' }}
                                            viewBox="0 0 330 330"
                                        >
                                            <g id="XMLID_6_">
                                                <g id="XMLID_11_">
                                                    <path d="M240,121.076H30V275c0,8.284,6.716,15,15,15h60h37.596c19.246,24.348,49.031,40,82.404,40c57.897,0,105-47.103,105-105 C330,172.195,290.816,128.377,240,121.076z M225,300c-41.355,0-75-33.645-75-75s33.645-75,75-75s75,33.645,75,75 S266.355,300,225,300z" />
                                                </g>
                                                <g id="XMLID_18_">
                                                    <path d="M240,90h15c8.284,0,15-6.716,15-15s-6.716-15-15-15h-30h-15V15c0-8.284-6.716-15-15-15H75c-8.284,0-15,6.716-15,15v45H45 H15C6.716,60,0,66.716,0,75s6.716,15,15,15h15H240z M90,30h90v30h-15h-60H90V30z" />
                                                </g>
                                                <g id="XMLID_23_">
                                                    <path d="M256.819,193.181c-5.857-5.858-15.355-5.858-21.213,0L225,203.787l-10.606-10.606c-5.857-5.858-15.355-5.858-21.213,0 c-5.858,5.858-5.858,15.355,0,21.213L203.787,225l-10.606,10.606c-5.858,5.858-5.858,15.355,0,21.213 c2.929,2.929,6.768,4.394,10.606,4.394c3.839,0,7.678-1.465,10.607-4.394L225,246.213l10.606,10.606 c2.929,2.929,6.768,4.394,10.607,4.394c3.839,0,7.678-1.465,10.606-4.394c5.858-5.858,5.858-15.355,0-21.213L246.213,225 l10.606-10.606C262.678,208.535,262.678,199.039,256.819,193.181z" />
                                                </g>
                                            </g>
                                        </svg>
                                    }
                                />
                            </span>
                        }
                        content={
                            <ConfirmationModal
                                onConfirm={async () => { await callBack(); return true; }}
                                message="Are you sure you want to delete selected procedures ?"
                            />
                        }
                    />
                )}
            </div>
        </div>
    );
};

export default withRouter(ProcedureFilter);