
import "../styles/base.scss";
import "../styles/pages.scss";
import "../styles/animations.scss";
import "../styles/responsive.scss";
import { useEffect, useState } from "react";
import { API_URL } from "../opl-utilities/constants";
import { withRouter } from "react-router-dom";
import LoaderIcon from "../components/common/ui/LoaderIcon";
import Header from "../components/common/ui/Header"; 
import Pagination from "../components/common/Pagination"; 
import { useUI } from "../context/ui"; 
import TableFilter from "./zip/TableFilter";
import { getUserTypeInfo } from "../opl-utilities/helpers";
import ButtonTextIcon from "../components/common/ui/ButtonTextIcon";
import CustomModal from "../components/common/CustomModal";
import ButtonTertiary from "../components/common/ui/ButtonTertiary";
import ConfirmationModal from "../components/common/ui/ConfirmationModal";
import AddFormzip from "./zip/AddForm";
const jsonToQueryParams = (json) => {
  const queryParams = Object.keys(json)
    .filter((key) => json[key] !== null && json[key] !== "")
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(json[key])}`)
    .join("&");

  return queryParams;
};
const ZipPage = ({ history, location, activePage }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);
  const queryParams = new URLSearchParams(location.search);
  const pageSize = queryParams.get("pageSize") || 12;
  const pageNo = queryParams.get("pageNo") || 0;
  const search = queryParams.get("search") || "";
 
  const { addToastMessage } = useUI();

  const resetFilters = () => {
    updateQueryParam("pageNo", 0);
    updateQueryParam("search", "");   
  };

  const callListApi = async () => {
    setLoading(true);
    try {
      const query = jsonToQueryParams({
        search: search,
      });
      const resp = await fetch(
        `${API_URL}/api/1.0.0/zip?pageNo=${pageNo}&pageSize=${pageSize}&sortOrder=DESC&${query}`,
        {
          headers: {
            "Content-Type": "application/json",
            "X-JWT-Assertion": localStorage.getItem("token"),
          },
        }
      );
      if (resp.status === 401 || resp.status === 403) {
        if (resp.status === 401) {
          localStorage.clear();
          window.location.href = "/login";
        } else if (resp.status === 403) {
          addToastMessage({
            status: false,
            message:
              "Access denied. You do not have permission to perform this action.",
          });
          setLoading(false);
          setError("Access denied. You do not have permission to perform this action.");
        }
        return false;
      }
      if (resp.ok) {
        const response = await resp.json();
        if (response) {
          setData(response.data);
        }
        else {
          setData(null);
        }
      }
      else {
        setData(null);
      }

    } catch (e) {
      console.log("Error:", e);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    try {
        if (!id) {
            return false;
        }
        const response = await fetch(
            API_URL + `/api/1.0.0/zip/${id}`,
            {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "X-JWT-Assertion": localStorage.getItem("token"),
                },
            }
        );
        if (response.status === 401 || response.status === 403) {
    if (response.status === 401) {
      localStorage.clear();
      window.location.href = "/login";
    } else if (response.status === 403) {
      addToastMessage({
        status: false, 
        message: "Access denied. You do not have permission to perform this action.",
      });
    }
    return false; 
  }
        if (response.status === 200) {
            callListApi();
            addToastMessage({
                status: true,
                message: `Zip Deleted Successfully`,
            });
            return true;
        }

        addToastMessage({
            status: false,
            message: `Something went wrong while deleting the Zip`,
        });

        return false;
    } catch (error) {
        addToastMessage({
            status: false,
            message: `Something went wrong while deleting the Zip`,
        });
        return false;
    }
};


  const updateQueryParam = (key, value) => {
    value ? queryParams.set(key, value) : queryParams.delete(key);
    history.push({ search: queryParams.toString() });
  };
 
  useEffect(() => {
    callListApi();
  }, [location.search]);

  return (
    <section id="opl_coremax">
      <section className="main-section">
        <Header active={activePage} location={location} />
        <section id="category">
          <div
            className="col-1-1 flex pad-0-10"
            style={{ justifyContent: "space-between" }}
          >
            <h5 className="page-active color-primary f-w-700">ZIP</h5>
            <CustomModal
              selector={
                <ButtonTextIcon
                  title="Add New"
                  className="mar-l-a"
                  icon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19.995"
                      height="19.995"
                      viewBox="0 0 19.995 19.995"
                    >
                      <path
                        d="M12,2a10,10,0,1,1-10,10A10,10,0,0,1,12,2Zm0,1.5A8.5,8.5,0,1,0,20.5,12,8.5,8.5,0,0,0,12,3.5Zm-.747,7.75h-3.5a.75.75,0,0,0,0,1.5h3.5v3.5a.75.75,0,0,0,1.5,0v-3.5h3.5a.75.75,0,0,0,0-1.5h-3.5V7.75a.75.75,0,0,0-1.5,0Z"
                        transform="translate(-2.005 -2)"
                      />
                    </svg>
                  }
                />
              }
              content={<AddFormzip callback={callListApi} />}
            />
          </div>
          <TableFilter resetFilters={resetFilters} />
          <div>
            {data && !loading && (
              <div
                className="flex center-left"
                style={{
                  padding: "10px 15px 10px 15px",
                  gap: "12px",
                }}
              >
                <p className="f-w-300" style={{ fontSize: "11px" }}>
                  Page no:
                </p>
                <Pagination
                  totalRecords={data.totalRecords}
                  pageNo={data.pageNo}
                  pageSize={data.pageSize}
                />
              </div>
            )}
          </div>
          {loading ? (
            <section
              className="flex center-center"
              style={{ height: "100vh", backgroundColor: "#fafaff" }}
            >
              <LoaderIcon style={{ height: "32px", width: "32px" }} />
            </section>
          ) : data?.list.length ? (
            <div className="rs-table-holder">
              <table className="rs-table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>ZIP</th>
                    {/* <th>Country</th> */}
                    <th>City</th>
                    <th>State</th>
                    <th style={{ textAlign: "center" }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.list.map((item, index) => (
                    <tr key={item.id}>
                      <td>{pageNo * pageSize + index + 1}</td>
                      <td>{item.zipCode || "NA"}</td>
                      <td>{item.city || "NA"}</td>
                      <td>{item.state || "NA"}</td>
                      <td>
                        <div className="flex space-between">
                          <CustomModal
                            selector={
                              <ButtonTertiary
                                actionType="delete"
                                title="Delete"
                              />
                            }
                            content={
                              <ConfirmationModal
                                onConfirm={async () => {
                                  await handleDelete(item.id);
                                  return true;
                                }}
                                message="Are you sure you want to delete this Zip Code?"
                              />
                            }
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <section
              style={{
                height: "80vh",
                paddingBottom: "20vh",
                backgroundColor: "#fff",
              }}
              className="flex center-center"
            >
              <p>No records found.</p>
            </section>
          )}
          <div>
            {data && !loading && (
              <div
                className="flex center-center"
                style={{
                  padding: "24px 15px 32px 15px",
                  gap: "12px",
                }}
              >
                <p className="f-w-300" style={{ fontSize: "11px" }}>
                  Page no:
                </p>
                <Pagination
                  totalRecords={data.totalRecords}
                  pageNo={data.pageNo}
                  pageSize={data.pageSize}
                />
              </div>
            )}
          </div>
        </section>
      </section>
    </section>
  );
};

export default withRouter(ZipPage);

 