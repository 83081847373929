import { useEffect, useState } from "react";
import { getLogInUserDetails, isManager } from "../../opl-utilities/helpers";
import { useUI } from "../../context/ui";
import { API_URL } from "../../opl-utilities/constants";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { withRouter } from "react-router-dom";
import LoaderIcon from "../../components/common/ui/LoaderIcon";
import ButtonLightTextIcon from "../../components/common/ui/ButtonLightTextIcon";
import ButtonTextIcon from "../../components/common/ui/ButtonTextIcon";
import * as Yup from "yup";

const Label = ({ title, required }) => {
  return (
    <p className="color-primary f-w-600" style={{ paddingBottom: "5px" }}>
      {title}
      {required && <span style={{ color: "red", marginLeft: "4px" }}>*</span>}
    </p>
  );
};

const InvoiceStatus = ({ id,invoiceStatus,history, setShow,callback }) => {
  const { addToastMessage } = useUI();
  const [loading, setLoading] = useState(false);
  const validationSchema = Yup.object().shape({
    selectedRadio: Yup.string().required("Select an option, it is required"),

    chequeNumber: Yup.string().test(
      "cheque-number-required",
      "Cheque number is required",
      function (value) {
        const { selectedRadio } = this.parent; // Access parent form values
        if (selectedRadio === "4" && !value) {
          return false;
        }
        return true;
      }
    ).max(255, "Cheque number must not exceed 255 characters"),

    amountReceived: Yup.string()
      .test(
        "amount-received-required",
        "Amount must be a valid number and is required",
        function (value) {
          const { selectedRadio } = this.parent; // Access parent form values
          if (
            selectedRadio === "4" &&
            (!value || isNaN(value) || value.trim() === "")
          ) {
            return false;
          }
          return true;
        }
      )
      .matches(
        /^[0-9]+(\.[0-9]{1,2})?$/, // Regular expression to allow only numbers and up to 2 decimal places
        "Amount must be a valid number"
      ).test(
        "amount-range",
        "Amount must not be greater than 999999 or less than 0",
        function (value) {
          if (value && !isNaN(value)) {
            const numericValue = parseFloat(value);
            return numericValue >= 0 && numericValue <= 999999;
          }
          return true; // If no value, let the required test handle it
        }
      ),
  });
  const initialValues = {
    selectedRadio: null,
    chequeNumber: "",
    amountReceived: "",
  };
  const handleSubmit = async (values) => {
    
    setLoading(true);
    if (!id) {
      addToastMessage({
        status: false,
        message: "Something went wrong. Please try again later.",
      });
      setLoading(false);
      return;
    }
    try {
      const payload = {
        status: parseInt(values.selectedRadio),
        paymentReceived: values.amountReceived,
        reference: values.chequeNumber,
      };
      const resp = await fetch(API_URL + `/api/1.0.0/invoice/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "X-JWT-Assertion": localStorage.getItem("token"),
        },
        body: JSON.stringify(payload),
      });
      if (resp.status === 401 || resp.status === 403) {
        if (resp.status === 401) {
          localStorage.clear();
          window.location.href = "/login";
        } else if (resp.status === 403) {
          addToastMessage({
            status: false,
            message:
              "Access denied. You do not have permission to perform this action.",
          });
          setLoading(false);
        }
        return false;
      }
        const response = await resp.json();
        if (response.result.responseCode === 200) {
          addToastMessage({
            status: true,
            message: "Invoice status updated successfully.",
          });
          callback();
          setLoading(false);
          setShow(false);
          return;
        } else {
          addToastMessage({
            status: false,
            message: "Something went wrong. Please try again later.",
          });
        }
    } catch (e) {
      console.log("error", e);
    } finally {
      setLoading(false);
    }
  };

  const isDisabled = (radioValue) => {
    if (invoiceStatus === 1 && (radioValue === 2)) return false;
    if (invoiceStatus === 2 && (radioValue === 3 || radioValue === 4))
      return false;
    if (invoiceStatus === 3 || invoiceStatus === 4) return true;
    return true;
  };

  return (
    <section id="opl_coremax">
      <section
        style={{
          position: "fixed",
          inset: 0,
          zIndex: 9999,
          backgroundColor: "rgba(255, 255, 255, 0.86)",
        }}
      >
        <section
          className="col-1-1 flex center-center"
          style={{
            height: "100vh",
            width: "100vw",
          }}
        >
          <div
            style={{
              width: "600px",
              padding: "24px",
              height: "auto",
              maxHeight: "80vh",
              border: "solid 1px #f0f0f0",
              background: "white",
              borderRadius: "8px",
              margin: "0 auto",
              boxShadow: "0px 0px 20px rgba(0,0,0,0.08)",
            }}
          >
            <div
              className="col-1-1 flex center-left"
              style={{
                borderBottom: "solid 1px #f0f0f0",
                paddingBottom: "12px",
                marginBottom: "16px",
              }}
            >
              <h6 className="color-primary f-w-300 ">Invoice Status
              {invoiceStatus === 1
                            ? " (Ready)"
                            : invoiceStatus === 2
                            ? " (Send to Facility)"
                            : invoiceStatus === 3
                            ? " (Update Required)"
                            : invoiceStatus === 4
                            ? " (Payment Received)"
                                  : " NA"}
              </h6>
              <div
                className="mar-l-a cursor-pointer "
                onClick={() => setShow(false)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z"
                    fill="#283f54"
                  />
                </svg>
              </div>
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              enableReinitialize={true}
              onSubmit={handleSubmit}
            >
              {({ values, setFieldValue, errors, touched }) => (
                <Form>
                  <div>
                    <div>
                      <div
                        style={{
                          marginBottom: "20px",
                        }}
                      >
                        <div
                          className="flex"
                          style={{
                            gap: "10px",
                            justifyContent: "space-between",
                          }}
                        >
                          {/* label 2*/}

                          <label
                            style={{
                              display: "block",
                              backgroundColor:
                                parseInt(values.selectedRadio) === 2
                                  ? " #6ca0ff"
                                  : " #ffffff",
                              color:
                                parseInt(values.selectedRadio) === 2
                                  ? "#ffffff"
                                  : "#6ca0ff",
                              border: "1px solid #6ca0ff",
                              padding: "5px",
                              borderRadius: "5px",
                              textAlign: "center",
                              width: "30%",
                              cursor: isDisabled(2) ? "not-allowed" : "pointer",
                              opacity: isDisabled(2) ? 0.5 : 1,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            onClick={() => {
                              if (!isDisabled(2)) {
                                setFieldValue("selectedRadio", 2);
                                setFieldValue("chequeNumber", "");
                                setFieldValue("amountReceived", "");
                              }
                            }}
                          >
                            {/* Radio button */}
                            <Field
                              type="radio"
                              name="selectedRadio"
                              value="2"
                              hidden
                              disabled={isDisabled(2)}
                            />
                            <span
                              style={{
                                width: "20px",
                                height: "20px",
                                borderRadius: "50%",
                                border:
                                  parseInt(values.selectedRadio) === 2
                                    ? "0px"
                                    : "1px solid #6ca0ff",
                                marginRight: "8px",
                                borderColor:
                                  parseInt(values.selectedRadio) === 2
                                    ? "white"
                                    : " #6ca0ff",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {parseInt(values.selectedRadio) === 2 && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                >
                                  <circle
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    fill="#ffffff"
                                  />
                                  <path
                                    d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.25 16.518l-4.5-4.319 1.396-1.435 3.078 2.937 6.105-6.218 1.421 1.409-7.5 7.626z"
                                    fill="#43a047"
                                  />
                                </svg>
                              )}
                            </span>
                            <p
                              style={{
                                color:
                                  parseInt(values.selectedRadio) === 2
                                    ? "#ffffff"
                                    : "#6ca0ff",
                                margin: 0,
                              }}
                            >
                              Send to Facility
                            </p>
                          </label>
                          {/* label 3*/}

                          <label
                            style={{
                              display: "block",
                              backgroundColor:
                                parseInt(values.selectedRadio) === 3
                                  ? " #6ca0ff"
                                  : " #ffffff",
                              color:
                                parseInt(values.selectedRadio) === 3
                                  ? "#ffffff"
                                  : "#6ca0ff",
                              border: "1px solid #6ca0ff",
                              padding: "5px",
                              borderRadius: "5px",
                              textAlign: "center",
                              width: "30%",
                              cursor: isDisabled(3) ? "not-allowed" : "pointer",
                              opacity: isDisabled(3) ? 0.5 : 1,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            onClick={() => {
                              if (!isDisabled(3)) {
                                setFieldValue("selectedRadio", 3);
                                setFieldValue("chequeNumber", "");
                                setFieldValue("amountReceived", "");
                              }
                            }}
                          >
                            {/* Radio button */}
                            <Field
                              type="radio"
                              name="selectedRadio"
                              value="3"
                              hidden
                              disabled={isDisabled(3)}
                            />
                            <span
                              style={{
                                width: "20px",
                                height: "20px",
                                borderRadius: "50%",
                                border:
                                  parseInt(values.selectedRadio) === 3
                                    ? "0px"
                                    : "1px solid #6ca0ff",
                                marginRight: "8px",
                                borderColor:
                                  parseInt(values.selectedRadio) === 3
                                    ? "white"
                                    : " #6ca0ff",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {parseInt(values.selectedRadio) === 3 && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                >
                                  <circle
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    fill="#ffffff"
                                  />
                                  <path
                                    d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.25 16.518l-4.5-4.319 1.396-1.435 3.078 2.937 6.105-6.218 1.421 1.409-7.5 7.626z"
                                    fill="#43a047"
                                  />
                                </svg>
                              )}
                            </span>
                            <p
                              style={{
                                color:
                                  parseInt(values.selectedRadio) === 3
                                    ? "#ffffff"
                                    : "#6ca0ff",
                                margin: 0,
                              }}
                            >
                              Update Required
                            </p>
                          </label>
                          {/* label 4*/}
                          <label
                            style={{
                              display: "block",
                              backgroundColor:
                                parseInt(values.selectedRadio) === 4
                                  ? " #6ca0ff"
                                  : " #ffffff",
                              color:
                                parseInt(values.selectedRadio) === 4
                                  ? "#ffffff"
                                  : "#6ca0ff",
                              border: "1px solid #6ca0ff",
                              padding: "5px",
                              borderRadius: "5px",
                              textAlign: "center",
                              width: "30%",
                              cursor: isDisabled(4) ? "not-allowed" : "pointer",
                              opacity: isDisabled(4) ? 0.5 : 1,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            onClick={() => {
                              if (!isDisabled(4)) {
                                setFieldValue("selectedRadio", 4);
                              }
                            }}
                          >
                            {/* Radio button */}
                            <Field
                              type="radio"
                              name="selectedRadio"
                              value="4"
                              hidden
                              disabled={isDisabled(4)}
                            />
                            <span
                              style={{
                                width: "20px",
                                height: "20px",
                                borderRadius: "50%",
                                border:
                                  parseInt(values.selectedRadio) === 4
                                    ? "0px"
                                    : "1px solid #6ca0ff", // Default border
                                marginRight: "8px",
                                borderColor:
                                  parseInt(values.selectedRadio) === 4
                                    ? "white"
                                    : " #6ca0ff",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {parseInt(values.selectedRadio) === 4 && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                >
                                  <circle
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    fill="#ffffff"
                                  />
                                  <path
                                    d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.25 16.518l-4.5-4.319 1.396-1.435 3.078 2.937 6.105-6.218 1.421 1.409-7.5 7.626z"
                                    fill="#43a047"
                                  />
                                </svg>
                              )}
                            </span>
                            <p
                              style={{
                                color:
                                  parseInt(values.selectedRadio) === 4
                                    ? "#ffffff"
                                    : "#6ca0ff",
                                margin: 0,
                              }}
                            >
                              {" "}
                              Payment Received
                            </p>
                          </label>
                        </div>
                        <ErrorMessage
                          name="selectedRadio"
                          component="p"
                          className="error-messages"
                        />
                      </div>

                      {parseInt(values.selectedRadio) === 4 && (
                        <div>
                          <div style={{ marginBottom: "5px" }}>
                            <Label title="Cheque Number" required />
                            <Field
                              type="text"
                              name="chequeNumber"
                              className={`primary ${
                                errors.chequeNumber && touched.chequeNumber
                                  ? "error"
                                  : ""
                              }`}
                            />
                            <ErrorMessage
                              name="chequeNumber"
                              component="p"
                              className="error-messages"
                            />
                          </div>
                          <div style={{ marginBottom: "10px" }}>
                            <Label title="Amount Received" required />
                            <Field
                              type="text"
                              name="amountReceived"
                              className={`primary ${
                                errors.amountReceived && touched.amountReceived
                                  ? "error"
                                  : ""
                              }`}
                            />
                            <ErrorMessage
                              name="amountReceived"
                              component="p"
                              className="error-messages"
                            />
                          </div>
                        </div>
                      )}
                      <div
                        style={{
                          padding: "10px",
                          borderRadius: "5px",
                          width: "100%",
                        }}
                      >
                        <p
                          style={{
                            color: "rgb(0, 0, 0)",
                          }}
                        >
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="size-6"
                              style={{
                                height: "20px",
                                width: "20px",
                                marginTop: "-3px",
                              }}
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                              />
                            </svg>
                             {" Disclaimer:"}
                          </span>
                          
                          <p style={{color:"rgb(86, 86, 86)"}}>
                            {invoiceStatus === 1
                              ? `Invoice is ready to be sent to the facility, only "Send to facility" option is available`
                              : invoiceStatus === 2
                              ? `Invoice has been sent to the facility awaiting feedback, only "Update required" and "Payment received" option is available`
                              : invoiceStatus === 3
                              ? `All options are disabled, after changes are made in necessary fields. Invoice needs to be regenerated and the invoice status will automatically change to ready state.`
                              : invoiceStatus === 4
                              ? `Payment has been received, all options are disabled`
                              : " NA"}
                          </p>
                        </p>
                      </div>
                    </div>
                    <div
                      className=" flex "
                      style={{
                        marginTop: "16px",
                        borderTop: "solid 1px #f0f0f0",
                        paddingTop: "16px",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <ButtonLightTextIcon
                          title="Cancel"
                          onClick={() => setShow(false)}
                          icon={
                            <svg
                              clipRule="evenodd"
                              fillRule="evenodd"
                              strokeLinejoin="round"
                              strokeMiterlimit="2"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                              width="19.995"
                              height="19.996"
                            >
                              <path
                                d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 1.5c-4.69 0-8.497 3.807-8.497 8.497s3.807 8.498 8.497 8.498 8.498-3.808 8.498-8.498-3.808-8.497-8.498-8.497zm0 7.425 2.717-2.718c.146-.146.339-.219.531-.219.404 0 .75.325.75.75 0 .193-.073.384-.219.531l-2.717 2.717 2.727 2.728c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.384-.073-.53-.219l-2.729-2.728-2.728 2.728c-.146.146-.338.219-.53.219-.401 0-.751-.323-.751-.75 0-.192.073-.384.22-.531l2.728-2.728-2.722-2.722c-.146-.147-.219-.338-.219-.531 0-.425.346-.749.75-.749.192 0 .385.073.531.219z"
                                fillRule="nonzero"
                              />
                            </svg>
                          }
                        />
                      </div>
                      <div>
                        <ButtonTextIcon
                          title="Update Status"
                          type="submit"
                          disabled={loading}
                          icon={
                            loading ? (
                              <LoaderIcon />
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="19.995"
                                height="19.996"
                                viewBox="0 0 19.995 19.996"
                              >
                                <path
                                  d="M12.012,2a10,10,0,1,0,10,10,10,10,0,0,0-10-10Zm0,1.5a8.5,8.5,0,1,1-8.5,8.5,8.5,8.5,0,0,1,8.5-8.5ZM13.54,8.21s1.5,1.505,3.255,3.259a.752.752,0,0,1,0,1.061c-1.753,1.754-3.254,3.258-3.254,3.258a.742.742,0,0,1-.527.217.752.752,0,0,1-.534-1.278l1.978-1.977H7.764a.75.75,0,0,1,0-1.5h6.694L12.479,9.271a.746.746,0,0,1,.006-1.054.754.754,0,0,1,.531-.222.738.738,0,0,1,.524.215Z"
                                  transform="translate(-2.014 -1.995)"
                                />
                              </svg>
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </section>
      </section>
    </section>
  );
};

export default withRouter(InvoiceStatus);
