import "../styles/base.scss";
import "../styles/pages.scss";
import "../styles/animations.scss";
import { NavLink, withRouter } from "react-router-dom";
import Pagination from "../components/common/Pagination";
import {
  decodeModuleAccessFromToken,
  formatRateUSD,
  formatDate,
  getRandomColor,
  getUserTypeInfo,
  jsonToQueryParams,
} from "../opl-utilities/helpers";
import { useEffect, useState } from "react";
import { useUI } from "../context/ui";
import Header from "../components/common/ui/Header"; 
import LoaderIcon from "../components/common/ui/LoaderIcon";
import TableFilter from "./bill/TableFilter";
import CustomModal from "../components/common/CustomModal";
import ButtonTertiary from "../components/common/ui/ButtonTertiary";
import FullScreenBillDetailsModal from "./bill/FullScreenBillDetailsModal"; 
import PopUpModal from "../components/common/PopUpModal";
import ButtonLightTextIcon from "../components/common/ui/ButtonLightTextIcon";
import { API_URL } from "../opl-utilities/constants";
import FullScreenFileViewerModal from "../components/common/ui/FullScreenFileViewerModal";
import PatientDetails from "./bill/PatientDetails";
import FullScreenUpdateBillFormModal from "./bill/FullScreenUpdateBillFormModal";
import BillHistory from "./bill/BillHistory";

const getPatientName = (obj) => {
  if (!obj || typeof obj !== "object") return "Not Selected";

  const { firstName = "", middleName = "", lastName = "" } = obj;
  const fullName = [firstName, middleName, lastName]
      .filter(name => name && name.trim() !== "")
      .join(" ");

  return fullName || "Not Selected";
};

const BillPage = ({ history, location, activePage }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);
  const queryParams = new URLSearchParams(location.search);
  const pageSize = queryParams.get("pageSize") || 12;
  const pageNo = queryParams.get("pageNo") || 0;
  const { addToastMessage } = useUI(); 
  const [ids, setIds] = useState([]); 
	const [viewActivityId, setViewActivityId] = useState(null); 

	const toggleNumberInArray = (number) => {
		setIds((prevArray) => {
		  const index = prevArray.indexOf(number); 
		  if (index === -1) { 
			return [...prevArray, number];
		  } else { 
			const newArray = [...prevArray];
			newArray.splice(index, 1);
			return newArray;
		  }
		});
	  };
  const updateQueryParam = (key, value) => {
    value ? queryParams.set(key, value) : queryParams.delete(key);
    history.push({ search: queryParams.toString() });
  };

  // Decode permissions for the "bills" module
  const permissions = decodeModuleAccessFromToken("bills");
  const patientPermissions = decodeModuleAccessFromToken("patients");
  const activitiePermissions = decodeModuleAccessFromToken("activities");
  
  // Redirect to login if no permissions
  useEffect(() => {
    if (!permissions) {
      history.push("/login");
    }
  }, [permissions, history]);

  // ====== FILTER QUERY PARAMS
  const startDate = queryParams.get("startDate") || "";
  const endDate = queryParams.get("endDate") || ""; 
  const facility = queryParams.get("facility") || null;
  const patient = queryParams.get("patient") || null; 

  // ====== RESET FILTER
  const resetFilters = () => {
    updateQueryParam("startDate", "");
    updateQueryParam("endDate", ""); 
    updateQueryParam("facility", "");
    updateQueryParam("patient", ""); 
    updateQueryParam("pageNo", 0);
  };

  const getListApi = async (s) => {
      setLoading(true);
      try { 
        const query = jsonToQueryParams({
          startDate,
          endDate, 
          facilityId: facility ? JSON.parse(facility)?.value : null,
          patientId: patient ? JSON.parse(patient)?.value : null, 
        }); 
        const resp = await fetch(
          API_URL + `/api/1.0.0/bill?pageSize=${pageSize}&pageNo=${pageNo}&${query}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "X-JWT-Assertion": localStorage.getItem("token")
            },
          }
        );
        if (resp.status === 401 || resp.status === 403) {
          if (resp.status === 401) {
            localStorage.clear();
            window.location.href = "/login";
          } else if (resp.status === 403) {
            addToastMessage({
              status: false, 
              message: "Access denied. You do not have permission to perform this action.",
            });
            setLoading(false);
          }
          return false; 
        }
        if (resp.ok) {
          const response = await resp.json();
          if (response) {
            setData(response.data); 
          }
        }
      } catch (e) {
        console.log("Error:", e);
      } finally {
        setLoading(false);
      }
    };
   
    useEffect(() => {
      getListApi();
    }, [location.search]);

  return (
    <section id="opl_coremax">
      <section className="main-section">
        <Header active={activePage} location={location} />
        <section id="patient">
          <div className="col-1-1 flex center-left pad-0-10 main-section-top-row">
            <h5 className="page-active color-primary f-w-700">Bills</h5>
          </div>
          <TableFilter
            resetFilters={() => {
              resetFilters();
            }}
          />
          <div className="flex center-left">
            <div>
              {data && !loading && (
                <div
                  className="flex center-left"
                  style={{
                    padding: "10px 15px 10px 15px",
                    gap: "12px",
                  }}
                >
                  <p className="f-w-300" style={{ fontSize: "11px" }}>
                    Page no:
                  </p>
                  <Pagination
                    totalRecords={data.totalRecords}
                    pageNo={data.pageNo}
                    pageSize={data.pageSize}
                  />
                </div>
              )}
            </div> 
          </div>
          {loading ? (
            <section
              style={{
                height: "100vh",
                paddingBottom: "20vh",
                backgroundColor: "#fafaff",
              }}
              className="flex center-center"
            >
              <LoaderIcon style={{ height: "32px", width: "32px" }} />
            </section>
          ) : data?.list.length ? (
            <div className="rs-table-holder">
              <table className="rs-table">
                <thead>
                  <tr> 
                    <th>Bill ID</th>
                    <th>Activity ID</th>
                    <th>Patient ID</th>
                    <th>Patient DOB</th>
                    <th>Patient Name</th>
                    <th>Patient DOS</th>
                    <th>Charge Amount</th>
                    <th>Facility</th> 
                    <th style={{ textAlign: "center" }}>
                      <div data-tooltip="Actions available: View, Edit, or Delete the record.">
                        Action
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data &&
                    data.list.map((data, index) => (
                      <tr key={index}> 
                        <td>
                          {data.id ? (
                            <div
                              className="flex center-left"
                              style={{ gap: "10px" }}
                            >
                              <div>{data?.id}</div>
                            </div>
                          ) : (
                            "NA"
                          )}
                        </td>
                        <td>
                          {data?.activity?.id ? (
                            <div
                              className="flex center-left"
                              style={{ gap: "10px" }}
                            >
                              <div>{data?.activity?.id}</div>
                              {activitiePermissions?.isUpdateAllowed && (<div>
                                <NavLink
                                  to={
                                    data?.activity?.id
                                      ? {
                                          pathname: "/edit-activity",
                                          search: `?next=${
                                            window.location.pathname +
                                            window.location.search
                                          }`,
                                          state: {
                                            id: data?.activity?.id,
                                          },
                                        }
                                      : "#"
                                  }
                                >
                                  <ButtonTertiary
                                    disabled={(data?.invoiceStatus === 2 ||data?.invoiceStatus === 4 ) }
                                    actionType="edit"
                                    title="Edit Activity "
                                    className="tertiary-sm"
                                  />
                                </NavLink>
                              </div>)}
                              {activitiePermissions?.isReadAllowed && (
                                <div>
                                <ButtonTertiary
                                  actionType="view"
                                  title="Activity Detail"
                                  className="tertiary-sm"
                                  onClick={() => {
                                    setViewActivityId(data?.activity?.id);
                                  }}
                                />
                              </div>
                              )}
                            </div>
                          ) : (
                            "NA"
                          )}
                        </td>
                        <td>
                          {data?.activity?.patient?.id ? (
                            <div
                              className="flex center-left"
                              style={{ gap: "10px" }}
                            >
                              <div>{data?.activity?.patient?.id}</div>
                              {patientPermissions?.isUpdateAllowed && (
                                <div>
                                <NavLink
                                  to={{
                                    pathname: "/edit-patient",
                                    search: `?next=${
                                      window.location.pathname +
                                      window.location.search
                                    }`,
                                    state: { id: data?.activity?.patient?.id },
                                  }}
                                >
                                  <ButtonTertiary
                                    actionType="edit"
                                    title="Edit Patient"
                                    className="tertiary-sm"
                                  />
                                </NavLink>
                              </div>
                              )}
                              {patientPermissions?.isReadAllowed && (
                                <div>
                                <CustomModal
                                  selector={
                                    <ButtonTertiary
                                      actionType="view"
                                      title="Patient Detail"
                                      className="tertiary-sm"
                                    />
                                  }
                                  content={
                                    <PatientDetails
                                      id={data?.activity?.patient?.id}
                                    />
                                  }
                                />
                              </div>
                              )}
                            </div>
                          ) : (
                            "NA"
                          )}
                        </td>

                        <td>
                          {data?.activity?.patient?.dateOfBirth
                            ? formatDate(
                                data?.activity?.patient?.dateOfBirth
                              )
                            : "NA"}
                        </td>
                        <td>
                          {data?.activity?.patient?.firstName || "NA"}{" "}{data?.activity?.patient?.middleName || ""}{" "}
                          {data?.activity?.patient?.lastName || ""}
                        </td>
                        <td className="t-t-u t-a-l">
                          {data?.activity?.patient?.serviceDate
                            ? formatDate(
                                data?.activity?.patient?.serviceDate
                              )
                            : "NA"}
                        </td>
                        <td>
                          {data?.amount
                            ? `${formatRateUSD(data?.amount)}`
                            : "NA"}
                        </td>
                        <td>{data?.facility?.facilityName || "NA"}</td>
                        <td>
                          <div
                            className="flex center-center"
                            style={{ gap: "12px" }}
                          >
                            {permissions?.isReadAllowed && (
                              <div className="color-secondary transition hover-color-primary cursor-pointer ">
                                <CustomModal
                                  selector={
                                    <ButtonTertiary
                                      actionType="view"
                                      title="View"
                                    />
                                  }
                                  content={
                                    <FullScreenBillDetailsModal
                                      title="Bill Detail"
                                      id={data.id}
                                    />
                                  }
                                />
                              </div>
                            )}
                            {permissions?.isUpdateAllowed && (
                              <div className="color-secondary transition hover-color-primary cursor-pointer ">
                                <CustomModal
                                  selector={
                                    <ButtonTertiary
                                      disabled={(data?.invoiceStatus === 2 ||data?.invoiceStatus === 4 ) }
                                      actionType="edit"
                                      title="Edit"
                                    />
                                  }
                                  content={
                                    <FullScreenUpdateBillFormModal
                                      title="Edit Bill"
                                      id={data.id}
                                    />
                                  }
                                />
                              </div>
                            )}
                            <div>
                                <CustomModal
                                  selector={
                                    <ButtonTertiary
                                    actionType="view"
                                    title="History"
                                  /> 
                                  }
                                  content={
                                    <BillHistory
                                      id={data.id}
                                    />
                                  }
                                />
                              </div>
                          </div>
                        </td> 
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          ) : (
            <section
              style={{
                height: "80vh",
                paddingBottom: "20vh",
                backgroundColor: "#fff",
              }}
              className="flex center-center"
            >
              <p>No records found.</p>
            </section>
          )}

          <div>
            <div>
              {data && !loading && (
                <div
                  className="flex center-center"
                  style={{
                    padding: "24px 15px 32px 15px",
                    gap: "12px",
                  }}
                >
                  <p className="f-w-300" style={{ fontSize: "11px" }}>
                    Page no:
                  </p>
                  <Pagination
                    totalRecords={data.totalRecords}
                    pageNo={data.pageNo}
                    pageSize={data.pageSize}
                  />
                </div>
              )}
            </div>
          </div>
        </section>
      </section>
      {viewActivityId && (
        <PopUpModal>
          <ActivityDetailsContent
            setViewActivity={setViewActivityId}
            id={viewActivityId}
            history={history}
          />
        </PopUpModal>
      )}
    </section>
  );
};

export default withRouter(BillPage);



const ActivityDetailsContent = ({ setViewActivity, id, history }) => {
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
  const typeCounters = {};
	// Decode permissions for the "groups" module
	const permissions = decodeModuleAccessFromToken("activities");

	// Redirect to login if no permissions
	useEffect(() => {
		if (!permissions) {
			history.push("/login");
		}
	}, [permissions, history]);

	const InfoItem = ({ icon, label, name, ...rest }) => {
		return (
			<div {...rest}>
				<div className='flex center-left' style={{ gap: "10px" }}>
					<div>{icon}</div>
					<div style={{ overflow: "hidden" }}>
						<p
							className='f-w-300'
							style={{
								color: "#aaaaaa",
								fontSize: "10px",
								lineHeight: 1,
								paddingBottom: "2px",
							}}>
							{label}
						</p>
						<p
							style={{
								width: "200px",
								lineHeight: 1,
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
                padding: "1px 0px"
							}}>
							{name}
						</p>
					</div>
				</div>
			</div>
		);
	};

	const callApi = async (id) => {
		setLoading(true);
		try {
			const resp = await fetch(API_URL + `/api/1.0.0/activity/${id}`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					"X-JWT-Assertion": localStorage.getItem("token"),
				},
      });
      if (resp.status === 401 || resp.status === 403) {
				if (resp.status === 401) {
					localStorage.clear();
					window.location.href = "/login";
        } else if (resp.status === 403) {
          setLoading(false);
          setError("Access denied. You do not have permission to perform this action.");
				}
				return false; 
			}
			if (resp.ok) {
				const response = await resp.json();
				if (response) {
					setData(response.data);
					setLoading(false);
				}
			}
		} catch (e) {
			setError("Failed to fetch activity data.");
			setLoading(false);
			console.log("Error:", e);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (id) callApi(id);
	}, [id]);

	return (
    <section id="opl_coremax">
      <section id="custom-modal">
        <div className="inner">
          <div className="box" id="activity-detail-box">
            <div className="flex center-left">
              <div>
                <h6 className="color-primary f-w-300">Activity Details</h6>
              </div>
              <div style={{ marginLeft: "auto" }}>
                <ButtonLightTextIcon
                  title="Cancel"
                  onClick={() => setViewActivity(null)}
                  icon={
                    <svg
                      clipRule="evenodd"
                      fillRule="evenodd"
                      strokeLinejoin="round"
                      strokeMiterlimit="2"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      width="19.995"
                      height="19.996"
                    >
                      <path
                        d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 1.5c-4.69 0-8.497 3.807-8.497 8.497s3.807 8.498 8.497 8.498 8.498-3.808 8.498-8.498-3.808-8.497-8.498-8.497zm0 7.425 2.717-2.718c.146-.146.339-.219.531-.219.404 0 .75.325.75.75 0 .193-.073.384-.219.531l-2.717 2.717 2.727 2.728c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.384-.073-.53-.219l-2.729-2.728-2.728 2.728c-.146.146-.338.219-.53.219-.401 0-.751-.323-.751-.75 0-.192.073-.384.22-.531l2.728-2.728-2.722-2.722c-.146-.147-.219-.338-.219-.531 0-.425.346-.749.75-.749.192 0 .385.073.531.219z"
                        fillRule="nonzero"
                      />
                    </svg>
                  }
                />
              </div>
            </div>
            <div
              style={{
                height: "60vh",
                margin: "10px",
                overflowY: "scroll",
                padding: "20px 5px",
                overflowX: "hidden",
                scrollbarWidth: "thin",
              }}
            >
              {loading && (
                <div
                  className="col-1-1"
                  style={{
                    height: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoaderIcon
                    style={{
                      width: "20px",
                      height: "20px",
                    }}
                  />
                </div>
              )}
              {!loading && error && (
                <p
                  className="f-w-300 color-primary"
                  style={{ color: "#c0392b" }}
                >
                  {error}
                </p>
              )}
              {data && !loading && !error && (
                <div className="activity-card pos-rel">
                  <div
                    style={{
                      position: "absolute",
                      content: "",
                      height: "4px",
                      left: "-1px",
                      right: "-1px",
                      bottom: "-2px",
                      borderBottomLeftRadius: "4px",
                      borderBottomRightRadius: "4px",
                    }}
                  ></div>
                  <div
                    className="col-1-1 flex center-left"
                    style={{
                      gap: "10px",
                      paddingBottom: "5px",
                    }}
                  >
                    <h5 className="f-w-700">
                      <span className="f-w-300">#</span>
                      &nbsp;
                      {data.patientActivityDAO.id}
                    </h5>
                    {/* <hr
                      style={{
                        outline: "none",
                        border: "none",
                        height: "16px",
                        width: "2px",
                        background: "#EBEBEB",
                      }}
                    />
                    <StatusIcon id={status} /> */}
                  </div>
                  <div
                    style={{
                      padding: "12px 0 12px 0 ",
                    }}
                  >
                    <div
                      className="info-row flex center-center"
                      style={{
                        gap: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      <InfoItem
                        className="col-1-1 info-row-column"
                        icon={
                          <svg
                            color={getRandomColor()}
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            style={{
                              height: "20px",
                              width: "20px",
                            }}
                          >
                            <path d="M18 20a6 6 0 0 0-12 0"></path>
                            <circle cx="12" cy="10" r="4"></circle>
                            <circle cx="12" cy="12" r="10"></circle>
                          </svg>
                        }
                        label={"Patient"}
                        name={getPatientName(data?.patientActivityDAO?.patient)}
                      />
                      <InfoItem
                        className="col-1-1 info-row-column"
                        icon={
                          <svg
                            color={getRandomColor()}
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            style={{
                              height: "20px",
                              width: "20px",
                            }}
                            className="lucide lucide-info h-4 w-4 text-muted-foreground"
                            data-id="30"
                          >
                            <circle cx="12" cy="12" r="10"></circle>
                            <path d="M12 16v-4"></path>
                            <path d="M12 8h.01"></path>
                          </svg>
                        }
                        label={"Account Number"}
                        name={data?.patientActivityDAO?.account_number || "NA"}
                      />
                    </div>
                    <div
                      className="info-row flex center-center"
                      style={{
                        gap: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      <InfoItem
                        className="col-1-1 info-row-column"
                        icon={
                          <svg
                            color={getRandomColor()}
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            data-id="28"
                            style={{
                              height: "20px",
                              width: "20px",
                            }}
                          >
                            <path d="M19 7V4a1 1 0 0 0-1-1H5a2 2 0 0 0 0 4h15a1 1 0 0 1 1 1v4h-3a2 2 0 0 0 0 4h3a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1"></path>
                            <path d="M3 5v14a2 2 0 0 0 2 2h15a1 1 0 0 0 1-1v-4"></path>
                          </svg>
                        }
                        label={"Pay To"}
                        name={data?.payToAdmin?.payToName || "NA"}
                      />
                    </div>
                    <div
                      className="info-row flex center-center"
                      style={{
                        gap: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      <InfoItem
                        className="col-1-1 info-row-column"
                        icon={
                          <svg
                            color={getRandomColor()}
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            style={{
                              height: "20px",
                              width: "20px",
                            }}
                          >
                            <path d="M4.8 2.3A.3.3 0 1 0 5 2H4a2 2 0 0 0-2 2v5a6 6 0 0 0 6 6v0a6 6 0 0 0 6-6V4a2 2 0 0 0-2-2h-1a.2.2 0 1 0 .3.3"></path>
                            <path d="M8 15v1a6 6 0 0 0 6 6v0a6 6 0 0 0 6-6v-4"></path>
                            <circle cx="20" cy="10" r="2"></circle>
                          </svg>
                        }
                        label={"Surgeon"}
                        name={
                          data?.eventSurgeon
                            ? `${data?.eventSurgeon?.firstName} ${data?.eventSurgeon?.lastName}`
                            : "NA"
                        }
                      />
                      <InfoItem
                        className="col-1-1 info-row-column"
                        icon={
                          <svg
                            color={getRandomColor()}
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            style={{
                              height: "20px",
                              width: "20px",
                            }}
                          >
                            <path d="M12 6v4"></path>
                            <path d="M14 14h-4"></path>
                            <path d="M14 18h-4"></path>
                            <path d="M14 8h-4"></path>
                            <path d="M18 12h2a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-9a2 2 0 0 1 2-2h2"></path>
                            <path d="M18 22V4a2 2 0 0 0-2-2H8a2 2 0 0 0-2 2v18"></path>
                          </svg>
                        }
                        label={"Facility"}
                        name={
                          data?.patientActivityDAO?.event_facility
                            ?.facilityName || "NA"
                        }
                      />
                    </div>
                    <div
                      className="info-row flex center-center"
                      style={{
                        gap: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      <InfoItem
                        className="col-1-1 info-row-column"
                        icon={
                          <svg
                            color={getRandomColor()}
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            style={{
                              height: "20px",
                              width: "20px",
                            }}
                          >
                            <path d="M18 20a6 6 0 0 0-12 0"></path>
                            <circle cx="12" cy="10" r="4"></circle>
                            <circle cx="12" cy="12" r="10"></circle>
                          </svg>
                        }
                        label={"Assistant"}
                        name={
                          data?.patientActivityDAO?.event_surgical_assitant
                            ? `${data?.patientActivityDAO?.event_surgical_assitant?.name} ${data?.patientActivityDAO?.event_surgical_assitant?.lastName} (${getUserTypeInfo(data?.patientActivityDAO?.event_surgical_assitant?.type || "")?.label || "NA"})`
                            : "NA"
                        }
                      />

                      <InfoItem
                        className="col-1-1 info-row-column"
                        icon={
                          <svg
                            color={getRandomColor()}
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            style={{
                              height: "20px",
                              width: "20px",
                            }}
                          >
                            <path d="M2 18a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v2z"></path>
                            <path d="M10 10V5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v5"></path>
                            <path d="M4 15v-3a6 6 0 0 1 6-6h0"></path>
                            <path d="M14 6h0a6 6 0 0 1 6 6v3"></path>
                          </svg>
                        }
                        label={"Event Type"}
                        name={data?.eventType?.eventTypeName || "NA"}
                      />
                    </div>
                    <div
                      className="info-row flex center-center"
                      style={{
                        gap: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      <InfoItem
                        className="col-1-1 info-row-column"
                        icon={
                          <svg
                            color={getRandomColor()}
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="lucide lucide-map-pin h-4 w-4 text-muted-foreground"
                            data-id="72"
                            style={{
                              height: "20px",
                              width: "20px",
                            }}
                          >
                            <path d="M20 10c0 6-8 12-8 12s-8-6-8-12a8 8 0 0 1 16 0Z"></path>
                            <circle cx="12" cy="10" r="3"></circle>
                          </svg>
                        }
                        label={"Place of Service"}
                        name={
                          data?.placeOfService?.name
                            ? `${data?.placeOfService?.name}`
                            : "NA"
                        }
                      />
                      <InfoItem
                        className="col-1-1 info-row-column"
                        icon={
                          <svg
                            color={getRandomColor()}
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="lucide lucide-flag h-4 w-4 text-muted-foreground"
                            data-id="76"
                            style={{
                              height: "20px",
                              width: "20px",
                            }}
                          >
                            <path d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1z"></path>
                            <line x1="4" x2="4" y1="22" y2="15"></line>
                          </svg>
                        }
                        label={"Event Flags"}
                        name={
                          data?.eventFlags?.scheduleFlag
                            ? `${data?.eventFlags?.scheduleFlag}`
                            : "NA"
                        }
                      />
                    </div>

                    <div
                      className="info-row flex center-center"
                      style={{
                        gap: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      <div className="col-1-1 info-row-column">
                        <div
                          className="flex center-left"
                          style={{
                            gap: "10px",
                            alignItems: "flex-start",
                          }}
                        >
                          <div>
                            <svg
                              color={getRandomColor()}
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="lucide lucide-info h-4 w-4 text-muted-foreground"
                              data-id="30"
                              style={{
                                height: "20px",
                                width: "20px",
                              }}
                            >
                              <circle cx="12" cy="12" r="10"></circle>
                              <path d="M12 16v-4"></path>
                              <path d="M12 8h.01"></path>
                            </svg>
                          </div>
                          <div
                            style={{
                              overflow: "hidden",
                            }}
                          >
                            <p
                              className="f-w-300"
                              style={{
                                color: "#aaaaaa",
                                fontSize: "10px",
                                lineHeight: 1,
                                paddingBottom: "2px",
                              }}
                            >
                              Procedures
                            </p>
                            <p
                              style={{
                                whiteSpace: "normal",
                                overflowWrap: "break-word",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                width: "100%",
                              }}
                            >
                              {data?.procedure?.length
                                ? data.procedure
                                    .map((procedure) => procedure.procedureName)
                                    .join(", ")
                                : "NA"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr
                      style={{
                        marginTop: "10px",
                        paddingBottom: "10px",
                      }}
                    />
                    <div style={{ marginBottom: "16px" }}>
                      <p
                        style={{
                          fontWeight: "700",
                          paddingBottom: "8px",
                        }}
                      >
                        Files
                      </p>
                      <div
                        style={{
                          display: "grid",
                          gap: "16px",
                        }}
                      >
                        {data.patientActivityDAO?.files.length ? (
                          data.patientActivityDAO?.files.map((file, index) => {

                             const type = file.type; 
                             typeCounters[type] = (typeCounters[type] || 0) + 1;

                         return  ( <CustomModal
							  key={index}
                              selector={
                                <div
								className="cursor-pointer"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    padding: "5px 12px",
                                    backgroundColor: "rgb(245, 245, 245)",
                                    borderRadius: "8px",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "12px",
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      style={{
                                        height: "20px",
                                        width: "20px",
                                      }}
                                    >
                                      <path d="M15 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7Z"></path>
                                      <path d="M14 2v4a2 2 0 0 0 2 2h4"></path>
                                      <path d="M10 9H8"></path>
                                      <path d="M16 13H8"></path>
                                      <path d="M16 17H8"></path>
                                    </svg>
                                    <div>
                                      <div
                                        style={{
                                          fontSize: "12px",
                                          fontWeight: "400",
                                        }}
                                        className="t-t-u l-s-n"
                                      >
                                        {`${data?.patientActivityDAO?.patient?.firstName ? data?.patientActivityDAO?.patient?.firstName+"-" :""}${data?.patientActivityDAO?.patient?.lastName? data?.patientActivityDAO?.patient?.lastName+"-" :""}${file.type || ""}-${typeCounters[file.type]}` }
                                      </div>
                                      <div
                                        style={{
                                          fontSize: "10px",
                                          color: "#7D7D7D",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {file.type}
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "8px",
                                    }}
                                  >
                                    <span
                                      style={{
                                        background: "transparent",
                                        border: "none",
                                        cursor: "pointer",
                                      }}
                                      title="View file"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        style={{
                                          height: "16px",
                                          width: "16px",
                                          color: "#000",
                                        }}
                                      >
                                        <path d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z"></path>
                                        <circle cx="12" cy="12" r="3"></circle>
                                      </svg>
                                    </span>
                                  </div>
                                </div>
                              }
                              content={
                                <FullScreenFileViewerModal
                                  title={`Document - (${data?.patientActivityDAO?.patient?.firstName ? data?.patientActivityDAO?.patient?.firstName+"-" :""}${data?.patientActivityDAO?.patient?.lastName||""}-${file.type || ""}-${typeCounters[file.type]})`}
                                  fileUrl={file?.fileS3Url || null}
                                />
                              }
                            />)
                          }
                          )
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              padding: "5px 12px",
                              backgroundColor: "rgb(245, 245, 245)",
                              borderRadius: "8px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "12px",
                              }}
                            >
                              <div>
                                <div
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "400",
                                  }}
                                >
                                  No file found.
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <hr
                      style={{
                        marginTop: "10px",
                        paddingBottom: "12px",
                      }}
                    />

                    <div
                      className="info-row flex center-center"
                      style={{
                        gap: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      <InfoItem
                        className="col-1-1 info-row-column"
                        icon={
                          <svg
                            color={getRandomColor()}
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            data-id="71"
                            style={{
                              height: "20px",
                              width: "20px",
                            }}
                          >
                            <path d="M8 2v4"></path>
                            <path d="M16 2v4"></path>
                            <rect
                              width="18"
                              height="18"
                              x="3"
                              y="4"
                              rx="2"
                            ></rect>
                            <path d="M3 10h18"></path>
                          </svg>
                        }
                        label={"Admission Date"}
                        name={
                          data?.patientActivityDAO?.admission_date
                            ? formatDate(
                                // convertToYYYYMMDD(
                                data?.patientActivityDAO?.admission_date
                                // )
                              )
                            : "NA"
                        }
                      />

                      <InfoItem
                        className="col-1-1 info-row-column"
                        icon={
                          <svg
                            color={getRandomColor()}
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            style={{
                              height: "20px",
                              width: "20px",
                            }}
                          >
                            <path d="M21 7.5V6a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h3.5"></path>
                            <path d="M16 2v4"></path>
                            <path d="M8 2v4"></path>
                            <path d="M3 10h5"></path>
                            <path d="M17.5 17.5L16 16.3V14"></path>
                            <circle cx="16" cy="16" r="6"></circle>
                          </svg>
                        }
                        label={"Start Date & Time"}
                        name={
                          (data?.patientActivityDAO?.event_start_date
                            ? formatDate(
                                data?.patientActivityDAO?.event_start_date
                              )
                            : "NA") +
                          " " +
                          (data?.patientActivityDAO?.event_start_time || "")
                        }
                      />
                    </div>
                    <div
                      className="info-row flex center-center"
                      style={{
                        gap: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      <InfoItem
                        className="col-1-1 info-row-column"
                        icon={
                          <svg
                            color={getRandomColor()}
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            style={{
                              height: "20px",
                              width: "20px",
                            }}
                          >
                            <path d="M21 7.5V6a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h3.5"></path>
                            <path d="M16 2v4"></path>
                            <path d="M8 2v4"></path>
                            <path d="M3 10h5"></path>
                            <path d="M17.5 17.5L16 16.3V14"></path>
                            <circle cx="16" cy="16" r="6"></circle>
                          </svg>
                        }
                        label={"End Date & Time"}
                        name={
                          (data?.patientActivityDAO?.event_end_date
                            ? formatDate(
                                data?.patientActivityDAO?.event_end_date
                              )
                            : "NA") +
                          " " +
                          (data?.patientActivityDAO?.event_end_time || "")
                        }
                      />
                    </div>

                    <hr
                      style={{
                        marginTop: "10px",
                        paddingBottom: "10px",
                      }}
                    />

                    <div
                      className="info-row flex center-center"
                      style={{
                        gap: "10px",
                        paddingBottom: "20px",
                      }}
                    >
                      <div className="col-1-1 info-row-column">
                        <div
                          className="flex center-left"
                          style={{ gap: "10px" }}
                        >
                          <div>
                            <svg
                              color={getRandomColor()}
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="lucide lucide-info h-4 w-4 text-muted-foreground"
                              data-id="30"
                              style={{
                                height: "20px",
                                width: "20px",
                              }}
                            >
                              <circle cx="12" cy="12" r="10"></circle>
                              <path d="M12 16v-4"></path>
                              <path d="M12 8h.01"></path>
                            </svg>
                          </div>
                          <div
                            style={{
                              overflow: "hidden",
                            }}
                          >
                            <p
                              className="f-w-300"
                              style={{
                                color: "#aaaaaa",
                                fontSize: "10px",
                                lineHeight: 1,
                                paddingBottom: "2px",
                              }}
                            >
                              Note
                            </p>
                            <p
                              style={{
                                whiteSpace: "normal",
                                overflowWrap: "break-word",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                width: "100%",
                              }}
                            >
                              {data?.patientActivityDAO?.event_notes || "NA"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="info-row flex center-center"
                      style={{
                        gap: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      <InfoItem
                        className="col-1-1 info-row-column"
                        label={"Created By"}
                        name={
                          data?.createdBy
                            ? `${data?.createdBy?.name} ${data?.createdBy?.lastName}`
                            : "NA"
                        }
                      />
                      <InfoItem
                        className="col-1-1 info-row-column"
                        label={"Modified By"}
                        name={
                          data?.modifiedBy
                            ? `${data?.modifiedBy?.name} ${data?.modifiedBy?.lastName}`
                            : "NA"
                        }
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div>
              {permissions?.isUpdateAllowed && !(data?.invoiceStatus === 2 ||data?.invoiceStatus === 4 ) && !loading && (
                <NavLink
                  to={
                    data?.patientActivityDAO?.id
                      ? {
                          pathname: "/edit-activity",
                          search: `?next=${window.location.pathname + window.location.search}`,
                          state: {
                            id: data?.patientActivityDAO?.id,
                          },
                        }
                      : "#"
                  }
                >
                  <ButtonLightTextIcon
                    title="Edit Details"
                    icon={
                      <svg
                        width="15"
                        height="15"
                        style={{
                          height: "15px",
                          width: "15px",
                        }}
                        viewBox="0 0 24 24"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M21.1213 2.70705C19.9497 1.53548 18.0503 1.53547 16.8787 2.70705L15.1989 4.38685L7.29289 12.2928C7.16473 12.421 7.07382 12.5816 7.02986 12.7574L6.02986 16.7574C5.94466 17.0982 6.04451 17.4587 6.29289 17.707C6.54127 17.9554 6.90176 18.0553 7.24254 17.9701L11.2425 16.9701C11.4184 16.9261 11.5789 16.8352 11.7071 16.707L19.5556 8.85857L21.2929 7.12126C22.4645 5.94969 22.4645 4.05019 21.2929 2.87862L21.1213 2.70705ZM18.2929 4.12126C18.6834 3.73074 19.3166 3.73074 19.7071 4.12126L19.8787 4.29283C20.2692 4.68336 20.2692 5.31653 19.8787 5.70705L18.8622 6.72357L17.3068 5.10738L18.2929 4.12126ZM15.8923 6.52185L17.4477 8.13804L10.4888 15.097L8.37437 15.6256L8.90296 13.5112L15.8923 6.52185ZM4 7.99994C4 7.44766 4.44772 6.99994 5 6.99994H10C10.5523 6.99994 11 6.55223 11 5.99994C11 5.44766 10.5523 4.99994 10 4.99994H5C3.34315 4.99994 2 6.34309 2 7.99994V18.9999C2 20.6568 3.34315 21.9999 5 21.9999H16C17.6569 21.9999 19 20.6568 19 18.9999V13.9999C19 13.4477 18.5523 12.9999 18 12.9999C17.4477 12.9999 17 13.4477 17 13.9999V18.9999C17 19.5522 16.5523 19.9999 16 19.9999H5C4.44772 19.9999 4 19.5522 4 18.9999V7.99994Z"
                          fill="#283f54"
                        />
                      </svg>
                    }
                  />
                </NavLink>
              )}
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};
 