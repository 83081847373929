import { NavLink, withRouter } from "react-router-dom";
import ButtonLightTextIcon from "../../components/common/ui/ButtonLightTextIcon";
import { decodeModuleAccessFromToken, formatDate } from "../../opl-utilities/helpers";
import { useEffect, useState } from "react";
import { API_URL } from "../../opl-utilities/constants";
import LoaderIcon from "../../components/common/ui/LoaderIcon";



const getRandomColor = () => {
    const array = ["#84B8A4", "#72B5E5", "#CBA5DB", "#CFA96D", "#E8823D"];
    const randomIndex = Math.floor(Math.random() * array.length);
    return array[randomIndex];
};
const ProcedureDetails = ({ setShow, id, status, history }) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    // Decode permissions for the "procedures" module
    const permissions = decodeModuleAccessFromToken("procedures");

    // Redirect to login if no permissions
    useEffect(() => {
        if (!permissions) {
            history.push("/login");
        }
    }, [permissions, history]);

    const InfoItem = ({ icon, label, name, ...rest }) => {
        return (
            <div {...rest}>
                <div className='flex center-left' style={{ gap: "10px" }}>
                    <div>{icon}</div>
                    <div style={{ overflow: "hidden" }}>
                        <p
                            className='f-w-300'
                            style={{
                                color: "#aaaaaa",
                                fontSize: "10px",
                                lineHeight: 1,
                                paddingBottom: "2px",
                            }}>
                            {label}
                        </p>
                        <p
                            style={{
                                width: "200px",
                                lineHeight: 1.2,
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                            }}>
                            {name}
                        </p>
                    </div>
                </div>
            </div>
        );
    };

    const fetchFacilityData = async (id) => {
        try {
            const resp = await fetch(
                API_URL +
                `/api/1.0.0/procedure-list/${id}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "X-JWT-Assertion": localStorage.getItem("token")
                    },
                }
            );
            if (resp.status === 401 || resp.status === 403) {
                if (resp.status === 401) {
                  localStorage.clear();
                  window.location.href = "/login";
                } else if (resp.status === 403) {
                    setError("Access denied. You do not have permission to perform this action.");
                    setLoading(false);
                }
                return false;
              }
            if (resp.ok) {
                const response = await resp.json();
                if (response) {
                    setData(response.data);
                    setLoading(false);
                }
            }
        } catch (e) {
            setError("Failed to fetch facility data.");
            setLoading(false);
            console.log("Error:", e);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (id) fetchFacilityData(id);
    }, [id]);

    return (
        <section id='opl_coremax'>
            <section id='custom-modal'>
                <div className='inner'>
                    <div className='box' id='activity-detail-box'>
                        <div className='flex center-left'>
                            <div>
                                <h6 className='color-primary f-w-300'>
                                    Procedure Details
                                </h6>
                            </div>
                            <div style={{ marginLeft: "auto" }}>
                                <ButtonLightTextIcon
                                    title='Cancel'
                                    onClick={() => setShow(false)}
                                    icon={
                                        <svg
                                            clipRule="evenodd"
                                            fillRule="evenodd"
                                            strokeLinejoin="round"
                                            strokeMiterlimit="2"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width='19.995'
                                            height='19.996'
                                        >
                                            <path
                                                d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 1.5c-4.69 0-8.497 3.807-8.497 8.497s3.807 8.498 8.497 8.498 8.498-3.808 8.498-8.498-3.808-8.497-8.498-8.497zm0 7.425 2.717-2.718c.146-.146.339-.219.531-.219.404 0 .75.325.75.75 0 .193-.073.384-.219.531l-2.717 2.717 2.727 2.728c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.384-.073-.53-.219l-2.729-2.728-2.728 2.728c-.146.146-.338.219-.53.219-.401 0-.751-.323-.751-.75 0-.192.073-.384.22-.531l2.728-2.728-2.722-2.722c-.146-.147-.219-.338-.219-.531 0-.425.346-.749.75-.749.192 0 .385.073.531.219z"
                                                fillRule="nonzero" 
                                            />
                                        </svg>
                                    }
                                />
                            </div>
                        </div>
                        <div
                            style={{
                                height: "60vh",
                                margin: "10px",
                                overflowY: "scroll",
                                padding: "20px 5px",
                                overflowX: "hidden",
                                scrollbarWidth: "thin",
                            }}>
                            {loading && (
                                <div
                                    className='col-1-1'
                                    style={{
                                        height: "40px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}>
                                    <LoaderIcon
                                        style={{
                                            width: "20px",
                                            height: "20px",
                                        }}
                                    />
                                </div>
                            )}
                            {!loading && error && (
                                <p
                                    className='f-w-300 color-primary'
                                    style={{ color: "#c0392b" }}>
                                    {error}
                                </p>
                            )}
                            {data && !loading && !error && (
                                <div className='activity-card pos-rel'>
                                    <div
                                        style={{
                                            position: "absolute",
                                            content: "",
                                            height: "4px",
                                            left: "-1px",
                                            right: "-1px",
                                            bottom: "-2px",
                                            borderBottomLeftRadius: "4px",
                                            borderBottomRightRadius: "4px",
                                        }}></div>
                                    <div
                                        className='col-1-1 flex center-left'
                                        style={{
                                            gap: "10px",
                                            paddingBottom: "5px",
                                        }}>
                                        <h5 className='f-w-700'>
                                            <span className='f-w-300'>#</span>
                                            &nbsp;
                                            {data?.id}
                                        </h5>
                                        <hr
                                            style={{
                                                outline: "none",
                                                border: "none",
                                                height: "16px",
                                                width: "2px",
                                                background: "#EBEBEB",
                                            }}
                                        />
                                    </div>
                                    <div
                                        style={{
                                            padding: "12px 0 12px 0 ",
                                        }}>
                                        <div
                                            className='info-row flex center-center'
                                            style={{
                                                gap: "10px",
                                                paddingBottom: "10px",
                                            }}>
                                            <InfoItem
                                                className='col-1-1 info-row-column'
                                                icon={
                                                    <svg
                                                        color={getRandomColor()}
                                                        width='24'
                                                        height='24'
                                                        viewBox='0 0 24 24'
                                                        fill='none'
                                                        stroke='currentColor'
                                                        strokeWidth='2'
                                                        strokeLinecap='round'
                                                        strokeLinejoin='round'
                                                        style={{
                                                            height: "20px",
                                                            width: "20px",
                                                        }}>
                                                        <path d='M18 20a6 6 0 0 0-12 0'></path>
                                                        <circle
                                                            cx='12'
                                                            cy='10'
                                                            r='4'></circle>
                                                        <circle
                                                            cx='12'
                                                            cy='12'
                                                            r='10'></circle>
                                                    </svg>
                                                }
                                                label={"Procedure"}
                                                name={
                                                    data?.procedureName || "NA"
                                                }
                                            />
                                            <InfoItem
                                                className='col-1-1 info-row-column'
                                                icon={
                                                    <svg
                                                    color={getRandomColor()}
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    width='24'
                                                    height='24'
                                                    viewBox='0 0 24 24'
                                                    fill='none'
                                                    stroke='currentColor'
                                                    strokeWidth='2'
                                                    strokeLinecap='round'
                                                    strokeLinejoin='round'
                                                    data-id='28'
                                                    style={{
                                                        height: "20px",
                                                        width: "20px",
                                                    }}>
                                                    <path d='M19 7V4a1 1 0 0 0-1-1H5a2 2 0 0 0 0 4h15a1 1 0 0 1 1 1v4h-3a2 2 0 0 0 0 4h3a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1'></path>
                                                    <path d='M3 5v14a2 2 0 0 0 2 2h15a1 1 0 0 0 1-1v-4'></path>
                                                </svg>

                                                }
                                                label={"CPT Code"}
                                                name=
                                                {data?.cptCode || "NA"}
                                            />
                                        </div>
                                        <div
                                            className='info-row flex center-center'
                                            style={{
                                                gap: "10px",
                                                paddingBottom: "10px",
                                            }}>
                                            <InfoItem
                                                className='col-1-1 info-row-column'
                                                icon={
                                                    <svg
                                                        color={getRandomColor()}
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        width='24'
                                                        height='24'
                                                        viewBox='0 0 24 24'
                                                        fill='none'
                                                        stroke='currentColor'
                                                        strokeWidth='2'
                                                        strokeLinecap='round'
                                                        strokeLinejoin='round'
                                                        data-id='28'
                                                        style={{
                                                            height: "20px",
                                                            width: "20px",
                                                        }}>
                                                        <path d='M19 7V4a1 1 0 0 0-1-1H5a2 2 0 0 0 0 4h15a1 1 0 0 1 1 1v4h-3a2 2 0 0 0 0 4h3a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1'></path>
                                                        <path d='M3 5v14a2 2 0 0 0 2 2h15a1 1 0 0 0 1-1v-4'></path>
                                                    </svg>
                                                }
                                                label={"Related Dx Code"}
                                                name={
                                                    data?.relatedDxCode
                                                    || "NA"
                                                }
                                            />
                                            <InfoItem
                                                className='col-1-1 info-row-column'
                                                icon={
                                                    <svg
                                                        color={getRandomColor()}
                                                        width='24'
                                                        height='24'
                                                        viewBox='0 0 24 24'
                                                        fill='none'
                                                        stroke='currentColor'
                                                        strokeWidth='2'
                                                        strokeLinecap='round'
                                                        strokeLinejoin='round'
                                                        style={{
                                                            height: "20px",
                                                            width: "20px",
                                                        }}>
                                                        <path d="M5.829 6c-.412 1.165-1.524 2-2.829 2-1.656 0-3-1.344-3-3s1.344-3 3-3c1.305 0 2.417.835 2.829 2h13.671c2.484 0 4.5 2.016 4.5 4.5s-2.016 4.5-4.5 4.5h-4.671c-.412 1.165-1.524 2-2.829 2-1.305 0-2.417-.835-2.829-2h-4.671c-1.38 0-2.5 1.12-2.5 2.5s1.12 2.5 2.5 2.5h13.671c.412-1.165 1.524-2 2.829-2 1.656 0 3 1.344 3 3s-1.344 3-3 3c-1.305 0-2.417-.835-2.829-2h-13.671c-2.484 0-4.5-2.016-4.5-4.5s2.016-4.5 4.5-4.5h4.671c.412-1.165 1.524-2 2.829-2 1.305 0 2.417.835 2.829 2h4.671c1.38 0 2.5-1.12 2.5-2.5s-1.12-2.5-2.5-2.5h-13.671zm6.171 5c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1z" />
                                                    </svg>
                                                }
                                                label={"Specialty"}
                                                name={
                                                    data?.specialty?.name
                                                    || "NA"
                                                }
                                            />
                                        </div>
                                        <div
                                            className='info-row flex center-center'
                                            style={{
                                                gap: "10px",
                                                paddingBottom: "10px",
                                            }}>
                                            <InfoItem
                                                className='col-1-1 info-row-column'
                                                icon={
                                                    <svg
                                                        color={getRandomColor()}
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        width='24'
                                                        height='24'
                                                        viewBox='0 0 24 24'
                                                        fill='none'
                                                        stroke='currentColor'
                                                        // strokeWidth='2'
                                                        style={{
                                                            height: "20px",
                                                            width: "20px",
                                                        }}>
                                                        <path d="M15.985 17.031c-1.479 1.238-3.384 1.985-5.461 1.985-4.697 0-8.509-3.812-8.509-8.508s3.812-8.508 8.509-8.508c4.695 0 8.508 3.812 8.508 8.508 0 2.078-.747 3.984-1.985 5.461l4.749 4.75c.146.146.219.338.219.531 0 .587-.537.75-.75.75-.192 0-.384-.073-.531-.22zm-5.461-13.53c-3.868 0-7.007 3.14-7.007 7.007s3.139 7.007 7.007 7.007c3.866 0 7.007-3.14 7.007-7.007s-3.141-7.007-7.007-7.007zm.741 8.499h-4.5c-.414 0-.75.336-.75.75s.336.75.75.75h4.5c.414 0 .75-.336.75-.75s-.336-.75-.75-.75zm3-2.5h-7.5c-.414 0-.75.336-.75.75s.336.75.75.75h7.5c.414 0 .75-.336.75-.75s-.336-.75-.75-.75zm0-2.5h-7.5c-.414 0-.75.336-.75.75s.336.75.75.75h7.5c.414 0 .75-.336.75-.75s-.336-.75-.75-.75z" />
                                                        </svg>
                                                }
                                                label={"CPT Description"}
                                                name={
                                                    data?.cptDescription || "NA"
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div>
                            {permissions?.isUpdateAllowed &&
                                <NavLink
                                    to={
                                        data?.id
                                            ? {
                                                pathname: "/edit-procedure",
                                                search: `?next=${window.location.pathname + window.location.search}`,
                                                state: {
                                                    id: data?.id,
                                                },
                                            }
                                            : "#"
                                    }>
                                    <ButtonLightTextIcon
                                        title='Edit Procedure'
                                        icon={
                                            <svg
                                                width='15'
                                                height='15'
                                                style={{
                                                    height: "15px",
                                                    width: "15px",
                                                }}
                                                viewBox='0 0 24 24'>
                                                <path
                                                    fillRule='evenodd'
                                                    clipRule='evenodd'
                                                    d='M21.1213 2.70705C19.9497 1.53548 18.0503 1.53547 16.8787 2.70705L15.1989 4.38685L7.29289 12.2928C7.16473 12.421 7.07382 12.5816 7.02986 12.7574L6.02986 16.7574C5.94466 17.0982 6.04451 17.4587 6.29289 17.707C6.54127 17.9554 6.90176 18.0553 7.24254 17.9701L11.2425 16.9701C11.4184 16.9261 11.5789 16.8352 11.7071 16.707L19.5556 8.85857L21.2929 7.12126C22.4645 5.94969 22.4645 4.05019 21.2929 2.87862L21.1213 2.70705ZM18.2929 4.12126C18.6834 3.73074 19.3166 3.73074 19.7071 4.12126L19.8787 4.29283C20.2692 4.68336 20.2692 5.31653 19.8787 5.70705L18.8622 6.72357L17.3068 5.10738L18.2929 4.12126ZM15.8923 6.52185L17.4477 8.13804L10.4888 15.097L8.37437 15.6256L8.90296 13.5112L15.8923 6.52185ZM4 7.99994C4 7.44766 4.44772 6.99994 5 6.99994H10C10.5523 6.99994 11 6.55223 11 5.99994C11 5.44766 10.5523 4.99994 10 4.99994H5C3.34315 4.99994 2 6.34309 2 7.99994V18.9999C2 20.6568 3.34315 21.9999 5 21.9999H16C17.6569 21.9999 19 20.6568 19 18.9999V13.9999C19 13.4477 18.5523 12.9999 18 12.9999C17.4477 12.9999 17 13.4477 17 13.9999V18.9999C17 19.5522 16.5523 19.9999 16 19.9999H5C4.44772 19.9999 4 19.5522 4 18.9999V7.99994Z'
                                                    fill='#283f54'
                                                />
                                            </svg>
                                        }
                                    />
                                </NavLink>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </section>
    );
};
export default withRouter(ProcedureDetails);
