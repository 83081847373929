import "../styles/base.scss";
import "../styles/pages.scss";
import "../styles/animations.scss";
import "../styles/responsive.scss";
import { useEffect, useState } from "react";
import { API_URL, SYSTEM_TOKEN } from "../opl-utilities/constants";
import { NavLink, withRouter } from "react-router-dom";
import LoaderIcon from "../components/common/ui/LoaderIcon";
import Header from "../components/common/ui/Header";
import ButtonTextIcon from "../components/common/ui/ButtonTextIcon";
import ResultsPerPage from "../components/common/ResultsPerPage";
import Pagination from "../components/common/Pagination";
import ActivityFilter from "./activities/ActivityFilter";
import PaginatedDropdown from "../components/common/ui/PaginatedDropdown";
import ButtonLightTextIcon from "../components/common/ui/ButtonLightTextIcon";
import ConfirmationModal from "../components/common/ui/ConfirmationModal";
import { decodeModuleAccessFromToken, formatDate } from "../opl-utilities/helpers";
import UserFilter from "./setupuser/UserFilter";
import PopUpModal from "../components/common/PopUpModal";
import UserView from "./setupuser/ViewUser";
import ButtonTertiary from "../components/common/ui/ButtonTertiary";
import CustomModal from "../components/common/CustomModal";
import { useUI } from "../context/ui";

const jsonToQueryParams = (json) => {
    const queryParams = Object.keys(json)
        .filter((key) => json[key] !== null && json[key] !== "")
        .map(
            (key) =>
                `${encodeURIComponent(key)}=${encodeURIComponent(json[key])}`
        )
        .join("&");

    return queryParams;
};

const ArchivedUser = ({ history, location, activePage }) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const pageNo = new URLSearchParams(location.search).get("pageNo") || 0;
    const pageSize = new URLSearchParams(location.search).get("pageSize") || 12;
    const queryParams = new URLSearchParams(location.search);
	const [viewActivity, setViewActivity] = useState(false);
  const [ueserid, setueserid] = useState(null);
  const { addToastMessage } = useUI();
	// Decode permissions for the "users" module
	const permissions = decodeModuleAccessFromToken("users");

	// Redirect to login if no permissions
	useEffect(() => {
		if (!permissions) {
			history.push("/login");
		}
	}, [permissions, history]);
    const search = queryParams.get("search") || "";

    const resetFilters = () => {
        updateQueryParam("search", "");
        updateQueryParam("pageNo", 0);
    };
    const updateQueryParam = (key, value) => {
        value ? queryParams.set(key, value) : queryParams.delete(key);
        history.push({ search: queryParams.toString() });
    }; 
    const callListApi = async () => {
        setLoading(true);
        const query = jsonToQueryParams({
            search: search, 
        });
        try {
            const resp = await fetch(
                `${API_URL}/api/1.0.0/users/list?pageSize=${pageSize}&pageNo=${pageNo}&${query}&showArchivedUsers=show`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "X-JWT-Assertion": localStorage.getItem("token")
                    },
                }
          );
          if (resp.status === 401 || resp.status === 403) {
            if (resp.status === 401) {
                localStorage.clear();
                window.location.href = "/login";
            } else if (resp.status === 403) {
                setLoading(false);
            }
            return false; 
        }
        

            if (resp.ok) {
                const response = await resp.json();
                if (response.result.responseCode === 200) {
                    setData(response.data);
                } else {
                    setData(null);
                }
            }
        } catch (e) {
            console.error("Error", e);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        callListApi();
    }, [location.search]);

    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const handleUnArchive = async (id) => {
      try {
        if (!id) {
          addToastMessage({
            status: false,
            message: `Something went wrong while unarchiving user. Please try again.`,
          });
          return false;
        }
        const response = await fetch(
          API_URL + `/api/1.0.0/users/un-archive/${id}`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              "X-JWT-Assertion": localStorage.getItem("token"),
            },
          }
        );
        if (response.status === 401 || response.status === 403) {
          if (response.status === 401) {
            localStorage.clear();
            window.location.href = "/login";
          } else if (response.status === 403) {
            addToastMessage({
              status: false,
              message:
                "Access denied. You do not have permission to perform this action.",
            });
          }
          return false;
        }
        if (response.status === 200) {
          await callListApi();
          addToastMessage({
            status: true,
            message: `User unarchived successfully.`,
          });
        } else {
          const resp = await response.json();
          addToastMessage({
            status: false,
            message:
              resp?.responseDescription ||
              `Something went wrong while unarchiving user. Please try again.`,
          });
        }
        return true;
      } catch (error) {
        addToastMessage({
          status: false,
          message: `Something went wrong while unarchiving user. Please try again.`,
        });
        return false;
      }
    };

    const handlePermanentDelete = async (id) => {
      try {
        if (!id) {
          addToastMessage({
            status: false,
            message: `Something went wrong while permanently delete user. Please try again.`,
          });
          return false;
        }
        const response = await fetch(
          API_URL + `/api/1.0.0/users/permanent-delete/${id}`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              "X-JWT-Assertion": localStorage.getItem("token"),
            },
          }
        );
        if (response.status === 401 || response.status === 403) {
          if (response.status === 401) {
            localStorage.clear();
            window.location.href = "/login";
          } else if (response.status === 403) {
            addToastMessage({
              status: false,
              message:
                "Access denied. You do not have permission to perform this action.",
            });
          }
          return false;
        }
        if (response.status === 200) {
          await callListApi();
          addToastMessage({
            status: true,
            message: `User permanently delete successfully.`,
          });
        } else {
          const resp = await response.json();
          addToastMessage({
            status: false,
            message:
              resp?.responseDescription ||
              `Something went wrong while permanently delete user. Please try again.`,
          });
        }
        return true;
      } catch (error) {
        addToastMessage({
          status: false,
          message: `Something went wrong while permanently delete user. Please try again.`,
        });
        return false;
      }
    };

    const handleConfirmDelete = async () => {
        alert("Deleted");
        setShowDeleteModal(false);
    };

    return (
        <section id='opl_coremax'>
            <section className='main-section'>
          <Header active={activePage} location={location} />
                <section id='activity-v2'>
                    <div className='col-1-1 flex center-left pad-0-10 main-section-top-row'>
                        <h5 className='page-active color-primary f-w-700'>
                Archived Users
              </h5>
            </div>
            <UserFilter
              resetFilters={() => {
                resetFilters();
              }}
                    /><br />

            <div>
              {data && !loading && (
                <div
                                className='flex center-left'
                  style={{
                    padding: "10px 15px 10px 15px",
                    gap: "12px",
                                }}>
                                <p
                                    className='f-w-300'
                                    style={{ fontSize: "11px" }}>
                    Page no:
                  </p>
                  <Pagination
                    totalRecords={data.totalRecords}
                    pageNo={data.pageNo}
                    pageSize={data.pageSize}
                  />
                </div>
              )}
            </div>
            {loading ? (
              <section
                style={{
                  height: "100vh",
                  paddingBottom: "20vh",
                                backgroundColor: "#fafaff"
                }}
                            className='flex center-center'>
                            <LoaderIcon
                                style={{ height: "32px", width: "32px" }}
                            />
              </section>
            ) : data?.records.length && data?.records.length ? (
              <div className="rs-table-holder">
                <table className="rs-table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Type</th>
                      <th>Active Start</th>
                      <th>Active End</th>
                      <th>Notes</th>
                      <th style={{ textAlign: 'center' }}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                                    {data && data.records.map((data, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{data.fullName}</td>
                          <td>{data.email}</td>
                          <td>{data.type}</td>
                                            <td>{data.activeStart ? formatDate(data.activeStart) : "NA"}</td>
                                            <td>{data.activeEnd ? formatDate(data.activeEnd) : "NA"}</td>
                          <td>{data.notes}</td>
                                        <td style={{ textAlign: 'center' }}>
                                          
                                          <div className="flex " style={{ gap: "10px", justifyContent: 'center' }}>
                            {permissions?.isReadAllowed /* Later add view pop up as well... */ && (
                                <div
                                  onClick={() => {
                                    setueserid(data.id);
                                    setViewActivity(true);
                                  }}
                                >
                                  <ButtonTertiary
                                    actionType="view"
                                    title="View"
                                  />
                                </div>
                              )}
                              {permissions?.isDeleteAllowed && (
                                <div className="color-secondary transition hover-color-primary cursor-pointer ">
                                  <CustomModal
                                    selector={
                                      <ButtonTertiary
                                        actionType="reload"
                                        title="Un Archive"
                                      />
                                    }
                                    content={
                                      <ConfirmationModal
                                        onConfirm={async () => {
                                          await handleUnArchive(data.id);
                                          return true;
                                        }}
                                        message="Are you sure you want to unarchive this user?"
                                      />
                                    }
                                  />
                                </div>
                              )}
                               {permissions?.isDeleteAllowed && (
                                <div className="color-secondary transition hover-color-primary cursor-pointer ">
                                  <CustomModal
                                    selector={
                                      <ButtonTertiary
                                        actionType="delete"
                                        title="Permanently Delete"
                                      />
                                    }
                                    content={
                                      <ConfirmationModal
                                        onConfirm={async () => {
                                          await handlePermanentDelete(data.id);
                                          return true;
                                        }}
                                        message="Are you sure you want to permanently delete this user? Deleting this user will remove their account from the platform. This action cannot be undone, and the user cannot be restored once deleted.<br/><br/>Please confirm if you wish to proceed."
                                      />
                                    }
                                  />
                                </div>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <section
                style={{
                  height: "80vh",
                  paddingBottom: "20vh",
                  backgroundColor: "#fff",
                }}
                            className='flex center-center'>
                <p>No records found.</p>
              </section>
            )}
            <div>
              {data && !loading && (
                <div
                                className='flex center-center'
                  style={{
                    padding: "24px 15px 32px 15px",
                    gap: "12px",
                                }}>
                                <p
                                    className='f-w-300'
                                    style={{ fontSize: "11px" }}>
                    Page no:
                  </p>
                  <Pagination
                    totalRecords={data.totalRecords}
                    pageNo={data.pageNo}
                    pageSize={data.pageSize}
                  />
                </div>
              )}
            </div>
          </section>
        </section>
        {viewActivity && (
          <PopUpModal>
            <UserView
              history={history}
              ueserid={ueserid}
              setViewActivity={setViewActivity}
              redirectUrl="/archived-user"
              editbutton={false}
            />
          </PopUpModal>
        )}
      </section>
    );
};

export default withRouter(ArchivedUser);
