import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import ButtonTextIcon from "../../components/common/ui/ButtonTextIcon";
import LoaderIcon from "../../components/common/ui/LoaderIcon";
import { useRef, useState } from "react";
import {
	API_URL, 
} from "../../opl-utilities/constants";
import DragFileUpload from "../../components/common/ui/DragFileUpload";
import ButtonLightTextIcon from "../../components/common/ui/ButtonLightTextIcon";
import PaginatedDropdown from '../../components/common/ui/PaginatedDropdown';
import { useUI } from "../../context/ui";
import PhoneNumberInput from "../../components/common/ui/PhoneNumberInput";
import { formatInputPhoneNumber } from "../../opl-utilities/helpers";

const Label = ({ title, required }) => {
	return (
		<p
			className='color-primary f-w-600'
			style={{ paddingBottom: "5px" }}>
			{title}
			{required && (
				<span style={{ color: "red", marginLeft: "4px" }}>*</span>
			)}
		</p>
	);
};

const AddForm = ({ history }) => {
	const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState("");
	const { addToastMessage } = useUI(); 

	const initialValues = {
		insuranceContactDetails: [],
		insuranceDocuments: [],
		city: "",
		state: "",
		zip: "",
		addressLine2: "",
		addressLine1: "",
		masterPayerId: "",
		description: "",
		electronicPayerId: "",
		category: "",
		insuranceType: "",
	};

	const validationSchema = Yup.object({
		// insuranceContactDetails: Yup.array().of(
		// 	Yup.object({
		// 		contact_type: Yup.string().required("Contact Type is required"),
		// 		contact_info: Yup.string().required("Contact Information is required"),
		// 	})
		// ),
		description: Yup.string().required("Insurance Name is required"),
	});


	const createInsurance = async (values) => {
		setLoading(true);
		setStatus("");
		try {
			setLoading(false);
			// return true;
			const payload = JSON.stringify({
				insuranceDocuments: values.insuranceDocuments ? values.insuranceDocuments.map((item) => ({
					fileName: item.file,
					path: item.path,
					description: item.description,
				})) : [],
				insuranceContactDetails: values.insuranceContactDetails ? values.insuranceContactDetails.map((item) => ({
					type: item.contact_type, 
					information: item.contact_info, 
				})) : [],
				city: values.city?.label,
				state: values.state?.value,
				zip: values.zip,
				addressLine2: values.addressLine2,
				addressLine1: values.addressLine1,
				masterPayerId: values.masterPayerId,
				description: values.description,
				electronicPayerId: values.electronicPayerId,
				category: values.category,
				insuranceType: values.insuranceType,

			});

			const resp = await fetch(API_URL + `/api/1.0.0/insurance`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"X-JWT-Assertion": localStorage.getItem("token")
				},
				body: payload,
			});
			if (resp.status === 401 || resp.status === 403) {
				if (resp.status === 401) {
				  localStorage.clear();
				  window.location.href = "/login";
				} else if (resp.status === 403) {
				  addToastMessage({
					status: false,
					message:
					  "Access denied. You do not have permission to perform this action.",
				  });
					setLoading(false);
					setStatus(
						"Access denied. You do not have permission to perform this action."
					)
				}
				return false;
			  }
			if (resp.ok) {
				const response = await resp.json();
				if (response) {
					return response.result.responseCode === 200 ? true : false;
				} else {
					const responseData = await resp.json();
					if (responseData) {
						setStatus(`${responseData.responseDescription || "Something went wrong, please try again later."}`);
						addToastMessage({
							status: false,
							message: `${responseData.responseDescription || "Something went wrong, please try again later."}`,
						});
						return false;
					}
					setStatus("Something went wrong, please try again later.");
					addToastMessage({
						status: false,
						message: `Something went wrong, please try again later.`,
					});
					return false;
				}
			}else {
				const responseData = await resp.json();
				if (responseData) {
					setStatus(`${responseData.responseDescription || "Something went wrong, please try again later."}`);
					addToastMessage({
						status: false,
						message: `${responseData.responseDescription || "Something went wrong, please try again later."}`,
					});
					return false;
				}
				setStatus("Something went wrong, please try again later.");
				addToastMessage({
					status: false,
					message: `Something went wrong, please try again later.`,
				});
				return false;
			}
		} catch (e) {
			setStatus("Something went wrong, please try again later.");
            addToastMessage({
                status: false,
                message: `Something went wrong, please try again later.`,
            });
			console.log("Error:", e);
			return false;
		} finally {
			setLoading(false);
		}
	};
	const mapOptions = (records, valueKey, labelFormatter) =>
        records.map((record) => ({
            value: record[valueKey],
            label: labelFormatter(record),
            obj: record,
        }));

    const labelStateFunc = (o) => `${o.name}`;
    const labelStateCodeFunc = (o) => `${o.code}`;
    const labelCityFunc = (o) => `${o.name}`;

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			enableReinitialize={true}
			onSubmit={async (values, { resetForm }) => {
				setLoading(true);
				const api = await createInsurance(values);
				if (api) {
					resetForm();
					setStatus(
						"You have successfully created an insurance, thank you."
					); 
                    addToastMessage({
						status: true,
						message: `You have successfully created an insurance, thank you.`,
					})
					history.push("/insurance");
				} else {
					// setStatus("Something went wrong, please try again later.");
                    // addToastMessage({
					// 	status: false,
					// 	message: `Something went wrong, please try again later.`,
					// })
				}
			}}>
			{({
				values,
				setFieldValue,
				errors,
				touched,
				handleSubmit,
				validateForm,
			}) => (
				<Form>
					<div className='col-1-1'>
						<table>
							<tbody>
								<tr>
									<td colSpan={2}>
										<h5 className="color-primary">
											Basic Information
										</h5>
									</td>
								</tr>
								<br />
								<tr>
									<td width='50%'>
										<Label title='Insurance Name' required={true} />
										<Field
											type='text'
											placeholder='Enter Insurance Name'
											name='description'
											className={`col-1-1 primary ${errors.description &&
												touched.description
												? "error"
												: ""
												}`}
										/>
									</td>
									<td>
										<Label title='Electronic Payer ID' />
										<Field
											type='text'
											placeholder='Electronic Payer ID'
											name='electronicPayerId'
											className={`col-1-1 primary ${errors.electronicPayerId &&
												touched.electronicPayerId
												? "error"
												: ""
												}`}
										/>
									</td>
								</tr>
								<tr>
									<td width='50%'>
										<Label title='Master Payer ID' />
										<Field
											type='text'
											placeholder='Master Payer ID'
											name='masterPayerId'
											className={`col-1-1 primary ${errors.masterPayerId &&
												touched.masterPayerId
												? "error"
												: ""
												}`}
										/>
									</td>
									<td>
										<Label title='Payer Type' />
										<Field
											type='text'
											placeholder='Enter Payer Type'
											name='category'
											className={`col-1-1 primary ${errors.category &&
												touched.category
												? "error"
												: ""
												}`}
										/>
									</td>
								</tr>
								<tr>
									<td width='50%'>
										<Label title='Type Code' />
										<Field
											type='text'
											placeholder='Enter Type Code'
											name='insuranceType'
											className={`col-1-1 primary ${errors.insuranceType &&
												touched.insuranceType
												? "error"
												: ""
												}`}
										/>
									</td>
									<td>
										<Label title='Address' />
										<Field
											type='text'
											placeholder='Address'
											name='addressLine1'
											className={`col-1-1 primary ${errors.addressLine1 &&
												touched.addressLine1
												? "error"
												: ""
												}`}
										/>
									</td>
								</tr>
								<tr>
									<td width='50%'>
										<Label title='Address 2' />
										<Field
											type='text'
											placeholder='Enter Address 2'
											name='addressLine2'
											className={`col-1-1 primary ${errors.addressLine2 &&
												touched.addressLine2
												? "error"
												: ""
												}`}
										/>
									</td>
									<td>
										<Label title='Zip Code' />
										<Field
											type='text'
											placeholder='Zip Code'
											name='zip'
											className={`col-1-1 primary ${errors.zip &&
												touched.zip
												? "error"
												: ""
												}`}
										/>
									</td>
								</tr>
								<tr>
									<td width='50%'>
										<Label title='State' />
										<PaginatedDropdown
                                            datakey="records"
                                            url={`${API_URL}/api/1.0.0/reference/states/US?`}
                                            mapOptions={(records) =>
                                                mapOptions(
                                                    records,
                                                    "code",
                                                    labelStateFunc
                                                )
                                            }
                                            placeHolder='Search'
                                            selectedValue={values.state}
                                            onChange={(v) => {
                                                setFieldValue("state", v);
                                                setFieldValue("city", null);
                                            
                                            }}
                                        />
									</td>
									<td>
										<Label title='City' />
										<PaginatedDropdown
                                            datakey="records"
                                            url={`${API_URL}/api/1.0.0/reference/cities/US/${values?.state?.value}?`}
                                            mapOptions={(records) =>
                                                mapOptions(
                                                    records,
                                                    "name",
                                                    labelCityFunc
                                                )
                                            }
                                            placeHolder='Search'
                                            selectedValue={values.city}
                                            onChange={(v) => {
                                                setFieldValue(
                                                        "city",
                                                        v
                                                );
                                            }}
                                        />
										
									</td>
								</tr>
								<tr>
									<td colSpan="2">
										<br />
										<hr />
										<br />
									</td>
								</tr>
								<tr>
									<td colSpan={2}>
										<h5 className="color-primary">
											Contact Details
										</h5>
									</td>
								</tr>
								<tr>
									<td colSpan="2">
										<br />
									</td>
								</tr>
								<tr>
									<td colSpan="2">
										<NestedContactForm
											onAdd={(newContact) => {
												setFieldValue('insuranceContactDetails', [
													...values.insuranceContactDetails,
													newContact,
												]);
											}}
										/>
									</td>
								</tr>

								{values.insuranceContactDetails && (
									<tr>
										<td colSpan="2" >
											<div className="contect-date">
												<table>
													<thead>
														<tr>
															<th>Contact Type</th>
															<th>Contact Information</th>
															<th style={{ textAlign: 'center' }}>Action</th>
														</tr>
													</thead>
													<tbody>
														{values.insuranceContactDetails.map((contact, index) => (
															<tr>
																<td><span className="color-primary badge">{contact.contact_type}</span></td>
																<td>
																	<span>
																	{contact.contact_type === "Phone" 
																		? (formatInputPhoneNumber(contact.contact_info.trim()) ) 
																		: (contact.contact_info)}
																	</span></td>
																<td>
																	<div className="flex center-center">
																		<span className="cursor-pointer"
																			onClick={() => {
																				const updatedContacts = values.insuranceContactDetails.filter((_, i) => i !== index);
																				setFieldValue("insuranceContactDetails", updatedContacts);
																			}}
																		>
																			<svg
																				height="15px"
																				width="15px"
																				viewBox="0 0 330 330"
																				fill="red"
																			>
																				<g id="XMLID_6_">
																					<g id="XMLID_11_">
																						<path d="M240,121.076H30V275c0,8.284,6.716,15,15,15h60h37.596c19.246,24.348,49.031,40,82.404,40c57.897,0,105-47.103,105-105 C330,172.195,290.816,128.377,240,121.076z M225,300c-41.355,0-75-33.645-75-75s33.645-75,75-75s75,33.645,75,75 S266.355,300,225,300z" />
																					</g>
																					<g id="XMLID_18_">
																						<path d="M240,90h15c8.284,0,15-6.716,15-15s-6.716-15-15-15h-30h-15V15c0-8.284-6.716-15-15-15H75c-8.284,0-15,6.716-15,15v45H45 H15C6.716,60,0,66.716,0,75s6.716,15,15,15h15H240z M90,30h90v30h-15h-60H90V30z" />
																					</g>
																					<g id="XMLID_23_">
																						<path d="M256.819,193.181c-5.857-5.858-15.355-5.858-21.213,0L225,203.787l-10.606-10.606c-5.857-5.858-15.355-5.858-21.213,0 c-5.858,5.858-5.858,15.355,0,21.213L203.787,225l-10.606,10.606c-5.858,5.858-5.858,15.355,0,21.213 c2.929,2.929,6.768,4.394,10.606,4.394c3.839,0,7.678-1.465,10.607-4.394L225,246.213l10.606,10.606 c2.929,2.929,6.768,4.394,10.607,4.394c3.839,0,7.678-1.465,10.606-4.394c5.858-5.858,5.858-15.355,0-21.213L246.213,225 l10.606-10.606C262.678,208.535,262.678,199.039,256.819,193.181z" />
																					</g>
																				</g>
																			</svg>
																		</span>
																	</div>
																</td>
															</tr>
														))}
													</tbody>
												</table>
											</div>
										</td>
									</tr>
								)}
								<tr>
									<td colSpan="2">
										<br />
										<hr />
										<br />
									</td>
								</tr>
								<tr>
									<td colSpan={2}>
										<h5 className="color-primary">
											Documents
										</h5>
									</td>
								</tr>
								<tr>
									<td colSpan="2">
										<br />
									</td>
								</tr>
								<tr>
									<td colSpan="2">
										<NestedDocumentForm
											onAdd={(newDocument) => {
												setFieldValue('insuranceDocuments', [
													...values.insuranceDocuments,
													newDocument,
												]);
											}}
										/>
									</td>
								</tr>

								{values.insuranceDocuments && (
									<tr>
										<td colSpan="2" >
											<div className="contect-date">
												<table>
													<thead>
														<tr>
															<th>Document File</th>
															<th>Document Description</th>
															<th style={{ textAlign: 'center' }}>Action</th>
														</tr>
													</thead>
													<tbody>
														{values.insuranceDocuments.map((contact, index) => (
															<tr>
																<td className="t-t-u" >{`${values?.description || ""} Document - ${index + 1}`}</td>
																<td>{contact.description}</td>
																<td>
																	<div className="flex center-center">
																		<span className="cursor-pointer"
																			onClick={() => {
																				const updatedContacts = values.insuranceDocuments.filter((_, i) => i !== index);
																				setFieldValue("insuranceDocuments", updatedContacts);
																			}}
																		>
																			<svg
																				height="15px"
																				width="15px"
																				viewBox="0 0 330 330"
																				fill="red"
																			>
																				<g id="XMLID_6_">
																					<g id="XMLID_11_">
																						<path d="M240,121.076H30V275c0,8.284,6.716,15,15,15h60h37.596c19.246,24.348,49.031,40,82.404,40c57.897,0,105-47.103,105-105 C330,172.195,290.816,128.377,240,121.076z M225,300c-41.355,0-75-33.645-75-75s33.645-75,75-75s75,33.645,75,75 S266.355,300,225,300z" />
																					</g>
																					<g id="XMLID_18_">
																						<path d="M240,90h15c8.284,0,15-6.716,15-15s-6.716-15-15-15h-30h-15V15c0-8.284-6.716-15-15-15H75c-8.284,0-15,6.716-15,15v45H45 H15C6.716,60,0,66.716,0,75s6.716,15,15,15h15H240z M90,30h90v30h-15h-60H90V30z" />
																					</g>
																					<g id="XMLID_23_">
																						<path d="M256.819,193.181c-5.857-5.858-15.355-5.858-21.213,0L225,203.787l-10.606-10.606c-5.857-5.858-15.355-5.858-21.213,0 c-5.858,5.858-5.858,15.355,0,21.213L203.787,225l-10.606,10.606c-5.858,5.858-5.858,15.355,0,21.213 c2.929,2.929,6.768,4.394,10.606,4.394c3.839,0,7.678-1.465,10.607-4.394L225,246.213l10.606,10.606 c2.929,2.929,6.768,4.394,10.607,4.394c3.839,0,7.678-1.465,10.606-4.394c5.858-5.858,5.858-15.355,0-21.213L246.213,225 l10.606-10.606C262.678,208.535,262.678,199.039,256.819,193.181z" />
																					</g>
																				</g>
																			</svg>
																		</span>
																	</div>
																</td>
															</tr>
														))}
													</tbody>
												</table>
											</div>
										</td>
									</tr>
								)}
							</tbody>
						</table>
					</div>
					<br />
					<div className='flex center-left'>
						<ButtonTextIcon
							type='button'
							className='light'
							title='Cancel'
							onClick={() => history.push("/insurance")}
							icon={
								<svg
									clipRule="evenodd"
									fillRule="evenodd"
									strokeLinejoin="round"
									strokeMiterlimit="2"
									viewBox="0 0 24 24"
									xmlns="http://www.w3.org/2000/svg"
									width='19.995'
									height='19.996'
								>
									<path
										d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 1.5c-4.69 0-8.497 3.807-8.497 8.497s3.807 8.498 8.497 8.498 8.498-3.808 8.498-8.498-3.808-8.497-8.498-8.497zm0 7.425 2.717-2.718c.146-.146.339-.219.531-.219.404 0 .75.325.75.75 0 .193-.073.384-.219.531l-2.717 2.717 2.727 2.728c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.384-.073-.53-.219l-2.729-2.728-2.728 2.728c-.146.146-.338.219-.53.219-.401 0-.751-.323-.751-.75 0-.192.073-.384.22-.531l2.728-2.728-2.722-2.722c-.146-.147-.219-.338-.219-.531 0-.425.346-.749.75-.749.192 0 .385.073.531.219z"
										fillRule="nonzero" 
									/>
								</svg>
							}
						/>
						<ButtonTextIcon
							type='button'
							disabled={loading}
							title='Create Now'
							onClick={async () => {
								const errors = await validateForm(); 
								if (Object.keys(errors).length > 0) {
								  const firstErrorMessage = Object.values(errors)[0];
								  addToastMessage({
									status: false,
									message: `${firstErrorMessage || "Something went wrong, please try again later."}`,
								  });
								}
								handleSubmit(); 
							  }}
							className='mar-l-a'
							icon={
								loading ? (
									<LoaderIcon />
								) : (
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='19.995'
										height='19.996'
										viewBox='0 0 19.995 19.996'>
										<path
											d='M12.012,2a10,10,0,1,0,10,10,10,10,0,0,0-10-10Zm0,1.5a8.5,8.5,0,1,1-8.5,8.5,8.5,8.5,0,0,1,8.5-8.5ZM13.54,8.21s1.5,1.505,3.255,3.259a.752.752,0,0,1,0,1.061c-1.753,1.754-3.254,3.258-3.254,3.258a.742.742,0,0,1-.527.217.752.752,0,0,1-.534-1.278l1.978-1.977H7.764a.75.75,0,0,1,0-1.5h6.694L12.479,9.271a.746.746,0,0,1,.006-1.054.754.754,0,0,1,.531-.222.738.738,0,0,1,.524.215Z'
											transform='translate(-2.014 -1.995)'
										/>
									</svg>
								)
							}
						/>
					</div>
					{status && (
						<>
							<br />
							<p className='color-primary f-w-700'>{status}</p>
						</>
					)}
				</Form>
			)}
		</Formik>
	);
};


const NestedDocumentForm = ({ onAdd }) => {
  const fileUploadRef = useRef();
  const validationSchema = Yup.object().shape({
    file: Yup.string().required("File is required"),
    description: Yup.string().required("Description is required"),
  });
  const handleReset = () => {
    if (fileUploadRef.current) {
      fileUploadRef.current.resetFile();
    }
  };
  return (
    <Formik
      initialValues={{
        file: "",
        description: "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        onAdd(values);
        resetForm();
        handleReset();
      }}
    >
      {({ errors, touched, setFieldValue, values }) => (
        <Form>
          <table>
            <tbody>
              <tr>
                <td colSpan="2">
                  <div className="flex center-left" style={{ gap: "8px" }}>
                    <div
                      className="opl-tooltip"
                      data-tooltip="Please only select PDF files for upload."
                    >
                      <Label title="Upload File" required={true} />
                    </div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      style={{
                        height: "12px",
                        width: "12px",
                        marginBottom: "5px",
                      }}
                    >
                      <path
                        d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.351 6.493c-.08-.801.55-1.493 1.351-1.493s1.431.692 1.351 1.493l-.801 8.01c-.029.282-.266.497-.55.497s-.521-.215-.55-.498l-.801-8.009zm1.351 12.757c-.69 0-1.25-.56-1.25-1.25s.56-1.25 1.25-1.25 1.25.56 1.25 1.25-.56 1.25-1.25 1.25z"
                        fill="#5dc6b3"
                      />
                    </svg>
                  </div>
                  <DragFileUpload
                    ref={fileUploadRef}
                    fileType={"insurance"}
                    error={errors.file && touched.file}
                    afterUpload={(url) => {
                      setFieldValue("file", url);
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td width="50%">
                  <Label title="Description" required={true} />
                  <Field
                    type="text"
                    placeholder="Enter Description"
                    name="description"
                    className={`col-1-1 primary ${
                      errors.description && touched.description ? "error" : ""
                    }`}
                  />
                </td>
                <td>
                  <div className="flex center-right">
                    <div>
                      <Label title="&nbsp;" />
                      <ButtonLightTextIcon
                        title="Add Document"
                        type="submit"
                        icon={
                          <svg
                            fill="#000000"
                            version="1.1"
                            width="800px"
                            height="800px"
                            viewBox="0 0 400 400"
                            xmlSpace="preserve"
                          >
                            <g>
                              <g>
                                <path d="M199.995,0C89.716,0,0,89.72,0,200c0,110.279,89.716,200,199.995,200C310.277,400,400,310.279,400,200 C400,89.72,310.277,0,199.995,0z M199.995,373.77C104.182,373.77,26.23,295.816,26.23,200c0-95.817,77.951-173.77,173.765-173.77 c95.817,0,173.772,77.953,173.772,173.77C373.769,295.816,295.812,373.77,199.995,373.77z" />
                                <path d="M279.478,186.884h-66.363V120.52c0-7.243-5.872-13.115-13.115-13.115s-13.115,5.873-13.115,13.115v66.368h-66.361 c-7.242,0-13.115,5.873-13.115,13.115c0,7.243,5.873,13.115,13.115,13.115h66.358v66.362c0,7.242,5.872,13.114,13.115,13.114 c7.242,0,13.115-5.872,13.115-13.114v-66.365h66.367c7.241,0,13.114-5.873,13.114-13.115 C292.593,192.757,286.72,186.884,279.478,186.884z" />
                              </g>
                            </g>
                          </svg>
                        }
                      />
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </Form>
      )}
    </Formik>
  );
};

const NestedContactForm = ({ onAdd }) => {
	const validationSchema = Yup.object().shape({
		contact_type: Yup.string().required("Contact Type is required"),
		contact_info: Yup.string()
			.when("contact_type", {
				is: "Fax",
				then: (schema) =>
					schema
						.max(15, "Fax must be at most 15 characters")
						.required("Fax is required"),
				otherwise: (schema) =>
					schema.when("contact_type", {
						is: "Phone",
						then: (innerSchema) =>
							innerSchema
								.matches(/^\d+$/, "Phone number must contain only digits")
								.max(15, "Phone number must be at most 15 digits")
								.required("Phone number is required"),
						otherwise: (innerSchema) =>
							innerSchema.when("contact_type", {
								is: "Email",
								then: (emailSchema) =>
									emailSchema
										.email("Invalid email format")
										.required("Email is required"),
								otherwise: (defaultSchema) =>
									defaultSchema.required("Contact information is required"),
							}),
					}),
			}),
		});

	return (
    <Formik
      initialValues={{
        contact_type: "",
        contact_info: "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        onAdd(values);
        resetForm();
      }}
    >
      {({ errors, touched,values,setFieldTouched,setFieldValue}) => (
        <Form>
          <table>
            <tbody>
              <tr>
                <td width="50%">
                  <Label title="Contact Type" required={true} />
                  <Field
                    as="select"
                    name="contact_type"
                    className={`col-1-1 primary ${
                      errors.contact_type && touched.contact_type ? "error" : ""
                    }`}
                  >
                    <option value="">Select Contact Type</option>
                    <option value="Phone">Phone</option>
                    <option value="Fax">Fax</option>
                    <option value="Email">Email</option>
                  </Field>
                </td>
                <td>
                  <Label title="Contact Information" required={true} />
                  {values.contact_type === "Phone" ? (
					<>
						<PhoneNumberInput
						name="contact_info"
						placeholder="Eg: 888-888-8888"
						error={errors.contact_info && touched.contact_info}
						value={values.contact_info}
						setTouched={() => setFieldTouched("contact_info", true)}
						callback={(v) => setFieldValue("contact_info", v)}
						/>
						<ErrorMessage
						name="contact_info"
						component="p"
						className="error-messages"
						/>
					</>
					) : (
					<>
						<Field
						type="text"
						placeholder="Contact Information"
						name="contact_info"
						className={`col-1-1 primary ${
						errors.contact_info && touched.contact_info ? "error" : ""
						}`}
					/>
					{errors.contact_info && touched.contact_info && (
						<div
						style={{
							color: "red",
							fontSize: "12px",
							marginTop: "5px",
						}}
						>
						{errors.contact_info}
						</div>
						)}
					</>
				 	 )}
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <div className="flex center-right">
                    <div>
                      <Label title="&nbsp;" />
                      <ButtonLightTextIcon
                        title="Add Contact Detail"
                        type="submit"
                        icon={
                          <svg
                            fill="#000000"
                            version="1.1"
                            width="800px"
                            height="800px"
                            viewBox="0 0 400 400"
                            xmlSpace="preserve"
                          >
                            <g>
                              <g>
                                <path d="M199.995,0C89.716,0,0,89.72,0,200c0,110.279,89.716,200,199.995,200C310.277,400,400,310.279,400,200 C400,89.72,310.277,0,199.995,0z M199.995,373.77C104.182,373.77,26.23,295.816,26.23,200c0-95.817,77.951-173.77,173.765-173.77 c95.817,0,173.772,77.953,173.772,173.77C373.769,295.816,295.812,373.77,199.995,373.77z" />
                                <path d="M279.478,186.884h-66.363V120.52c0-7.243-5.872-13.115-13.115-13.115s-13.115,5.873-13.115,13.115v66.368h-66.361 c-7.242,0-13.115,5.873-13.115,13.115c0,7.243,5.873,13.115,13.115,13.115h66.358v66.362c0,7.242,5.872,13.114,13.115,13.114 c7.242,0,13.115-5.872,13.115-13.114v-66.365h66.367c7.241,0,13.114-5.873,13.114-13.115 C292.593,192.757,286.72,186.884,279.478,186.884z" />
                              </g>
                            </g>
                          </svg>
                        }
                      />
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </Form>
      )}
    </Formik>
  );
};


export default AddForm;