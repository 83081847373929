import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import ButtonTextIcon from "../../components/common/ui/ButtonTextIcon";
import LoaderIcon from "../../components/common/ui/LoaderIcon";
import { useEffect, useState } from "react";
import {
    ALLOWED_FILE_TYPES,
    API_URL,
    PLACE_OF_SERVICE,
    SYSTEM_TOKEN,
} from "../../opl-utilities/constants";
import PaginatedDropdown from "../../components/common/ui/PaginatedDropdown";
import DragFileUpload from "../../components/common/ui/DragFileUpload";
import AddDivision from "./AddDivision";

const Selector = ({
    inputKey,
    url,
    error,
    touched,
    optionValue,
    optionData,
    ...rest
}) => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);

    const getData = async (slug) => {
        try {
            const resp = await fetch(API_URL + slug, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "X-JWT-Assertion": localStorage.getItem("token"),
                },
            });
            if (resp.status === 401 || resp.status === 403) {
                if (resp.status === 401) {
                  localStorage.clear();
                  window.location.href = "/login";
                } else if (resp.status === 403) {
                    setLoading(false);
                    
                }
                return false;
              }
            if (resp.ok) {
                const response = await resp.json();
                if (response) {
                    setData(response.data.records);
                }
            }
        } catch (e) {
            console.log("Error", e);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getData(url);
    }, []);

    if (loading) {
        return (
            <select className='primary col-1-1' disabled={true}>
                <option>Loading..</option>
            </select>
        );
    }

    return (
        <>
            {data && (
                <Field
                    as='select'
                    name={inputKey}
                    className={`col-1-1 primary ${error && touched ? "error" : ""
                        }`}
                    {...rest}>
                    <option value=''>Select</option>
                    {data.map((d, i) => (
                        <option value={d[optionValue]} key={i}>
                            {d[optionData]}
                        </option>
                    ))}
                </Field>
            )}
        </>
    );
};

const AddDivisionForm = ({ history }) => {
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState("");

    const initialValues = {
        first_name: "",
        last_name: "",
        email: "",
        patient_name: "",
        type: "",
        procedure_id: [],

    };

    const validationSchema = Yup.object({
        first_name: Yup.string().required("Required"),
        last_name: Yup.string().required("Required"),
        type: Yup.string().required("Required"),
        email: Yup.string().email().required("Required"),
        patient_name: Yup.string().required("Required"),
        event_type: Yup.string().required("Required"),
        procedure_id: Yup.array()
            .of(
                Yup.object().shape({
                    val: Yup.string().required("Required"),
                    name: Yup.string().required("Required"),
                })
            )
            .required("Required")
            .min(1, "At least one item is required"),


    });


    const Label = ({ title, required }) => {
        return (
            <p
                className='color-primary f-w-600'
                style={{ paddingBottom: "5px" }}>
                {title}
                {required && (
                    <span style={{ color: "red", marginLeft: "4px" }}>*</span>
                )}
            </p>
        );
    };

    const mapOptions = (records, valueKey, labelFormatter) =>
        records.map((record) => ({
            value: record[valueKey],
            label: labelFormatter(record),
            obj: record,
        }));

    const labelPatientsFunc = (o) => `${o.firstName} ${o.middleName} ${o.lastName}`;
    const labelProcedureFunc = (o) => `${o.procedureName}${o?.cptCode?(", "+o.cptCode):""}`;


    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                // Your submit logic here, for example:
        
                // Optionally, reset the form after submission
                resetForm();
        
                // Set submitting to false when done to re-enable the form
                setSubmitting(false);
            }}
        >
            {({
                values,
                setFieldValue,
                errors,
                touched,
                handleSubmit,
                validateForm,
            }) => (
                <Form>
                    <div className='col-1-1'>
                        <table>
                            <tbody>
                                <tr>
                                    <td width='50%'>
                                        <Label title='Division Name' />
                                        <Field
                                            type='text'
                                            placeholder='Enter First Name'
                                            name='first_name'
                                            className={`col-1-1 primary ${errors.account_number &&
                                                touched.account_number
                                                ? "error"
                                                : ""
                                                }`}
                                        />
                                    </td>
                                    <td>
                                        <Label
                                            title='Surgical Assistant'
                                        />
                                        <Selector
                                            inputKey={"event_type"}
                                            url='/api/1.0.0/event-type?pageNo=0&pageSize=100'
                                            optionValue='id'
                                            optionData='type'
                                            error={errors.event_type}
                                            touched={touched.event_type}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <br />
                    <div className='flex center-left'>
                        <ButtonTextIcon
                            type='button'
                            className='light'
                            title='Cancel'
                            onClick={() => history.push("/division")}
                            icon={
                                <svg
									clipRule="evenodd"
									fillRule="evenodd"
									strokeLinejoin="round"
									strokeMiterlimit="2"
									viewBox="0 0 24 24"
									xmlns="http://www.w3.org/2000/svg"
									width='19.995'
									height='19.996'
								>
									<path
										d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 1.5c-4.69 0-8.497 3.807-8.497 8.497s3.807 8.498 8.497 8.498 8.498-3.808 8.498-8.498-3.808-8.497-8.498-8.497zm0 7.425 2.717-2.718c.146-.146.339-.219.531-.219.404 0 .75.325.75.75 0 .193-.073.384-.219.531l-2.717 2.717 2.727 2.728c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.384-.073-.53-.219l-2.729-2.728-2.728 2.728c-.146.146-.338.219-.53.219-.401 0-.751-.323-.751-.75 0-.192.073-.384.22-.531l2.728-2.728-2.722-2.722c-.146-.147-.219-.338-.219-.531 0-.425.346-.749.75-.749.192 0 .385.073.531.219z"
										fillRule="nonzero" 
									/>
								</svg>
                            }
                        />
                        <ButtonTextIcon
                            type='submit'
                            disabled={loading}
                            title='Submit'
                            className='mar-l-a'
                            icon={
                                loading ? (
                                    <LoaderIcon />
                                ) : (
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='19.995'
                                        height='19.996'
                                        viewBox='0 0 19.995 19.996'>
                                        <path
                                            d='M12.012,2a10,10,0,1,0,10,10,10,10,0,0,0-10-10Zm0,1.5a8.5,8.5,0,1,1-8.5,8.5,8.5,8.5,0,0,1,8.5-8.5ZM13.54,8.21s1.5,1.505,3.255,3.259a.752.752,0,0,1,0,1.061c-1.753,1.754-3.254,3.258-3.254,3.258a.742.742,0,0,1-.527.217.752.752,0,0,1-.534-1.278l1.978-1.977H7.764a.75.75,0,0,1,0-1.5h6.694L12.479,9.271a.746.746,0,0,1,.006-1.054.754.754,0,0,1,.531-.222.738.738,0,0,1,.524.215Z'
                                            transform='translate(-2.014 -1.995)'
                                        />
                                    </svg>
                                )
                            }
                        />
                    </div>
                    {status && (
                        <>
                            <br />
                            <p className='color-primary f-w-700'>{status}</p>
                        </>
                    )}
                </Form>
            )}
        </Formik>
    );
};

export default AddDivisionForm;
