import ButtonTertiary from "./ButtonTertiary";
import FileViewer from "./FileViewer";

 
const FullScreenFileViewerModal = ({ title = "", fileUrl, setShow }) => {
  return (
    <section id="opl_coremax">
      <section
        style={{
          position: "fixed",
          inset: 0,
          zIndex: 9999,
          backgroundColor: "rgba(255, 255, 255, 0.86)",
        }}
      >
        <section
          className="col-1-1 flex center-center"
          style={{
            height: "100vh",
            width: "100vw",
            overflowY: "auto",
          }}
        >
          <div
            style={{
              height: "100vh",
              width: "100vw",
              margin: "10px",
              padding: "15px",
              border: "solid 1px #f0f0f0",
              background: "white",
              borderRadius: "8px",
              margin: "0 auto",
              boxShadow: "0px 0px 20px rgba(0,0,0,0.08)",
            }}
          >
            <div
              className="col-1-1 flex center-left"
              style={{
                borderBottom: "solid 1px #f0f0f0",
                paddingBottom: "12px",
                marginBottom: "16px",
                gap: "10px",
              }}
            >
              <h6 className="color-primary f-w-700 t-t-u l-s-n">{title || "Document"}</h6>
              <div className="flex center-right mar-l-a" style={{
                gap:"10px"
              }} >
              {fileUrl && (
                <div>
                    <a
                      href={fileUrl}
                      download 
                      rel="noreferrer" 
                    >
                  <ButtonTertiary title="Click to Download" actionType="download"  />
                  </a>
                </div>
              )}
              <div
                className=" cursor-pointer "
                onClick={() => setShow(false)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z"
                    fill="#283f54"
                  />
                </svg>
              </div>
              </div>
            </div>
            <section style={{
              width:"100%",
              padding:"0 80px"
            }}>
              <FileViewer fileUrl={fileUrl} />
            </section>
          </div>
        </section>
      </section>
    </section>
  );
};

export default FullScreenFileViewerModal;
