import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import ButtonTextIcon from "../../components/common/ui/ButtonTextIcon";
import LoaderIcon from "../../components/common/ui/LoaderIcon";
import { useEffect, useState } from "react";
import {
  ALLOWED_FILE_TYPES,
  API_URL,
  PLACE_OF_SERVICE,
  STATUS_USER_OPTIONS,
  SYSTEM_TOKEN,
  USER_TYPE_OPTIONS,
} from "../../opl-utilities/constants";
import PaginatedDropdown from "../../components/common/ui/PaginatedDropdown";
import DragFileUpload from "../../components/common/ui/DragFileUpload";
import { useUI } from "../../context/ui";
import { handleDatePickerChange, sleep } from "../../opl-utilities/helpers";
import DatePicker from "react-datepicker";
import { formatISO, parseISO } from "date-fns";
import { set } from "lodash";
const Selector = ({
  inputKey,
  url,
  error,
  touched,
  optionValue,
  optionData,
  ...rest
}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

    const getData = async (slug) => {
        try {
            const resp = await fetch(API_URL + slug, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "X-JWT-Assertion": localStorage.getItem("token")
                },
            });
            if (resp.status === 401 || resp.status === 403) {
              if (resp.status === 401) {
                localStorage.clear();
                window.location.href = "/login";
              } else if (resp.status === 403) {
                setLoading(false);
              }
              return false;
            }
            if (resp.ok) {
                const response = await resp.json();
                if (response) {
                    setData(response.data.records);
                }
            }
        } catch (e) {
            console.log("Error", e);
        } finally {
            setLoading(false);
        }
    };

  useEffect(() => {
    getData(url);
  }, []);

  if (loading) {
    return (
      <select className="primary col-1-1" disabled={true}>
        <option>Loading..</option>
      </select>
    );
  }

  return (
    <>
      {data && (
        <Field
          as="select"
          name={inputKey}
          className={`col-1-1 primary ${error && touched ? "error" : ""}`}
          {...rest}
        >
          <option value="">Select</option>
          {data.map((d, i) => (
            <option value={d[optionValue]} key={i}>
              {d[optionData]}
            </option>
          ))}
        </Field>
      )}
    </>
  );
};

const UpdateUserForm = ({ userData, history }) => {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");
  const { addToastMessage } = useUI();
  const [searchGroup, setSearchGroup] = useState(null);
  const [searchPractice, setSearchPractice] = useState(null);
  const url =  window.location.href;
  const nextUrl = url.split('next=')[1];   

  const [initialValues, setInitialValues] = useState({
    name: "",
    lastName: "",
    email: "",
    type: "",
    groupIds: [],
    practice: [],
    notes: "",
    isActive: "",
    activeEnd: "",
    activeStart: "",
    region: "",
  });

  useEffect(() => {
    setInitialValues({
      name: userData?.userDAO?.name || null,
      lastName: userData?.userDAO?.lastName || null,
      email: userData?.userDAO?.email || null,
      type: userData?.userDAO?.type || null,
      activeEnd: userData?.userDAO?.activeEnd || "",
      activeStart: userData?.userDAO?.activeStart || null,
      notes: userData?.userDAO?.notes || null,
      practice: userData?.practice
        ? userData?.practice.map((item) => ({
            val: item.id,
            name: item.payToName,
          }))
        : [],
      groupIds: userData?.groups
        ? userData?.groups.map((group) => ({
            val: group.id,
            name: group.groupName,
          }))
        : [],
      isActive: userData?.userDAO?.isActive || null,
      id: userData?.userDAO?.id || null,
      region: userData?.region?.id
        ? {
            value: userData?.region?.id,
            label: userData?.region?.name,
          }
        : null,
    });
  }, [userData]);

  const validationSchema = Yup.object({
    name: Yup.string()
      .required("First Name is required")
      .min(2, "At least two characters is required in firstname"),
    lastName: Yup.string()
      .required("Last Name is required")
      .min(2, "At least two characters is required in lastname"),
    activeStart: Yup.string().required("Active Date is required"),
    activeEnd: Yup.date()
    .test("is-after-activeStart", "Active end must be greater than Active start", function (value) {
      const { activeStart } = this.parent;
      return !activeStart || !value || new Date(value) > new Date(activeStart);
    }),
    type: Yup.string().required("User Type is required"),
    email: Yup.string().email().required("Email is required"),
    groupIds: Yup.array()
      .of(
        Yup.object().shape({
          val: Yup.string().required("Required"),
          name: Yup.string().required("Group is required"),
        })
      )
      .required("Group is Required")
      .min(1, "At least one group is required"),
    practice: Yup.array()
      .of(
        Yup.object().shape({
          val: Yup.string().required("Required"),
          name: Yup.string().required("Practice is required"),
        })
      )
      .min(1, "At least one practice is required"),
    isActive: Yup.string().required("Status is required"),
  });
  const updateUser = async (values) => {
    setLoading(true);
    setStatus("");
    try {
      const payload = JSON.stringify({
        name: values.name,
        lastName: values.lastName,
        email: values.email,
        groupIds: values.groupIds.map((group) => group.val),
        practice: values.practice.map((item) => item.val).join(","),
        isActive: values.isActive,
        notes: values.notes,
        type: values.type,
        activeStart: values.activeStart,
        activeEnd: values.activeEnd,
        region: values.region ? values.region.value : "",
        isAddAccess: true, // need to be discussed
        isDeleteAccess: false,
        isUpdateAccess: false,
      });

      const resp = await fetch(API_URL + `/api/1.0.0/users/` + values.id, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "X-JWT-Assertion": localStorage.getItem("token")
        },
        body: payload,
      });
      if (resp.status === 401 || resp.status === 403) {
        if (resp.status === 401) {
          localStorage.clear();
          window.location.href = "/login";
        } else if (resp.status === 403) {
          addToastMessage({
            status: false,
            message:
              "Access denied. You do not have permission to perform this action.",
          });
          setLoading(false);
        }
        return false;
      }
      if (resp.ok) {
        const response = await resp.json();
        if (response) {
          // return response.result.responseCode === 200 ? true : false;
          if (response.result.responseCode === 200) {
            return true;
          } else {
            addToastMessage({
              status: false,
              message: `Something went wrong, please try again later.`,
            });
            return false;
          }
        }
      } else {
        const response = await resp.json();
        if (response) {
          addToastMessage({
            status: false,
            message: `${
              response.responseDescription ||
              "Something went wrong, please try again later."
            }`,
          });
          return false;
        }
      }
    } catch (e) {
      console.log("Error:", e);
      addToastMessage({
        status: false,
        message: "Something went wrong, please try again later.",
      });
      return false;
    } finally {
      setLoading(false);
    }
  };

  const Label = ({ title, required }) => {
    return (
      <p className="color-primary f-w-600" style={{ paddingBottom: "5px" }}>
        {title}
        {required && <span style={{ color: "red", marginLeft: "4px" }}>*</span>}
      </p>
    );
  };

  const mapOptions = (records, valueKey, labelFormatter) =>
    records.map((record) => ({
      value: record[valueKey],
      label: labelFormatter(record),
      obj: record,
    }));

  const labelPracticeFunc = (o) => `${o.payToName}`;
  const labelGroupFunc = (o) => `${o.groupName}`;
  const labelRegionFunc = (o) => `${o.name}`;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={async (values, { resetForm }) => {
        setLoading(true);
        const api = await updateUser(values);
        if (api) {
          resetForm();
          setStatus(
              "You have successfully updated a user, thank you."
          );
          addToastMessage({
              status: true,
              message: `You have successfully updated a user, thank you.`,
          }) 
          if (nextUrl) {   
            history.push(nextUrl); 
          } else { 
            history.push("/users");
          } 
          // setStatus("");
        } else {
          setStatus("Something went wrong, please try again later.");
        }
        setLoading(false);
      }}
    >
      {({
        values,
        setFieldValue,
        errors,
        touched,
        handleSubmit,
        validateForm,
      }) => (
        <Form>
          <div className="col-1-1">
            <table>
              <tbody>
                <tr>
                  <td width="50%">
                    <Label title="First Name" />
                    <Field
                      type="text"
                      placeholder="Enter First Name"
                      name="name"
                      value={values.name}
                      className={`col-1-1 primary ${
                        errors.name && touched.name ? "error" : ""
                      }`}
                    />
                     <ErrorMessage
                      name="name"
                      component="p"
                      className="error-messages"
                    />
                  </td>
                  <td width="50%">
                    <Label title="Last Name" required={true} />
                    <Field
                      type="text"
                      placeholder="Enter Last Name"
                      name="lastName"
                      value={values.lastName}
                      className={`col-1-1 primary ${
                        errors.lastName && touched.lastName ? "error" : ""
                      }`}
                    />
                     <ErrorMessage
                      name="lastName"
                      component="p"
                      className="error-messages"
                    />
                  </td>
                </tr>
                <tr>
                  <td width="50%">
                    <Label title="Email" required={true} />
                    <Field
                      type="email"
                      placeholder="Enter Email"
                      name="email"
                      className={`col-1-1 primary ${
                        errors.email && touched.email ? "error" : ""
                      }`}
                    />
                  </td>
                  <td>
                    <Label title="User Type" required={true} />
                    <Field
                      as="select"
                      name="type"
                      className={`col-1-1 primary ${
                        errors.type && touched.type ? "error" : ""
                      }`}
                      onChange={(e) => {
                        const selectedValue = e.target.value;
                        setFieldValue("type", selectedValue);

                        // Reset region field if user type changes
                        if (selectedValue !== "assistant") {
                          setFieldValue("region", ""); // Clear region selection
                        }
                      }}
                    >
                      <option value="">Select</option>
                      {USER_TYPE_OPTIONS.filter(
                        (d) =>
                          d.value != "assistant" &&
                          d.value != "Physician Assistant"
                      ).map((d, i) => (
                        <option value={d.value} key={i}>
                          {d.label}
                        </option>
                      ))}
                    </Field>

                    {/* Conditionally render the region dropdown if "Surgical Assistant" is selected */}
                    {values.type === "assistant" && (
                      <div style={{ marginTop: "10px" }}>
                        <Label title="Region" />
                        <PaginatedDropdown
                          url={`${API_URL}/api/1.0.0/regions?`}
                          mapOptions={(records) =>
                            mapOptions(records, "id", labelRegionFunc)
                          }
                          error={errors.practice && touched.practice}
                          selectedValue={values.region}
                          placeHolder="Search"
                          onChange={(v) => {
                              setFieldValue("region", v);
                            }
                          }
                        />
                      </div>
                    )}
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <Label title="Practice" required={true} />
                    <PaginatedDropdown
                      url={`${API_URL}/api/1.0.0/reference/practice?`}
                      mapOptions={(records) =>
                        mapOptions(records, "id", labelPracticeFunc)
                      }
                      error={errors.practice && touched.practice}
                      placeHolder="Search"
                      selectedValue={searchPractice}
                      onChange={async (v) => {
                        if (v) {
                          setSearchPractice(v);

                          const p = values.practice;
                          const n = {
                            val: v.value,
                            name: v.label,
                          };

                          setFieldValue("practice", [...values.practice, n]);
                          await sleep();
                          setSearchPractice(null);
                        }
                      }}
                    />
                    {/* Display selected practice */}
                    {values?.practice?.length > 0 && (
                      <ul
                        className="col-1-1 t-a-l"
                        style={{ padding: "10px 0" }}
                      >
                        {values?.practice?.map((p, j) => (
                          <li
                            key={j}
                            data-tooltip="Click to delete the practice."
                            onClick={() => {
                              const updatedPractice = values.practice.filter(
                                (obj) => obj.val !== p.val
                              );
                              setFieldValue("practice", updatedPractice);
                            }}
                            style={{
                              listStyle: "none",
                              marginRight: "4px",
                              marginBottom: "4px",
                              borderRadius: "2px",
                              lineHeight: "32px",
                              padding: "10px 12px",
                              verticalAlign: "middle",
                            }}
                            className="opl-tooltip bg-color-faded dis-i-b cursor-pointer color-primary hover-background-secondary transition"
                          >
                            <div className="flex center-left">
                              <p
                                className="of-hid"
                                style={{
                                  whiteSpace: "nowrap",
                                  width: "108px",
                                  textOverflow: "ellipsis",
                                  fontSize: "12px",
                                }}
                              >
                                {p.name}
                              </p>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                style={{ height: "16px", width: "16px" }}
                              >
                                <path
                                  fill="#283f54"
                                  d="M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Zm4.151,17.943-4.143-4.1L7.891,18,6.058,16.167l4.1-4.157L6,7.891,7.833,6.058l4.155,4.1L16.094,6l1.849,1.849-4.1,4.141L18,16.094Z"
                                />
                              </svg>
                            </div>
                          </li>
                        ))}
                      </ul>
                    )}
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <Label title="Groups" required={true} />
                    <PaginatedDropdown
                      datakey="list"
                      url={`${API_URL}/api/1.0.0/groups?`}
                      mapOptions={(records) =>
                        mapOptions(records, "id", labelGroupFunc)
                      }
                      error={errors.groupIds && touched.groupIds}
                      placeHolder="Search"
                      selectedValue={searchGroup}
                      onChange={async (v) => {
                        if (v) {
                          setSearchGroup(v);

                          const currentGroupIds = values.groupIds;
                          const newGroup = { val: v.value, name: v.label };

                          if (
                            !currentGroupIds.some(
                              (group) => group.val === v.value
                            )
                          ) {
                            setFieldValue("groupIds", [
                              ...currentGroupIds,
                              newGroup,
                            ]);
                          }
                          await sleep();
                          setSearchGroup(null);
                        }
                      }}
                    />

                    {/* Display selected groups */}
                    {values.groupIds.length > 0 && (
                      <ul
                        className="col-1-1 t-a-l"
                        style={{ padding: "10px 0" }}
                      >
                        {values.groupIds.map((p, j) => (
                          <li
                            key={j}
                            data-tooltip="Click to delete the group."
                            onClick={() => {
                              const updatedGroupsIds = values.groupIds.filter(
                                (obj) => obj.val !== p.val
                              );
                              setFieldValue("groupIds", updatedGroupsIds);
                            }}
                            style={{
                              listStyle: "none",
                              marginRight: "4px",
                              marginBottom: "4px",
                              borderRadius: "2px",
                              lineHeight: "32px",
                              padding: "10px 12px",
                              verticalAlign: "middle",
                            }}
                            className="opl-tooltip bg-color-faded dis-i-b cursor-pointer color-primary hover-background-secondary transition"
                          >
                            <div className="flex center-left">
                              <p
                                className="of-hid"
                                style={{
                                  whiteSpace: "nowrap",
                                  width: "108px",
                                  textOverflow: "ellipsis",
                                  fontSize: "12px",
                                }}
                              >
                                {p.name}
                              </p>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                style={{ height: "16px", width: "16px" }}
                              >
                                <path
                                  fill="#283f54"
                                  d="M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Zm4.151,17.943-4.143-4.1L7.891,18,6.058,16.167l4.1-4.157L6,7.891,7.833,6.058l4.155,4.1L16.094,6l1.849,1.849-4.1,4.141L18,16.094Z"
                                />
                              </svg>
                            </div>
                          </li>
                        ))}
                      </ul>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    <Label title="Active Date" required={true} />
                    <DatePicker
                      className={`primary ${
                        errors.activeStart && touched.activeStart ? "error" : ""
                      }`}
                      isClearable
                      portalId="root-portal" 
                      selected={values.activeStart ? parseISO(values.activeStart) : ""}
                      onChange={(date, e) => handleDatePickerChange(setFieldValue, "activeStart", date, e)}
                      showYearDropdown
                      placeholderText="MM/DD/YYYY"
                      dateFormat="MM/dd/yyyy"
                    />
                  </td>
                  <td>
                    <Label title="Active End" />
                    <DatePicker
                      className={`primary ${
                        errors.activeEnd && touched.activeEnd ? "error" : ""
                      }`}
                      isClearable
                      portalId="root-portal" 
                      selected={values.activeEnd ? parseISO(values.activeEnd) : ""}
                      onChange={(date, e) => handleDatePickerChange(setFieldValue, "activeEnd", date, e)}
                      showYearDropdown
                      placeholderText="MM/DD/YYYY"
                      dateFormat="MM/dd/yyyy"
                    />
                    {errors.activeEnd && touched.activeEnd && (
                      <div style={
                        {
                          "color": "red",
                          "font-size": "0.875rem",
                        }
                      }>{errors.activeEnd}</div>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    <Label title="Status" required={true} />
                    <Field
                      as="select"
                      name="isActive"
                      // value={values.isActive}
                      className={`col-1-1 primary cursor-not-allowed ${
                        errors.isActive && touched.isActive ? "error" : ""
                      }`}
                      disabled={true}
                    >
                      <option value="">Select</option>
                      {STATUS_USER_OPTIONS.map((d, i) => (
                        <option value={d.value} key={i}>
                          {d.label}
                        </option>
                      ))}
                    </Field>
                  </td>
                  <td width="50%">
                    <Label title="Notes" />
                    <Field
                      type="text"
                      placeholder="Enter Notes"
                      name="notes"
                      className={`col-1-1 primary ${
                        errors.notes && touched.notes ? "error" : ""
                      }`}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <br />
          <div className="flex center-left">
            <ButtonTextIcon
              type="button"
              className="light"
              title="Cancel"
              onClick={() =>{
                if (nextUrl) {   
                  history.push(nextUrl); 
                } else { 
                  history.push("/users");
                } 
              }}
              icon={
                <svg
									clipRule="evenodd"
									fillRule="evenodd"
									strokeLinejoin="round"
									strokeMiterlimit="2"
									viewBox="0 0 24 24"
									xmlns="http://www.w3.org/2000/svg"
									width='19.995'
									height='19.996'
								>
									<path
										d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 1.5c-4.69 0-8.497 3.807-8.497 8.497s3.807 8.498 8.497 8.498 8.498-3.808 8.498-8.498-3.808-8.497-8.498-8.497zm0 7.425 2.717-2.718c.146-.146.339-.219.531-.219.404 0 .75.325.75.75 0 .193-.073.384-.219.531l-2.717 2.717 2.727 2.728c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.384-.073-.53-.219l-2.729-2.728-2.728 2.728c-.146.146-.338.219-.53.219-.401 0-.751-.323-.751-.75 0-.192.073-.384.22-.531l2.728-2.728-2.722-2.722c-.146-.147-.219-.338-.219-.531 0-.425.346-.749.75-.749.192 0 .385.073.531.219z"
										fillRule="nonzero" 
									/>
								</svg>
              }
            />
            <ButtonTextIcon
              type="submit"
              disabled={loading}
              title="Update Now"
              onClick={async () => {
                const errors = await validateForm(); 
                if (Object.keys(errors).length > 0) {
                  const firstErrorMessage = Object.values(errors)[0];
                  addToastMessage({
                    status: false,
                    message: `${firstErrorMessage || "Something went wrong, please try again later."}`,
                  });
                }
                handleSubmit(); 
              }}
              className="mar-l-a"
              icon={
                loading ? (
                  <LoaderIcon />
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19.995"
                    height="19.996"
                    viewBox="0 0 19.995 19.996"
                  >
                    <path
                      d="M12.012,2a10,10,0,1,0,10,10,10,10,0,0,0-10-10Zm0,1.5a8.5,8.5,0,1,1-8.5,8.5,8.5,8.5,0,0,1,8.5-8.5ZM13.54,8.21s1.5,1.505,3.255,3.259a.752.752,0,0,1,0,1.061c-1.753,1.754-3.254,3.258-3.254,3.258a.742.742,0,0,1-.527.217.752.752,0,0,1-.534-1.278l1.978-1.977H7.764a.75.75,0,0,1,0-1.5h6.694L12.479,9.271a.746.746,0,0,1,.006-1.054.754.754,0,0,1,.531-.222.738.738,0,0,1,.524.215Z"
                      transform="translate(-2.014 -1.995)"
                    />
                  </svg>
                )
              }
            />
          </div>
          {status && (
            <>
              <br />
              <p className="color-primary f-w-700">{status}</p>
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};
export default UpdateUserForm;
