import React, { useState,useRef } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import PaginatedDropdown from '../../components/common/ui/PaginatedDropdown';
import ButtonTextIcon from '../../components/common/ui/ButtonTextIcon';
import LoaderIcon from '../../components/common/ui/LoaderIcon';
import DragFileUpload from '../../components/common/ui/DragFileUpload';
import { API_URL, } from '../../opl-utilities/constants';
import { useUI } from '../../context/ui';

const validationSchema = Yup.object({
    name: Yup.string().required('Title is required'),
    description: Yup.string().required('Description is required'), 
    articlecategory: Yup.mixed()
			.nullable()
			.test("articlecategory-required", "Article Category is Required",
				function (value) { return value !== null && value.value; }), 
    articlesubcategory: Yup.mixed()
    .nullable()
    .test("articlesubcategory-required", "Article Sub Category is required",
        function (value) { return value !== null && value.value; }),
});


function AddForm({ history,location }) {
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState("");
    const {addToastMessage}=useUI();
    const fileUploadRef = useRef();
    const initialValues = {
        name: '',
        articlecategory: null,
        articlesubcategory: null,
        description: '',
        file: null,
    };
    const handleReset = () => {
		if (fileUploadRef.current) {
			fileUploadRef.current.resetFile();
		}
	};
    const Label = ({ title, required }) => (
        <p className='color-primary f-w-600' style={{ paddingBottom: '5px' }}>
            {title}
            {required && <span style={{ color: 'red', marginLeft: '4px' }}>*</span>}
        </p>
    );

    const addKnowledgeBase = async (values) => {
        setLoading(true);
        setStatus("");

        try { 
            // setLoading(false); 
            const payload = { 
                name: values.name,
                description: values.description,
                knowledgeBaseSubCategoryId: Number(values?.articlesubcategory?.obj?.id), 
            }; 
            if(values.file){
                payload["fileLink"] = values.file;
            } 
            const resp = await fetch(`${API_URL}/api/1.0.0/knowledge-base`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-JWT-Assertion": localStorage.getItem("token"),
                },
                body: JSON.stringify(payload),
            });
            if (resp.status === 401 || resp.status === 403) {
                if (resp.status === 401) {
                  localStorage.clear();
                  window.location.href = "/login";
                } else if (resp.status === 403) {
                  addToastMessage({
                    status: false,
                    message:
                      "Access denied. You do not have permission to perform this action.",
                  });
                    setLoading(false);    
                    setStatus("Access denied. You do not have permission to perform this action.");
                }
                return false;
              }
            if (resp.ok) {
                const response = await resp.json();
                if (response) {
                    return response.result.responseCode === 200 ? true : false;
                } else {
                    const responseData = await resp.json();
                    if (responseData) {
                        setStatus(`${responseData.responseDescription || "Something went wrong, please try again later."}`);
                        addToastMessage({
                            status: false,
                            message: `${responseData.responseDescription || "Something went wrong, please try again later."}`,
                        });
                        return false;
                    }
                    setStatus("Something went wrong, please try again later.");
                    addToastMessage({
                        status: false,
                        message: `Something went wrong, please try again later.`,
                    });
                    return false;
                }
            } else {
                const responseData = await resp.json();
                if (responseData) {
                    setStatus(`${responseData.responseDescription || "Something went wrong, please try again later."}`);
                    addToastMessage({
                        status: false,
                        message: `${responseData.responseDescription || "Something went wrong, please try again later."}`,
                    });
                    return false;
                }
                setStatus("Something went wrong, please try again later.");
                addToastMessage({
                    status: false,
                    message: `Something went wrong, please try again later.`,
                });
                return false;
            }
        } catch (e) {
            setStatus("Something went wrong, please try again later.");
            addToastMessage({
                status: false,
                message: `Something went wrong, please try again later.`,
            });
            console.log("Error:", e);
            return false;
        } finally {
            setLoading(false);
        }
    };
  





    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values, { resetForm }) => {
                setLoading(true);
				const api = await addKnowledgeBase(values);
				if (api) {
                    handleReset();
					resetForm();
					setStatus(
						"You have successfully created a knowledge base, thank you."
					); 
    
                    addToastMessage({
						status: true,
						message: `You have successfully created an knowledge base, thank you.`,
					})
					history.push("/knowledge-base");
				}
            }}
        >
            {({ handleSubmit, validateForm, setFieldValue, errors, touched, values }) => (

                <Form>
                    <div className='col-1-1 '>
                        <table>
                            <tbody>
                                <tr>
                                    <td colSpan={2}>
                                        <Label title="Title" required />
                                        <Field
                                            name="name"
                                            type="text"
                                            placeholder="Eg: How to Create.."
                                            className={`primary ${errors.name && touched.name ? 'error' : ''}`}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Label title="Category" required />
                                        <PaginatedDropdown
                                            url={`${API_URL}/api/1.0.0/knowledge-base-category?`}
                                            searchkey='name'
                                            mapOptions={(records) =>
                                                records?.map((record) => ({
                                                    value: record["id"],
                                                    label: record.name,
                                                    obj: record,
                                                }))
                                            }
                                            error={errors.articlecategory && touched.articlecategory}
                                            selectedValue={values.articlecategory}
                                            placeHolder='Search'
                                            datakey="knowledgeBaseCategoryList"
                                            onChange={(v) => {
                                                setFieldValue(
                                                    "articlecategory",
                                                    v
                                                );
                                                setFieldValue(
                                                    "articlesubcategory",
                                                    null
                                                ); 
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <Label title="Sub Category" required />
                                        <PaginatedDropdown
                                            searchkey='name'
                                            datakey="knowledgeBaseSubCategoryList"
                                            url={`${API_URL}/api/1.0.0/knowledge-base-subcategory?statusId=1&categoryId=${values?.articlecategory?.value}&`}
                                            name="articlesubcategory"
                                            mapOptions={(records) =>
                                                records?.map((record) => ({
                                                    value: record["id"],
                                                    label: record.name,
                                                    obj: record,
                                                }))
                                            }
                                            placeHolder='Search'
                                            error={errors.articlesubcategory && touched.articlesubcategory}
                                            onChange={(v) => {

                                                setFieldValue(
                                                    "articlesubcategory",
                                                    v
                                                );
                                            }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>
                                        <div
                                            className='flex center-left'
                                            style={{ gap: "8px" }}>
                                            <div
                                                className='opl-tooltip'
                                                data-tooltip='Please only select PDF files for upload.'>
                                                <Label title='Upload File' />
                                            </div>
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='24'
                                                height='24'
                                                viewBox='0 0 24 24'
                                                style={{
                                                    height: "12px",
                                                    width: "12px",
                                                    marginBottom: "5px",
                                                }}>
                                                <path
                                                    d='M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.351 6.493c-.08-.801.55-1.493 1.351-1.493s1.431.692 1.351 1.493l-.801 8.01c-.029.282-.266.497-.55.497s-.521-.215-.55-.498l-.801-8.009zm1.351 12.757c-.69 0-1.25-.56-1.25-1.25s.56-1.25 1.25-1.25 1.25.56 1.25 1.25-.56 1.25-1.25 1.25z'
                                                    fill='#5dc6b3'
                                                />
                                            </svg>
                                        </div>
                                        <DragFileUpload
                                            ref={fileUploadRef}
                                            fileType={"KNOWLEDGE_BASE_DOC"} 
                                            error={errors.file && touched.file}
                                            afterUpload={(url) => {
                                                setFieldValue("file", url);
                                            }}
                                        />

                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>
                                        <Label title="Description" required />
                                        <Field
                                            name="description"
                                            as="textarea"
                                            placeholder="Enter Description"
                                            className={`primary ${errors.description && touched.description ? 'error' : ''}`}
                                            style={{
                                                width: '100%',
                                                height: '100px',
                                                resize: 'none'
                                            }}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <br />
                    <div className='flex center-left'>
                        <ButtonTextIcon
                            type='button'
                            className='light'
                            title='Cancel'
                            onClick={() => history.push('/knowledge-base')}
                            icon={
								<svg
									clipRule="evenodd"
									fillRule="evenodd"
									strokeLinejoin="round"
									strokeMiterlimit="2"
									viewBox="0 0 24 24"
									xmlns="http://www.w3.org/2000/svg"
									width='19.995'
									height='19.996'
								>
									<path
										d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 1.5c-4.69 0-8.497 3.807-8.497 8.497s3.807 8.498 8.497 8.498 8.498-3.808 8.498-8.498-3.808-8.497-8.498-8.497zm0 7.425 2.717-2.718c.146-.146.339-.219.531-.219.404 0 .75.325.75.75 0 .193-.073.384-.219.531l-2.717 2.717 2.727 2.728c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.384-.073-.53-.219l-2.729-2.728-2.728 2.728c-.146.146-.338.219-.53.219-.401 0-.751-.323-.751-.75 0-.192.073-.384.22-.531l2.728-2.728-2.722-2.722c-.146-.147-.219-.338-.219-.531 0-.425.346-.749.75-.749.192 0 .385.073.531.219z"
										fillRule="nonzero" 
									/>
								</svg>
							}
                        />
                        <ButtonTextIcon
                            type='submit'
                            disabled={loading}
                            title='Create Now'
                            onClick={async () => {
                                const errors = await validateForm(); 
                                if (Object.keys(errors).length > 0) {
                                  const firstErrorMessage = Object.values(errors)[0];
                                  addToastMessage({
                                    status: false,
                                    message: `${firstErrorMessage || "Something went wrong, please try again later."}`,
                                  });
                                }
                                handleSubmit(); 
                              }}
                            className='mar-l-a'
                            icon={loading ? <LoaderIcon /> : (
                                <svg xmlns='http://www.w3.org/2000/svg' width='19.995' height='19.996' viewBox='0 0 19.995 19.996'>
                                    <path d='M12.012,2a10,10,0,1,0,10,10,10,10,0,0,0-10-10Zm0,1.5a8.5,8.5,0,1,1-8.5,8.5,8.5,8.5,0,0,1,8.5-8.5ZM13.54,8.21s1.5,1.505,3.255,3.259a.752.752,0,0,1,0,1.061c-1.753,1.754-3.254,3.258-3.254,3.258a.742.742,0,0,1-.527.217.752.752,0,0,1-.534-1.278l1.978-1.977H7.764a.75.75,0,0,1,0-1.5h6.694L12.479,9.271a.746.746,0,0,1,.006-1.054.754.754,0,0,1,.531-.222.738.738,0,0,1,.524.215Z' transform='translate(-2.014 -1.995)' />
                                </svg>
                            )}/>
                    </div>
                    {status && (
                        <>
                            <br />
                            <p className='color-primary f-w-700'>{status}</p>
                        </>
                    )}
                </Form>
            )}
        </Formik>
    );
}

export default AddForm;