const APP_ENV = process.env.REACT_APP_MAIN_ENVIRONMENT;
// const APP_ENV = "oracle"; // | oracle | aws-staging | aws-production
const API_MAPPING = {
	"local": {
		JavaURL: "https://java-be.octopi-labs.com",
		LaravelApiUrl: "https://coremax-be.octopi-labs.com/api/",
		SystemToken:
			"eyJhbGciOiJIUzI1NiJ9.eyJ1c2VyaWQiOjEsInVzZXJOYW1lIjoiU3lzdGVtLVRva2VuIiwiYXV0aG9yaXRpZXMiOlsxXSwiaXNzIjoib2N0b3BpLWxhYnMuY29tIiwiZXhwIjoxODc5MzQwMTg1LCJpYXQiOjE3MjE2NjAxODV9.RR58MjQo9ECCW1aoMeJbtE4cvtENMfDegrt5MMzo0r0",
		AppVersion: "2.0.1",
	},
	"oracle": {
		ApiUrl: "https://coremax-java-be.octopi-labs.com",
		LaravelApiUrl: "https://coremax-be.octopi-labs.com/api/",
		SystemToken:
			"eyJhbGciOiJIUzI1NiJ9.eyJ1c2VyaWQiOjEsInVzZXJOYW1lIjoiU3lzdGVtLVRva2VuIiwiYXV0aG9yaXRpZXMiOlsxXSwiaXNzIjoib2N0b3BpLWxhYnMuY29tIiwiZXhwIjoxODc5MzQwMTg1LCJpYXQiOjE3MjE2NjAxODV9.RR58MjQo9ECCW1aoMeJbtE4cvtENMfDegrt5MMzo0r0",
		AppVersion: "3.0.0",
	},
	"aws-staging": {
		ApiUrl: "https://aws-staging-java.coremaxcloud.com",
		LaravelApiUrl: "https://aws-staging-be.coremaxcloud.com/api/",
		SystemToken:
			"eyJhbGciOiJIUzI1NiJ9.eyJ1c2VyaWQiOjEsInVzZXJOYW1lIjoiU3lzdGVtLVRva2VuIiwiYXV0aG9yaXRpZXMiOlsxXSwiaXNzIjoib2N0b3BpLWxhYnMuY29tIiwiZXhwIjoxODc5MzQwMTg1LCJpYXQiOjE3MjE2NjAxODV9.RR58MjQo9ECCW1aoMeJbtE4cvtENMfDegrt5MMzo0r0",
		AppVersion: "2.0.0",
	},
	"aws-production": {
		ApiUrl: "https://java-be.coremaxcloud.com",
		LaravelApiUrl: "https://be.coremaxcloud.com/api/",
		SystemToken:
			"eyJhbGciOiJIUzI1NiJ9.eyJ1c2VyaWQiOjEsInVzZXJOYW1lIjoiU3lzdGVtLVRva2VuIiwiYXV0aG9yaXRpZXMiOlsxXSwiaXNzIjoib2N0b3BpLWxhYnMuY29tIiwiZXhwIjoxODc5MzQwMTg1LCJpYXQiOjE3MjE2NjAxODV9.RR58MjQo9ECCW1aoMeJbtE4cvtENMfDegrt5MMzo0r0",
		AppVersion: "2.0.0",
	},
};

export const API_URL = API_MAPPING[APP_ENV].ApiUrl;//'http://192.168.1.125:8080';//
export const LARAVEL_API_URL = API_MAPPING[APP_ENV].LaravelApiUrl;
export const SYSTEM_TOKEN = API_MAPPING[APP_ENV].SystemToken;
export const APP_VERSION = API_MAPPING[APP_ENV].AppVersion;
export const SCANFLOW_STATUS_LIST = [
	{
		id: 1,
		name: "Not Started",
		slug: "not-started",
	},
	{
		id: 2,
		name: "In Progress",
		slug: "in-progress",
	},
	{
		id: 3,
		name: "Forwarded",
		slug: "forwarded",
	},
	{
		id: 4,
		name: "Paused",
		slug: "paused",
	},
	{
		id: 5,
		name: "Completed",
		slug: "completed",
	},
];

export const BILL_STATUS_OPTTIONS = [
	{ value: "billable", label: "Billable" },
	{ value: "non billable", label: "Non Billable" },
	{ value: "hospital contract", label: "Hospital Contract" },
	{
		value: "hospital contract second scrub",
		label: "Hospital Contract Second Scrub",
	},
	{ value: "bill to patient", label: "Bill to Patient" },
	{ value: "bill to physician", label: "Bill to Physician" },
];

export const CLAIM_STATUS_DROP_DOWN_OPTIONS = [
	{ label: "NOT STARTED", value: "not started" },
	{ label: "SUBMITTED", value: "submitted" },
	{ label: "CORRECTED CLAIM", value: "corrected claim" },
	{ label: "PAPER CLAIM", value: "paper claim" },
	{ label: "RECONSIDERATION", value: "reconsideration" },
	{ label: "FOLLOW-UP", value: "followup" },
	{ label: "APPEAL 1", value: "appeal 1" },
	{ label: "APPEAL 2", value: "appeal 2" },
	{ label: "HOLD TO BILL", value: "hold to bill" },
	{ label: "BILL PATIENT", value: "bill patient" },
	{ label: "BILL HOSPITAL", value: "bill hospital" },
	{ label: "BILL PHYSICIAN", value: "bill physician" },
	{ label: "REVIEW ADJUSTMENT", value: "review adjustment" },
	{ label: "CLOSED", value: "closed" },
	{ label: "CLOSED NON-BILL", value: "closed non-bill" },
	{ label: "CLOSED ADJUSTMENT", value: "closed adjustment" },
	{ label: "CLOSED HC-2nd Scrub", value: "closed hc-2nd scrub" },
];

export const HOSPITAL_CONTRACT_DROP_DOWN_OPTIONS = [
	{ label: "Non Contracted", value: "non-contracted" },
	{ label: "Billed Hospital", value: "billed hospital" },
	{ label: "Payment Received", value: "payment received" },
];

export const ACTIVITY_STATUS_DROP_DOWN_OPTIONS = [
	{ label: "New", value: "new" },
	{ label: "Started", value: "started" },
	{ label: "Completed", value: "completed" },
	{ label: "Cancelled", value: "cancelled" },
	{ label: "Billable", value: 1 },
	{ label: "Non-Billable", value: 2 },
	{ label: "Hospital Contract", value: 3 },
];

export const ACTIVITY_BILL_DROP_DOWN_OPTIONS = [
	{ label: "Activity With Generated Bill", value: "Billed" },
	{ label: "Activity With Not Generated Bill", value: "UnBilled" },
];

export function getLabelByValue(value) {
	if(!value){
      return null;
	}
	const option = BILL_STATUS_OPTTIONS.find((opt) => opt.value === value);
	return option ? option.label : null;
}

export const ROLES_LIST = [
	{
		name: "sorting-manager",
		type: "manager",
		department: "sorting",
	},
	{
		name: "data-entry-manager", //data-entry <- registration
		type: "manager",
		department: "data-entry",
	},
	{
		name: "adminstrative-manager-coding",
		type: "manager",
		department: "adminstrative-coding", //adminstrative-coding <- coding
	},
	{
		name: "charge-entry-manager",
		type: "manager",
		department: "charge-entry",
	},
	{
		name: "billing-manager",
		type: "manager",
		department: "billing",
	},
	{
		name: "sorting",
		type: "executive",
		department: "sorting",
	},
	{
		name: "data-entry",
		type: "executive",
		department: "data-entry",
	},
	{
		name: "adminstrative-coding",
		type: "executive",
		department: "adminstrative-coding",
	},
	{
		name: "charge-entry",
		type: "executive",
		department: "charge-entry",
	},
	{
		name: "billing",
		type: "executive",
		department: "billing",
	},
];

export const ALLOWED_FILE_TYPES = [
	{ label: "Appeals", value: "appeal" },
	{ label: "Authorization", value: "authorization" },
	{ label: "EOB", value: "eob" },
	{ label: "Facesheet", value: "facesheet" },
	{ label: "OP Report", value: "op" },
	{ label: "PN", value: "pn" },
	{ label: "Check", value: "check" },
	{ label: "Claim", value: "claim" },
	{ label: "Correspondence", value: "correspondence" },
];

export const PLACE_OF_SERVICE = [
	{
		label: "No Default POS",
		value: "1",
	},
	{
		label: "Office",
		value: "11",
	},
	{
		label: "Inpatient Hospital",
		value: "21",
	},
	{
		label: "Outpatient Hospital",
		value: "22",
	},
	{
		label: "Emergency Room - Hospital",
		value: "23",
	},
	{
		label: "Ambulatory Surgical Center",
		value: "24",
	},
];

export const SUGGESTED_COMMENTS = [
	"Completed.",
	"Forwarded to Someone else.",
	"In Progress.",
	"Waiting for Approval.",
];

export const ANDROID_APP_NAME = "com.coremaxmobileapp";
export const IOS_APP_NAME = "com.coremaxmobileapp";

// DROPDOWN OPTIONS FOR GROUP FORM
export const FACILITY_LISTING_OPTIONS=[
	{ label: 'All Facilities', value: 'All Facilities' },
	{ label: 'Assigned Facilities', value: 'Assigned Facilities' },
]

export const PATIENT_LISTING_OPTIONS=[
	{ label: 'All Patients', value: 'All Patients' },
	{ label: 'Assigned Patients', value: 'Assigned Patients' },
]

export const PREFRENCE_CARD_LISTING_OPTIONS = [
	{ label: 'All Prefrence Cards', value: 'All Prefrence Cards' },
	{ label: 'Assigned Prefrence Cards', value: 'Assigned Surgeons Prefrence Cards' },
]

export const SURGEON_LISTING_OPTIONS=[
	{ label: 'All Surgeons', value: 'All Surgeons' },
	{ label: 'Assigned Surgeons', value: 'Assigned Surgeons' },

]

export const ORGANIZATION_PAY_OPTIONS = [
	{
		label: "Pay To Organization",
		value: "Organization",
	},
	{
		label: "Pay To Individual",
		value: "Individual",
	},
];
 
export const STATUS_USER_OPTIONS = [
	{ label: 'Active', value: '1' },
	{ label: 'Inactive', value: '0' },
]

export const USER_TYPE_OPTIONS = [
	{ value: "admin", label: "Admin" },
	{ value: "assistant", label: "Surgical Assistant" },
	{ value: "Physician Assistant", label: "Physician Assistant" },
	{ value: "biller", label: "Biller" },
	{ value: "manager", label: "Manager" },
	{ value: "data-entry-manager", label: "Data Entry Manager" },
	{ value: "data-entry", label: "Data Entry" },
	{ value: "billing-manager", label: "Billing Manager" },
	{ value: "billing", label: "Billing" }
];

export const BILLING_LISTING_OPTIONS = [
	{ label: 'Billable', value: 'billable' },
	{ label: 'Non Billable', value: 'non-billable' },
	{ label: 'Non-Billable Commercial', value: 'non-billable-commercial' }
];

export const FACILITY_TYPE_OPTIONS=[
	{ label: 'Assisted Living', value: 'assistedliving' },
	{ label: 'Home Health', value: 'homehealth' },
	{ label: 'Hospice', value: 'hospice' },
	{ label: 'Hospital', value: 'hospital' },
	{ label: 'Nursing Home', value: 'nursinghome' },
	{ label: 'Office', value: 'office' },
]

export const FACILITY_INVOICE_OPTIONS = [
    { label: 'No Need', value: 'noneed' },
    { label: 'Procedure Name', value: 'procedurename' },
    { label: 'Cpt Code', value: 'cptcode' },
    { label: 'Procedure Name & CptCode', value: 'both' },
];

export const EMR_ACCESS_OPTIONS=[
	{ label: 'NO EMR ACCESS', value: 'noemr' },
	{ label: 'EMR ACCESS', value: 'emr' },
	{ label: 'NO EMR ACCESS FAX REQ', value: 'noemrfax' },
	{ label: 'NO ACCESS', value: 'noaccess' },
	{ label: 'HL7 DATA FEED', value: 'hl7data' },
]

export const CLEARANCE_OPTIONS=[
	{ label: 'YES', value: 'yes' },
	{ label: 'NO', value: 'no' },
	
]

export const SUFFIX_OPTIONS=[
	{ label: 'MD', value: 'MD' },
	{ label: 'DO', value: 'DO' },
	
]

export const DEGREE_OPTIONS=[
	{ value: "Associate Degree", label: "Associate Degree" },
	{ value: "Board Certification", label: "Board Certification" },
	{ value: "Professional License", label: "Professional License" }
]

export const DEGREE_STATUS_OPTIONS = [
	{ value: "Not Verified", label: "Not Verified" },
	{ value: "Verification In Progress", label: "Verification In Progress" },
	{ value: "Verified", label: "Verified" }
]

export const ROLE_OPTIONS = [
	{ value: "Divisional Manager", label: "Divisional Manager" },
	{ value: "Physician Assistant", label: "Physician Assistant" }
]


export const MENU_LIST = [
	{
		title: "Scheduling",
		slug: "scheduling",
		icon: null,
		sub_menu: [],
	},
	{
		title: "Activities",
		slug: "activities",
		icon: null,
		sub_menu: [],
	},
	{
		title: "Scan Flow",
		slug: "scan-flow",
		icon: null,
		sub_menu: [],
	},
	{
		title: "Patients",
		slug: "patients",
		icon: null,
		sub_menu: [],
	},
	{
		title: "Procedures",
		slug: "procedures",
		icon: null,
		sub_menu: [],
	}, 
	// {
	// 	title: "Revenue Cycle",
	// 	slug: "",
	// 	icon: null,
	// 	sub_menu: [
	// 		{
	// 			title: "Claims and Collection",
	// 			slug: "claims-and-collections",
	// 			icon: null,
	// 			sub_menu: [],
	// 		},
	// 		{
	// 			title: "Revenue Scheduler",
	// 			slug: "revenue-scheduler",
	// 			icon: null,
	// 			sub_menu: [],
	// 		},
	// 		{
	// 			title: "TDI/IDR",
	// 			slug: "tdi-idr",
	// 			icon: null,
	// 			sub_menu: [],
	// 		},
	// 		{
	// 			title: "",
	// 			slug: "",
	// 			icon: null,
	// 			sub_menu: [],
	// 		},
	// 	],
	// },
	{
		title: "Accounts",
		slug: "",
		icon: null,
		sub_menu: [
			{
				title: "Bill",
				slug: "bills",
				icon: null,
				sub_menu: [],
			},
			{
				title: "Invoicing",
				slug: "invoicing",
				icon: null,
				sub_menu: [],
			},
			// {
			// 	title: "Invoicing V1",
			// 	slug: "invoicingv1",
			// 	icon: null,
			// 	sub_menu: [],
			// },
			{
				title: "Contracts",
				slug: "contracts",
				icon: null,
				sub_menu: [],
			},
		],
	}, 
	{
		title: "Setup",
		slug: "",
		icon: null,
		sub_menu: [
			{
				title: "Manage Groups",
				slug: "groups",
				icon: null,
				sub_menu: [],
			},
			{
				title: "Manage Users",
				slug: "users",
				icon: null,
				sub_menu: [],
			},
			{
				title: "Archived Users",
				slug: "archived-users",
				icon: null,
				sub_menu: [],
			},
			{
				title: "Manage Insurance",
				slug: "insurance",
				icon: null,
				sub_menu: [],
			},
			{
				title: "Manage Practice",
				slug: "practices",
				icon: null,
				sub_menu: [],
			},
			{
				title: "ZIP",
				slug: "zip-codes",
				icon: null,
				sub_menu: [],
			}
		],
	},
	{
		title: "Providers",
		slug: "",
		icon: null,
		sub_menu: [
			{
				title: "Physician Assistants",
				slug: "physicians_assistance",
				icon: null,
				sub_menu: [],
			},
			{
				title: "Surgical Assistants",
				slug: "surgical_assistance",
				icon: null,
				sub_menu: [],
			},
			{
				title: "Surgeons",
				slug: "surgeons",
				icon: null,
				sub_menu: [],
			},
			{
				title: "Surgeon Association",
				slug: "surgeon-association",
				icon: null,
				sub_menu: [],
			},
			{
				title: "Facilities",
				slug: "facilities",
				icon: null,
				sub_menu: [],
			}, 
			// {
			// 	title: "Division Management",
			// 	slug: "division",
			// 	icon: null,
			// 	sub_menu: [],
			// },  
		],
	},
	{
		title: "Knowledge Base",
		slug: "",
		icon: "",
		sub_menu: [
			{
				title: "Categories",
				slug: "knowledge-base-categories",
				icon: null,
				sub_menu: [],
			},
			{
				title: "Sub Categories",
				slug: "knowledge-base-sub-categories",
				icon: null,
				sub_menu: [],
			},
			{
				title: "Knowledge Base",
				slug: "knowledge-base",
				icon: null,
				sub_menu: [],
			},
			{
				title: "",
				slug: "",
				icon: null,
				sub_menu: [],
			},
		],
	},
	{
		title: "Regions",
		slug: "regions",
		icon: null,
		sub_menu: [],
	}, 
	// {
	// 	title: "Batches",
	// 	slug: "Batch",
	// 	icon: null,
	// 	sub_menu: [],
	// },
	// {
	// 	title: "Logs",
	// 	slug: "logs",
	// 	icon: null,
	// 	sub_menu: [],
	// },
	// {
	// 	title: "Clients",
	// 	slug: "tenant-clients",
	// 	icon: null,
	// 	sub_menu: [],
	// },
	// {
	// 	title: "Feedback",
	// 	slug: "feedback",
	// 	icon: null,
	// 	sub_menu: [],
	// }, 
];

export const PBFUSA_EMAIL_ARRAY = [
	"mchaudhry@uspcorporate.com",
	"dawadiprabhat@gmail.com" 
]

export const INVOICE_OPTIONS_ARRAY= [
	{
	  "value": "Yes",
	  "label": "Bill With Generated Invoice"
	},
	{
	  "value": "No",
	  "label": "Bill With Not Generated Invoice"
	}
];

export const CREDENTIAL_OPTIONS=[
	{
	  "value": "PA-C",
	  "label": "PA-C"
	},
	{
	  "value": "LSA",
	  "label": "LSA"
	},
	{
		"value": "CSA",
		"label": "CSA"
	}
];

