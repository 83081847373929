import ResultsPerPage from "../../components/common/ResultsPerPage";
import ButtonLightTextIcon from "../../components/common/ui/ButtonLightTextIcon";
import PaginatedDropdown from "../../components/common/ui/PaginatedDropdown"; 
import {
	API_URL, 
    INVOICE_OPTIONS_ARRAY,
} from "../../opl-utilities/constants";
import { withRouter } from "react-router-dom";
import { formatDate } from "../../opl-utilities/helpers"; 
import DateRangeInput from "../../components/common/ui/DateRangeInput";

const Label = ({ title }) => {
	return (
		<p
			className='color-primary f-w-300 l-h-1'
			style={{ fontSize: "12px", paddingBottom: "6px" }}>
			&nbsp;{title}
		</p>
	);
};

const TableFilter = ({ history, location, resetFilters }) => {
	const queryParams = new URLSearchParams(location.search);

	const handleQueryParamChange = (key, value) => { 
		queryParams.set("pageNo", 0);
		value ? queryParams.set(key, value) : queryParams.delete(key);
		history.push({ search: queryParams.toString() });
	};

	const mapOptions = (records, valueKey, labelFormatter) =>
		records.map((record) => ({
			value: record[valueKey],
			label: labelFormatter(record),
		}));

	const DROPDOWNS = [
		{
			title: "Patients",
			url: "/reference/patients",
			param: "patient",
			mapFunc: mapOptions,
			labelFunc: (o) => `${o?.firstName || ''} ${o?.middleName || ''} ${o?.lastName || ''} ${o?.dateOfBirth ? `(${formatDate(o.dateOfBirth)})` : ''}`,
			placeholder: "Search patient",
		}, 
		// {
		// 	title: "Practices",
		// 	url: "/reference/practice",
		// 	param: "practice",
		// 	mapFunc: mapOptions,
		// 	labelFunc: (o) => o.payToName,
		// 	placeholder: "Search practice",
		// },
		{
			title: "Facilities",
			url: "/reference/facility",
			param: "facility",
			mapFunc: mapOptions,
			labelFunc: (o) => o.facilityName,
			placeholder: "Search facility",
		},
	];

	return (
		<div className='col-1-1 filters'>
			{DROPDOWNS.map(
				({ title, url, param, mapFunc, labelFunc, placeholder }) => (
					<div key={param}>
						<Label title={title} />
						<PaginatedDropdown
							url={`${API_URL}/api/1.0.0${url}?`}
							selectedValue={
								queryParams.get(param)
									? JSON.parse(queryParams.get(param))
									: null
							}
							onChange={(v) =>
								handleQueryParamChange(
									param,
									v ? JSON.stringify(v) : null
								)
							}
							mapOptions={(records) =>
								mapFunc(records, "id", labelFunc)
							}
							placeHolder={placeholder}
						/>
					</div>
				)
			)} 
			<div>
				<Label title='Date Filter' />
				<DateRangeInput 
				    initialRange={{
							startDate: queryParams.get("startDate") || null , 
							endDate : queryParams.get("endDate") || null 
						}} 
					onApply={(v) => { 
							handleQueryParamChange("startDate", v?.startDate || null); 
							handleQueryParamChange("endDate", v?.endDate || null); 
						}} 
					onCancel={(v) => { 
						handleQueryParamChange("startDate", null); 
						handleQueryParamChange("endDate", null); 
					}}  
					/>
			</div> 
			<div className='flex center-left' style={{ gap: "12px" }}>
				<div>
					<Label title='Reset' />
					<ButtonLightTextIcon
						title='Reset'
						className='light'
						icon={
							<svg
								width='800px'
								height='800px'
								viewBox='0 0 512 512'>
								<path d='M64,256H34A222,222,0,0,1,430,118.15V85h30V190H355V160h67.27A192.21,192.21,0,0,0,256,64C150.13,64,64,150.13,64,256Zm384,0c0,105.87-86.13,192-192,192A192.21,192.21,0,0,1,89.73,352H157V322H52V427H82V393.85A222,222,0,0,0,478,256Z' />
							</svg>
						}
						onClick={resetFilters}
					/>
				</div>
				<div className='mar-l-a'>
					<Label title='Per page' />
					<ResultsPerPage />
				</div>
			</div>
		</div>
	);
};

export default withRouter(TableFilter);
