import { useState } from "react";
import ButtonLightTextIcon from "./ButtonLightTextIcon";
import ButtonTextIcon from "./ButtonTextIcon";
import LoaderIcon from "./LoaderIcon";


const ConfirmationModal = ({ message, onConfirm, setShow }) => {
	const [loading, setLoading] = useState(false); 

    return (
        <section id='opl_coremax'>
            <section
                style={{
                    position: "fixed",
                    inset: 0,
                    zIndex: 9999,
                    backgroundColor: "rgba(255, 255, 255, 0.86)",
                }}>
                <section
                    className='col-1-1 flex center-center'
                    style={{
                        height: "100vh",
                        width: "100vw",
                    }}>
                    <div
                        style={{
                            width: "486px",
                            padding: "24px",
                            height: "auto",
                            maxHeight: "80vh",
                            border: "solid 1px #f0f0f0",
                            background: "white",
                            borderRadius: "8px",
                            margin: "0 auto",
                            boxShadow: "0px 0px 20px rgba(0,0,0,0.08)",
                        }}>
                        <div
                            className='col-1-1 flex center-left'
                            style={{
                                borderBottom: "solid 1px #f0f0f0",
                                paddingBottom: "12px",
                                marginBottom: "16px",
                            }}>
                            <h6
                                className='color-primary f-w-300 opl-tooltip'
                                data-tooltip='View entire history of the activity below.'>
                                Confirmation
                            </h6>
                            <div
                                className='mar-l-a cursor-pointer'
                                // data-tooltip='Close to return to the previous section.'// opl-tooltip
                                onClick={() => setShow(false)}>
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='24'
                                    height='24'
                                    viewBox='0 0 24 24'>
                                    <path
                                        d='M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z'
                                        fill='#283f54'
                                    />
                                </svg>
                            </div>
                        </div>
                        <div >
                            <section>
                                <p dangerouslySetInnerHTML={{ __html: message }} />
                            </section>
                        </div>
                        <div
                            className='col-1-1 flex center-center'
                            style={{
                                marginTop: "16px",
                                borderTop: "solid 1px #f0f0f0",
                                paddingTop: "16px",
                                gap:"10px"
                            }}>
                            <ButtonLightTextIcon
                                title='Cancel'
                                onClick={() => setShow(false)}
                                icon={
                                    <svg
                                        clipRule="evenodd"
                                        fillRule="evenodd"
                                        strokeLinejoin="round"
                                        strokeMiterlimit="2"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width='19.995'
                                        height='19.996'
                                    >
                                        <path
                                            d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 1.5c-4.69 0-8.497 3.807-8.497 8.497s3.807 8.498 8.497 8.498 8.498-3.808 8.498-8.498-3.808-8.497-8.498-8.497zm0 7.425 2.717-2.718c.146-.146.339-.219.531-.219.404 0 .75.325.75.75 0 .193-.073.384-.219.531l-2.717 2.717 2.727 2.728c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.384-.073-.53-.219l-2.729-2.728-2.728 2.728c-.146.146-.338.219-.53.219-.401 0-.751-.323-.751-.75 0-.192.073-.384.22-.531l2.728-2.728-2.722-2.722c-.146-.147-.219-.338-.219-.531 0-.425.346-.749.75-.749.192 0 .385.073.531.219z"
                                            fillRule="nonzero" 
                                        />
                                    </svg>
                                }
                            />
                            <ButtonTextIcon
                                title='Confirm'
                                onClick={async () => { 
                                    setLoading(true);  
                                    await onConfirm(); 
                                    setShow(); 
                                    setLoading(false); 
                                }}
                                disabled={loading}
                                icon={
                                    loading ?
                                        <LoaderIcon />
                                        :
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='24'
                                            height='24'
                                            viewBox='0 0 24 24'>
                                            <path d='M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1 17l-5-5.299 1.399-1.43 3.574 3.736 6.572-7.007 1.455 1.403-8 8.597z' />
                                        </svg>
                                }
                            /> 
                        </div>
                    </div>
                </section>
            </section>
        </section>
    );
};

export default ConfirmationModal;