import "../styles/base.scss";
import "../styles/pages.scss";
import "../styles/animations.scss";
import "../styles/responsive.scss";
import { useEffect, useState } from "react";
import { API_URL, SYSTEM_TOKEN } from "../opl-utilities/constants";
import { NavLink, withRouter } from "react-router-dom";
import LoaderIcon from "../components/common/ui/LoaderIcon";
import Header from "../components/common/ui/Header";
import ButtonTextIcon from "../components/common/ui/ButtonTextIcon";
import Pagination from "../components/common/Pagination";
import PatientFilter from "./patient/PatientFilter";
import { decodeModuleAccessFromToken, formatDate } from "../opl-utilities/helpers";
import ConfirmationModal from "../components/common/ui/ConfirmationModal";
import CustomModal from "../components/common/CustomModal";
import { useUI } from "../context/ui";
import ButtonTertiary from "../components/common/ui/ButtonTertiary";
import PatientDetails from "./patient/PatientDetails";
import { parseISO } from "date-fns";

const jsonToQueryParams = (json) => {
	const queryParams = Object.keys(json)
		.filter((key) => json[key] !== null && json[key] !== "")
		.map(
			(key) =>
				`${encodeURIComponent(key)}=${encodeURIComponent(json[key])}`
		)
		.join("&");

	return queryParams;
};

const PatientPage = ({ history, location, activePage }) => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [data, setData] = useState(null);
	const queryParams = new URLSearchParams(location.search);
	const pageSize = queryParams.get("pageSize") || 12;
	const pageNo = queryParams.get("pageNo") || 0;
	const { addToastMessage } = useUI(); 

	// Decode permissions for the "users" module
	const permissions = decodeModuleAccessFromToken("patients");

	// Redirect to login if no permissions
	useEffect(() => {
		if (!permissions) {
			history.push("/login");
		}
	}, [permissions, history]);

	// ====== FILTER QUERY PARAMS
	const search = queryParams.get("search") || "";
	const dob = queryParams.get("dob") || "";

	// ====== RESET FILTER
	const resetFilters = () => {
		updateQueryParam("search", "");
		updateQueryParam("dob", "");
		updateQueryParam("pageNo", 0);
	};
 
	const handleDelete = async (id) => {
		try {
			if (!id) {
				addToastMessage({
					status: false,
					message: `Something went wrong while deleting patient. Please try again.`,
				});
				return false;
			}
			const response = await fetch(
				API_URL +
				`/api/1.0.0/patient/${id}`,
				{
					method: "DELETE",
					headers: {
						"Content-Type": "application/json",
						"X-JWT-Assertion": localStorage.getItem("token")
					},
				}
			);
			if (response.status === 401 || response.status === 403) {
				if (response.status === 401) {
					localStorage.clear();
					window.location.href = "/login";
				} else if (response.status === 403) {
					addToastMessage({
						status: false, 
						message: "Access denied. You do not have permission to perform this action.",
					});
				}
				return false; 
			}
			if (response.status === 200) {
				await getListApi();
				addToastMessage({
					status: true,
					message: `Patient deleted successfully.`,
				});
				return true;
			}
			addToastMessage({
				status: false,
				message: `Something went wrong while deleting patient. Please try again.`,
			});

			return false;
		} catch (error) {
			addToastMessage({
				status: false,
				message: `Something went wrong while deleting patient. Please try again.`,
			});
			return false;
		} 
	};
	
	const getListApi = async (s) => {
		setLoading(true);
		try {
			const query = jsonToQueryParams({
				search,
				dob
			});
			const resp = await fetch(
				API_URL +
				`/api/1.0.0/patient/list?pageSize=${pageSize}&pageNo=${pageNo}&${query}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						"X-JWT-Assertion": localStorage.getItem("token")
					},
				}
			);
			if (resp.status === 401 || resp.status === 403) {
				if (resp.status === 401) {
					localStorage.clear();
					window.location.href = "/login";
				} else if (resp.status === 403) {
					addToastMessage({
						status: false, 
						message: "Access denied. You do not have permission to perform this action.",
					});
					setError("Access denied. You do not have permission to perform this action.");
					setLoading(false);
				}
				return false; 
			}
			if (resp.ok) {
				const response = await resp.json();
				if (response) {
					setData(response.data);

				}
			}
		} catch (e) {
			console.log("Error:", e);
		} finally {
			setLoading(false);
		}
	};

	const updateQueryParam = (key, value) => {
		value ? queryParams.set(key, value) : queryParams.delete(key);
		history.push({ search: queryParams.toString() });
	};

	useEffect(() => {
		getListApi();
	}, [location.search]);

	return (
		<section id='opl_coremax'>
			<section className='main-section'>
				<Header active={activePage} location={location} />
				<section id='patient'>
					<div className='col-1-1 flex center-left pad-0-10 main-section-top-row'>
						<h5 className='page-active color-primary f-w-700'>
							Patients
						</h5> 
						{permissions?.isCreateAllowed &&
							<ButtonTextIcon
								title='Add New'
								className='mar-l-a'
								onClick={() => history.push("/add-patient")}
								icon={
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='19.995'
										height='19.995'
										viewBox='0 0 19.995 19.995'>
										<path
											d='M12,2a10,10,0,1,1-10,10A10,10,0,0,1,12,2Zm0,1.5A8.5,8.5,0,1,0,20.5,12,8.5,8.5,0,0,0,12,3.5Zm-.747,7.75h-3.5a.75.75,0,0,0,0,1.5h3.5v3.5a.75.75,0,0,0,1.5,0v-3.5h3.5a.75.75,0,0,0,0-1.5h-3.5V7.75a.75.75,0,0,0-1.5,0Z'
											transform='translate(-2.005 -2)'
										/>
									</svg>
								}
							/>
						}
						&nbsp;
					</div>
					<PatientFilter
						resetFilters={() => {
							resetFilters();
						}}
					/>
					<div>
						{data && !loading && (
							<div
								className='flex center-left'
								style={{
									padding: "10px 15px 10px 15px",
									gap: "12px",
								}}>
								<p
									className='f-w-300'
									style={{ fontSize: "11px" }}>
									Page no:
								</p>
								<Pagination
									totalRecords={data.totalRecords}
									pageNo={data.pageNo}
									pageSize={data.pageSize}
								/>
							</div>
						)}
					</div>
					{loading ? (
						<section
							style={{
								height: "100vh",
								paddingBottom: "20vh",
								backgroundColor: "#fafaff"
							}}
							className='flex center-center'>
							<LoaderIcon
								style={{ height: "32px", width: "32px" }}
							/>
						</section>
					) : data?.records.length ? (
						<div className='rs-table-holder'>
							<table className='rs-table'>
								<thead>
									<tr>
										<th>#</th>
										<th className="t-t-u">Full Name</th>
										<th className="t-t-u">Date of Birth</th>
										<th className="t-t-u">Age (Yrs)</th>
										<th className="t-t-u">Chart No.</th>
										<th className="t-t-u">Date of Service</th>
										<th className="t-t-u">Gender (Male / Female)</th>
										<th className="t-t-u">Insurance</th>
										<th style={{ textAlign: "center" }}>
											<div className="t-t-u"
												data-tooltip="Actions available: View, Edit, or Delete the record." >
												Action
											</div>
										</th>
									</tr>
								</thead>
								<tbody>
									{data && data.records.map((data, index) => (
										<tr key={index}>
											<td>{pageNo * pageSize + index + 1}</td>
											<td className="t-t-u">{data?.firstName||""} {data?.middleName||""} {data?.lastName||""}</td>
											<td className="t-t-u">{data?.dateOfBirth ? formatDate(data.dateOfBirth) : "-"}</td>
											<td className="t-t-u">{data?.age ? data.age + " Yrs" : "-"} </td>
											<td className="t-t-u">{data?.patientChartNo || "-"}</td>
											<td className="t-t-u">{data?.serviceDate ? formatDate(data?.serviceDate) : "-"}</td>
											<td className="t-t-u">{data?.gender}</td>
											<td className="t-t-u">{data?.patientInsuranceData?.primaryInsurance?.description || ""}</td>
											<td>
												<div className="flex center-center" style={{ gap: '12px' }}>
												{permissions?.isReadAllowed && (
                                                            <div className="color-secondary transition hover-color-primary cursor-pointer ">

                                                                <CustomModal
                                                                    selector={
                                                                        <ButtonTertiary
                                                                            actionType='view'
                                                                            title='View' 
                                                                        />
                                                                    }
                                                                    content={
                                                                                <PatientDetails 
                                                                                    id={data?.id}
                                                                                    history={history}
                                                                                />
                                                                    }
                                                                />
                                                            </div>

                                                        )}
												{permissions?.isUpdateAllowed &&
													<div >
														<NavLink
															to={{
																pathname: '/edit-patient',
																search: `?next=${window.location.pathname + window.location.search}`,
																state: { id: data.id }
															}}
														>
															 <ButtonTertiary
                                                                    actionType='edit'
                                                                    title='Edit'
                                                                />
														</NavLink>
													</div>
												}
												{permissions?.isDeleteAllowed &&
																<div className="color-secondary transition hover-color-primary cursor-pointer " >
																	<CustomModal
																		selector={<ButtonTertiary
																			actionType='delete'
																			title='Delete'
																		/>}
																		content={
																			<ConfirmationModal
																				onConfirm={async () => { await handleDelete(data.id); return true; }}
																				message="Are you sure you want to delete this patient?"
																			/>
																		}
																	/>
																</div>
															}  
												</div>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					) : (
						<section
							style={{
								height: "80vh",
								paddingBottom: "20vh",
								backgroundColor: "#fff",
							}}
							className='flex center-center'>
							<p>No records found.</p>
						</section>
					)}

					<div>
					</div>
					<div>
						<div>
							{data && !loading && (
								<div
									className='flex center-center'
									style={{
										padding: "24px 15px 32px 15px",
										gap: "12px",
									}}>
									<p
										className='f-w-300'
										style={{ fontSize: "11px" }}>
										Page no:
									</p>
									<Pagination
										totalRecords={data.totalRecords}
										pageNo={data.pageNo}
										pageSize={data.pageSize}
									/>
								</div>
							)}
						</div>
					</div>
				</section>
			</section>
		</section>
	);
};

export default withRouter(PatientPage);
