import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import ButtonTextIcon from "../../components/common/ui/ButtonTextIcon";
import LoaderIcon from "../../components/common/ui/LoaderIcon";
import { useState } from "react";
import { withRouter } from "react-router-dom";
import ButtonLightTextIcon from "../../components/common/ui/ButtonLightTextIcon";
import { useUI } from "../../context/ui";
import zipcodes from "zipcodes";
import states from "./State.json";
import { API_URL } from "../../opl-utilities/constants";

const Label = ({ title, required }) => (
  <p className="color-primary f-w-600" style={{ paddingBottom: "5px" }}>
    {title}
    {required && <span style={{ color: "red", marginLeft: "4px" }}>*</span>}
  </p>
);

const LikeInput = ({ title, ...props }) => (
  <div className="ui-like-input col-1-1 cursor-not-allowed" {...props}>
    {title ? title : " "}
  </div>
);

const AddForm = ({ setShow ,callback}) => {
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const { addToastMessage } = useUI();

  const initialValues = {
    zip: "",
    city: "",
    state: "",
    stateCode: "",
  };

  const validationSchema = Yup.object().shape({
      zip: Yup.string().matches("^[0-9]{5}(?:-[0-9]{4})?$","Invalid Zip").required("Zip code is required."),
  });

  const handleSearch = (zip, setFieldValue) => {
    setLoadingSearch(true);
    const zipData = zipcodes.lookup(zip);
    if (zipData) {
      setFieldValue("city", zipData.city);
      setFieldValue("stateCode", zipData.state);
      setFieldValue("state", states[zipData.state] || "-");
    } else {
      setFieldValue("city", "");
      setFieldValue("state", "");
      setFieldValue("stateCode", "");
      addToastMessage({ status: false, message: "Invalid Zip code." });
    }
    setLoadingSearch(false);
  };

  const handleSubmit = async (values) => {
    setLoadingSubmit(true);
    try {
      const response = await fetch(`${API_URL}/api/1.0.0/zip`, {
        method: "POST",
        headers: {
          accept: "*/*",
          "content-type": "application/json",
          "x-jwt-assertion": localStorage.getItem("token"),
        },
        body: JSON.stringify({
          stateCode: values.stateCode,
          cityName: values.city,
          zipCode: parseInt(values.zip),
        }),
      });

      const data = await response.json();
      if (response.ok) {
          addToastMessage({ status: true, message: "Zip Code Added Successfully!" });
        setShow(false);
        callback();
      } else {
        addToastMessage({ status: false, message: data.responseDescription || "Something went wrong while unarchiving user. Please try again." });
      }
    } catch (error) {
      addToastMessage({ status: false, message: "Something went wrong while unarchiving user. Please try again." });
    }
    setLoadingSubmit(false);
  };

  return (
    <section id="opl_coremax">
      <section id="custom-modal">
        <div className="inner">
          <div className="box" id="activity-detail-box">
            <div className="flex center-left mb-3">
              <div>
                <h6 className="color-primary f-w-600">Zip Add</h6>
              </div>
              <div style={{ marginLeft: "auto" }}>
                <ButtonLightTextIcon
                  onClick={() => {
                    setShow(false);
                  }}
                  icon={
                    <svg
                      clipRule="evenodd"
                      fillRule="evenodd"
                      strokeLinejoin="round"
                      strokeMiterlimit="2"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      width="19.995"
                      height="19.996"
                    >
                      <path
                        d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 1.5c-4.69 0-8.497 3.807-8.497 8.497s3.807 8.498 8.497 8.498 8.498-3.808 8.498-8.498-3.808-8.497-8.498-8.497zm0 7.425 2.717-2.718c.146-.146.339-.219.531-.219.404 0 .75.325.75.75 0 .193-.073.384-.219.531l-2.717 2.717 2.727 2.728c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.384-.073-.53-.219l-2.729-2.728-2.728 2.728c-.146.146-.338.219-.53.219-.401 0-.751-.323-.751-.75 0-.192.073-.384.22-.531l2.728-2.728-2.722-2.722c-.146-.147-.219-.338-.219-.531 0-.425.346-.749.75-.749.192 0 .385.073.531.219z"
                        fillRule="nonzero"
                      />
                    </svg>
                  }
                />
              </div>
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, setFieldValue, validateForm }) => (
                <Form>
                  <div className="col-1-1">
                    <table className="w-100">
                      <tbody>
                        <tr>
                          <td
                            style={{
                              padding: "5px",
                            }}
                          >
                            <Label title="Zip Code" required={true} />
                            <Field
                              type="text"
                              placeholder="Eg. 12345"
                              name="zip"
                              className={`col-1-1 primary ${
                                errors.zip && touched.zip ? "error" : ""
                              }`}
                              onChange={async (e) => {
                                const value = e.target.value;
                                await validateForm();
                                setFieldValue("zip", value);
                                setFieldValue("city", "");
                                setFieldValue("state", "");
                                setFieldValue("stateCode", "");
                              }}
                            />
                          </td>
                          <td
                            style={{
                              padding: "5px",
                            }}
                          >
                            <Label title={`\u00A0`} />
                            <ButtonTextIcon
                              type="button"
                              title={loadingSearch ? <LoaderIcon /> : "Search"}
                              style={{ width: "100%", height: "100%" }}
                              disabled={
                                !values.zip || loadingSearch || errors.zip
                              }
                              onClick={() =>
                                handleSearch(values.zip, setFieldValue)
                              }
                              icon={
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                >
                                  <g fill="none" fill-rule="evenodd">
                                    <path d="m12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.018-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                                    <path
                                      fill="currentColor"
                                      d="M10.5 2a8.5 8.5 0 1 0 5.262 15.176l3.652 3.652a1 1 0 0 0 1.414-1.414l-3.652-3.652A8.5 8.5 0 0 0 10.5 2M4 10.5a6.5 6.5 0 1 1 13 0a6.5 6.5 0 0 1-13 0"
                                    />
                                  </g>
                                </svg>
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td
                            width={"50%"}
                            style={{
                              padding: "5px",
                            }}
                          >
                            <Label title="City" />
                            <LikeInput title={values.city || "-"} />
                          </td>
                          <td
                            style={{
                              padding: "5px",
                            }}
                          >
                            <Label title="State" />
                            <LikeInput title={values.state || "-"} />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <br />
                  <div className="flex space-between">
                    <ButtonLightTextIcon
                      type="button"
                      className="light"
                      title="Cancel"
                      onClick={() => setShow(false)}
                      icon={
                        <svg
                          clipRule="evenodd"
                          fillRule="evenodd"
                          strokeLinejoin="round"
                          strokeMiterlimit="2"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                          width="19.995"
                          height="19.996"
                        >
                          <path
                            d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 1.5c-4.69 0-8.497 3.807-8.497 8.497s3.807 8.498 8.497 8.498 8.498-3.808 8.498-8.498-3.808-8.497-8.498-8.497zm0 7.425 2.717-2.718c.146-.146.339-.219.531-.219.404 0 .75.325.75.75 0 .193-.073.384-.219.531l-2.717 2.717 2.727 2.728c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.384-.073-.53-.219l-2.729-2.728-2.728 2.728c-.146.146-.338.219-.53.219-.401 0-.751-.323-.751-.75 0-.192.073-.384.22-.531l2.728-2.728-2.722-2.722c-.146-.147-.219-.338-.219-.531 0-.425.346-.749.75-.749.192 0 .385.073.531.219z"
                            fillRule="nonzero"
                          />
                        </svg>
                      }
                    />
                    <ButtonTextIcon
                      type="submit"
                      disabled={
                        !values.zip ||
                        !values.city ||
                        !values.state ||
                        loadingSubmit
                      }
                      title={"Add Zip"}
                      className="mar-l-a"
                      icon={
                        loadingSubmit ? (
                          <LoaderIcon />
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="19.995"
                            height="19.996"
                            viewBox="0 0 19.995 19.996"
                          >
                            <path
                              d="M12.012,2a10,10,0,1,0,10,10,10,10,0,0,0-10-10Zm0,1.5a8.5,8.5,0,1,1-8.5,8.5,8.5,8.5,0,0,1,8.5-8.5ZM13.54,8.21s1.5,1.505,3.255,3.259a.752.752,0,0,1,0,1.061c-1.753,1.754-3.254,3.258-3.254,3.258a.742.742,0,0,1-.527.217.752.752,0,0,1-.534-1.278l1.978-1.977H7.764a.75.75,0,0,1,0-1.5h6.694L12.479,9.271a.746.746,0,0,1,.006-1.054.754.754,0,0,1,.531-.222.738.738,0,0,1,.524.215Z"
                              transform="translate(-2.014 -1.995)"
                            />
                          </svg>
                        )
                      }
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </section>
    </section>
  );
};

export default withRouter(AddForm);
