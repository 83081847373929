import "../styles/base.scss";
import "../styles/pages.scss";
import "../styles/animations.scss";
import { withRouter } from "react-router-dom";
import Pagination from "../components/common/Pagination";
import {
  decodeModuleAccessFromToken,
  formatRateUSD,
  jsonToQueryParams,
} from "../opl-utilities/helpers";
import { useEffect, useState } from "react";
import { useUI } from "../context/ui";
import Header from "../components/common/ui/Header"; 
import LoaderIcon from "../components/common/ui/LoaderIcon";
import TableFilter from "./invoicing/TableFilter"; 
import ButtonTertiary from "../components/common/ui/ButtonTertiary"; 
import { API_URL } from "../opl-utilities/constants";
import ConfirmationModal from "../components/common/ui/ConfirmationModal";
import CustomModal from "../components/common/CustomModal";
import InvoiceDetailModal from "./invoicing/InvoiceDetailModal";
import FullScreenFileViewerModal from "../components/common/ui/FullScreenFileViewerModal";
import InvoiceHistory from "./invoicing/InvoiceHistory";
import InvoiceStatus from "./invoicing/InvoiceStatus";
 
const InvoicingPage = ({ history, location, activePage }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);
  const queryParams = new URLSearchParams(location.search);
  const pageSize = queryParams.get("pageSize") || 12;
  const pageNo = queryParams.get("pageNo") || 0;
  const { addToastMessage } = useUI(); 

  const updateQueryParam = (key, value) => {
    value ? queryParams.set(key, value) : queryParams.delete(key);
    history.push({ search: queryParams.toString() });
  };

  // Decode permissions for the "invoicing" module
  const permissions = decodeModuleAccessFromToken("invoicing");

  // Redirect to login if no permissions
  useEffect(() => {
    if (!permissions) {
      history.push("/login");
    }
  }, [permissions, history]);

  // ====== FILTER QUERY PARAMS
  const month = queryParams.get("month") || "";
  const year = queryParams.get("year") || ""; 
  const facility = queryParams.get("facility") || null;
  const patient = queryParams.get("patient") || null; 

  // ====== RESET FILTER
  const resetFilters = () => {
    updateQueryParam("month", "");
    updateQueryParam("year", ""); 
    updateQueryParam("facility", "");
    updateQueryParam("patient", ""); 
    updateQueryParam("pageNo", 0);
  };

  const getListApi = async (s) => {
      setLoading(true);
      try { 
        const query = jsonToQueryParams({
          month: month ? JSON.parse(month)?.value : null,
          year: year ? JSON.parse(year)?.value : null,
          facilityId: facility ? JSON.parse(facility)?.value : null,
          patientId: patient ? JSON.parse(patient)?.value : null, 
        }); 
        const resp = await fetch(
          API_URL + `/api/1.0.0/invoice?pageSize=${pageSize}&pageNo=${pageNo}&${query}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "X-JWT-Assertion": localStorage.getItem("token")
            },
          }
        ); 
        if (resp.status === 401 || resp.status === 403) {
          if (resp.status === 401) {
            localStorage.clear();
            window.location.href = "/login";
          } else if (resp.status === 403) {
            addToastMessage({
              status: false, 
              message: "Access denied. You do not have permission to perform this action.",
            });
            setLoading(false);
          }
          return false; 
        }
        if (resp.ok) {
          const response = await resp.json();
          if (response) {
            setData(response.data); 
          }
        }
      } catch (e) {
        console.log("Error:", e);
      } finally {
        setLoading(false);
      }
    };
   
    useEffect(() => {
      getListApi();
    }, [location.search]);


  const handleRegenerate = async (id) => {
      try {
          if (!id) { return false; }
          const response = await fetch(
              API_URL + `/api/1.0.0/invoice/regenerate/${id}`,
              {
                  method: "POST",
                  headers: {
                      "Content-Type": "application/json",
                      "X-JWT-Assertion": localStorage.getItem("token"),
                  },
              }
        );
        if (response.status === 401 || response.status === 403) {
          if (response.status === 401) {
            localStorage.clear();
            window.location.href = "/login";
          } else if (response.status === 403) {
            addToastMessage({
              status: false, 
              message: "Access denied. You do not have permission to perform this action.",
            });
          }
          return false; 
        }
          if (response.status === 200) {
              getListApi();
              addToastMessage({
                  status: true,
                  message: `Invoice regenerate successfully.`,
              });
              return true;
          }

          addToastMessage({
              status: false,
              message: `Something went wrong while regenerate the invoice. Please try again.`,
          });

          return false;
      } catch (error) {
          addToastMessage({
              status: false,
              message: `Something went wrong while regenerate the invoice. Please try again.`,
          });
          return false;
      }
  };

  return (
    <section id="opl_coremax">
      <section className="main-section">
        <Header active={activePage} location={location} />
        <section id="patient">
          <div className="col-1-1 flex center-left pad-0-10 main-section-top-row">
            <h5 className="page-active color-primary f-w-700">Invoicing</h5>
          </div>
          <TableFilter
            resetFilters={() => {
              resetFilters();
            }}
          />
          <div className="flex center-left">
            <div>
              {data && !loading && (
                <div
                  className="flex center-left"
                  style={{
                    padding: "10px 15px 10px 15px",
                    gap: "12px",
                  }}
                >
                  <p className="f-w-300" style={{ fontSize: "11px" }}>
                    Page no:
                  </p>
                  <Pagination
                    totalRecords={data.totalRecords}
                    pageNo={data.pageNo}
                    pageSize={data.pageSize}
                  />
                </div>
              )}
            </div>
          </div>
          {loading ? (
            <section
              style={{
                height: "100vh",
                paddingBottom: "20vh",
                backgroundColor: "#fafaff",
              }}
              className="flex center-center"
            >
              <LoaderIcon style={{ height: "32px", width: "32px" }} />
            </section>
          ) : data?.list.length ? (
            <div className="rs-table-holder">
              <table className="rs-table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Invoice ID</th> 
                    <th>Facility</th> 
                    <th>Amount</th> 
                    <th>No. of Bills</th> 
                    <th>Invoice Status</th>
                      
                    <th style={{ textAlign: "center" }}>
                      <div data-tooltip="Actions available: View, Edit, or Delete the record.">
                        Action
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data &&
                    data.list.map((data, index) => (
                      <tr key={index}>
                        <td>{pageNo * pageSize + index + 1}</td>
                        <td>{data.invoiceNo || "NA" }</td> 
                        <td>{data?.facilityName || "NA"}</td>
                        <td>{data?.totalAmount  ? `${formatRateUSD(data?.totalAmount)}` : "NA"}</td>
                        <td>{data?.numberOfBills || "NA"}</td>
                        <td>{
                      data.invoiceSentStatus === 1 ? "Ready" : data.invoiceSentStatus === 2 ? "Send to Facility" : data.invoiceSentStatus === 3 ? "Update Required":data.invoiceSentStatus === 4 ? "Payment Received" : "NA"
                    }
                    </td>
                        <td>
                          <div
                            className="flex center-center"
                            style={{ gap: "12px" }}
                          > 
                            <div>
                                <CustomModal
                                  selector={
                                    <ButtonTertiary
                                    actionType="view"
                                    title="View"
                                  /> 
                                  }
                                  content={
                                    <InvoiceDetailModal 
                                      data={data}
                                    />
                                  }
                                />
                              </div>
                            <div>
                              <CustomModal
                                selector={
                                  <ButtonTertiary
                                    actionType="download"
                                    title="Download Invoice" 
                                  />
                                }
                                content={
                                  <FullScreenFileViewerModal
                                    fileUrl={data.invoicePath}
                                    title={`INVOICE NO-(${data.invoiceNo})`}
                                  />
                                }
                              />
                            </div>
                            <a
                              href={data.excelPath}
                              download
                              target="_blank"
                              rel="noreferrer" 
                            >
                              <ButtonTertiary
                                actionType="download"
                                title="Download Excel" 
                                style={{ background: "#03002e" }}
                              />
                              </a>
                            <div>
                                <CustomModal
                                  selector={
                                    <ButtonTertiary
                                    actionType="view"
                                    title="Invoice Status"
                                    disabled={data.invoiceSentStatus === 4 ? true : false}
                                  /> 
                                  }
                                  content={
                                    <InvoiceStatus
                                      callback={()=>getListApi()}
                                      id={data.id}
                                      invoiceStatus={data.invoiceSentStatus}
                                    />
                                  }
                                />
                            </div>
                            <div>
                                <CustomModal
                                  selector={
                                    <ButtonTertiary
                                    actionType="view"
                                    title="History"
                                  /> 
                                  }
                                  content={
                                    <InvoiceHistory
                                      id={data.id}
                                    />
                                  }
                                />
                              </div>
                            <div>
													{permissions?.isUpdateAllowed &&
                             ( <CustomModal
                                selector={
                                  <ButtonTertiary
                                    actionType="reload"
                                    title="Regenerate"
                                    disabled={
                                      data.requiredRegenerate === 1 ? false : true
                                    }
                                  />
                                }
                                content={
                                  <ConfirmationModal
                                    onConfirm={async () => {
                                      await handleRegenerate(data.id);
                                      return true;
                                    }}
                                    message="Are you sure you want to regenerate the invoice?"
                                  />
                                }
                                />
                              )}  
                            </div>
                          </div>
                        </td> 
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          ) : (
            <section
              style={{
                height: "80vh",
                paddingBottom: "20vh",
                backgroundColor: "#fff",
              }}
              className="flex center-center"
            >
              <p>No records found.</p>
            </section>
          )}
 
          <div>
            <div>
              {data && !loading && (
                <div
                  className="flex center-center"
                  style={{
                    padding: "24px 15px 32px 15px",
                    gap: "12px",
                  }}
                >
                  <p className="f-w-300" style={{ fontSize: "11px" }}>
                    Page no:
                  </p>
                  <Pagination
                    totalRecords={data.totalRecords}
                    pageNo={data.pageNo}
                    pageSize={data.pageSize}
                  />
                </div>
              )}
            </div>
          </div>
        </section>
      </section> 
    </section>
  );
};

export default withRouter(InvoicingPage);
