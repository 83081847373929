import { useState } from "react";
import CustomModal from "../../components/common/CustomModal";
import PopUpModal from "../../components/common/PopUpModal";
import ButtonLightTextIcon from "../../components/common/ui/ButtonLightTextIcon";
import { formatRateUSD, formatDate } from "../../opl-utilities/helpers";
import FullScreenBillDetailsModal from "../bill/FullScreenBillDetailsModal";

export default function BillCard({ bill }) {
    const [show, setShow] = useState(false);
    return (
      <>
        {!show ? (
          <div
            style={{
              border: "1px solid #ddd",
              borderRadius: "8px",
              padding: "16px",
              marginBottom: "16px",
            }}
          >
            <div>
              <div
                className="flex center-center"
                style={{
                  fontSize: "1.125rem",
                  justifyContent: "space-between",
                }}
              >
                <span>Bill #{bill?.id}</span>
                <span
                  style={{
                    fontSize: "1rem",
                    fontWeight: "normal",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {bill?.amount ? formatRateUSD(bill?.amount) : "NA"}
                </span>
              </div>
            </div>
            <div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "16px",
                  paddingTop: "16px",
                }}
              >
                <div>
                  <div style={{ fontWeight: "bold" }}>{`${
                    bill?.activity?.patient?.firstName || "NA"
                  } ${bill?.activity?.patient?.lastName || ""}`}</div>
                  <div
                    className="color-light flex "
                    style={{
                      fontSize: "0.875rem",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <span>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        style={{
                          height: "15px",
                          width: "15px",
                        }}
                      >
                        <line x1="10" y1="3" x2="8" y2="21"></line>
                        <line x1="16" y1="3" x2="14" y2="21"></line>

                        <line x1="3" y1="8" x2="21" y2="6"></line>
                        <line x1="3" y1="16" x2="21" y2="14"></line>
                      </svg>
                    </span>
                    {bill?.activity?.patient?.id || "NA"}
                  </div>
                  <div
                    className="color-light flex "
                    style={{
                      fontSize: "0.875rem",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        dataId="71"
                        style={{
                          height: "15px",
                          width: "15px",
                        }}
                      >
                        <path d="M8 2v4"></path>
                        <path d="M16 2v4"></path>
                        <rect width="18" height="18" x="3" y="4" rx="2"></rect>
                        <path d="M3 10h18"></path>
                      </svg>
                    </span>
                    Born:{" "}
                    {bill?.activity?.patient?.dateOfBirth
                      ? formatDate(bill?.activity?.patient?.dateOfBirth)
                      : "NA"}
                  </div>
                </div>
                <div>
                  <div
                    className="color-light flex "
                    style={{
                      fontSize: "0.875rem",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <span>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        style={{
                          height: "15px",
                          width: "15px",
                        }}
                      >
                        <line x1="10" y1="3" x2="8" y2="21"></line>
                        <line x1="16" y1="3" x2="14" y2="21"></line>

                        <line x1="3" y1="8" x2="21" y2="6"></line>
                        <line x1="3" y1="16" x2="21" y2="14"></line>
                      </svg>
                    </span>
                    Activity: {bill?.activity?.id}
                  </div>
                  <div
                    className="color-light flex "
                    style={{
                      fontSize: "0.875rem",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        dataId="71"
                        style={{
                          height: "15px",
                          width: "15px",
                        }}
                      >
                        <path d="M8 2v4"></path>
                        <path d="M16 2v4"></path>
                        <rect width="18" height="18" x="3" y="4" rx="2"></rect>
                        <path d="M3 10h18"></path>
                      </svg>
                    </span>
                    DOS:{" "}
                    {bill?.activity?.patient?.serviceDate
                      ? formatDate(bill?.activity?.patient?.serviceDate)
                      : "NA"}
                  </div>
                </div>
              </div>
              <div className="flex center-right">
                <ButtonLightTextIcon
                  title={"Bill Detail"}
                  icon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="22"
                      viewBox="0 0 24 22"
                      style={{ height: "13px", width: "13px" }}
                    >
                      <path
                        d="M21,13V23H0V4H12V6H2V21H19V13ZM24,1H13.012l4.035,4L10.07,12.07,12.9,14.9l6.977-7.07L24,12Z"
                        transform="translate(0 -1)"
                      />
                    </svg>
                  }
                  onClick={() => setShow(true)}
                />
              </div>
            </div>
          </div>
        ) : (
          <FullScreenBillDetailsModal
            title="Bill Detail"
            id={bill.id}
            setShow={setShow}
          />
        )}
      </>
    );
  }
  