import { formatISO } from "date-fns";
import { jwtDecode } from "jwt-decode";
import { LOCAL_STORAGE_KEYS } from "../javascript/constants/common";
import { PBFUSA_EMAIL_ARRAY, ROLES_LIST, SCANFLOW_STATUS_LIST, USER_TYPE_OPTIONS } from "./constants";

export const formatYYYYMMDD = (str) => {
	const months = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec",
	];
	const arr = str.split("-");
	return arr[2] + " " + months[parseInt(arr[1] - 1)] + " " + arr[0];
};

export const formatInputPhoneNumber = (value) => {
	if (!value) return value;

	const onlyNums = value.replace(/[^\d]/g, "");
	
	if (onlyNums.length <= 3) {
		return onlyNums;
	} else if (onlyNums.length <= 6) {
		return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`;
	} else {
		return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}-${onlyNums.slice(6)}`;
	}
};



export function formatMMDDYYYY(dateStr) {
    const regex = /^\d{4}-\d{2}-\d{2}$/; // Validate 'yyyy-mm-dd' format
    if (!regex.test(dateStr) || isNaN(new Date(dateStr).getTime())) {
        throw new Error('Invalid date format. Please use yyyy-mm-dd.');
    }
    const [year, month, day] = dateStr.split('-');
    return `${month}-${day}-${year}`;
}

export const formatServerDate = (str) => {
    // const months = [
    //     "Jan", "Feb", "Mar", "Apr", "May", "Jun", 
    //     "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    // ];
	const months = [
        "01", "02", "03", "04", "05", "06", 
        "07", "08", "09", "10", "11", "12"
    ];

    // const date = new Date(str);
	const date = convertGMTToLocal(str);
    // const day = date.getDate(); 
	const day = String(date.getDate()).padStart(2, '0');
    const month = months[date.getMonth()]; 
    const year = date.getFullYear(); 

    return month + "/" + day + "/" + year;
};

export const formatDate = (str) => {
    try {
        if (!str || typeof str !== "string") {
            return null; // Return null for invalid input
        }

        const parts = str.trim().split("-");
        if (parts.length !== 3) {
            return null; // Return null if format is incorrect
        }

        const [year, month, day] = parts;

        // Validate that year, month, and day are numeric and within valid ranges
        if (
            isNaN(year) || isNaN(month) || isNaN(day) ||
            year.length !== 4 || month.length !== 2 || day.length !== 2 ||
            parseInt(month, 10) < 1 || parseInt(month, 10) > 12 ||
            parseInt(day, 10) < 1 || parseInt(day, 10) > 31
        ) {
            return null; // Return null for invalid values
        }

        return `${month}/${day}/${year}`;
    } catch {
        return null; // Return null for any unexpected errors
    }
};


export function formatDateTime(dateString) { 
	const date = convertGMTToLocal(dateString);
	const hours = String(date.getHours()).padStart(2, "0");
	const minutes = String(date.getMinutes()).padStart(2, "0");
	const seconds = String(date.getSeconds()).padStart(2, "0");
	const formattedDate = `${formatServerDate(
		dateString
	)} ${hours}:${minutes}:${seconds}`;
	return formattedDate;
}

export const sleep = (m) => new Promise((r) => setTimeout(r, m));

export const formatAmountValue = (value) => {
	if (isNaN(value)) return value;
	return parseFloat(value).toLocaleString("en-US", {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});
};

export function getScanflowStatusById(id = 0) {
	const status = SCANFLOW_STATUS_LIST.find((d) => d.id == id);
	return status
		? status
		: {
				id: "0",
				name: "Undefined",
				class: "not-started",
		  };
}

export const formatTime = (totalSeconds) => {
	const hours = Math.floor(totalSeconds / 3600)
		.toString()
		.padStart(2, "0");
	const minutes = Math.floor((totalSeconds % 3600) / 60)
		.toString()
		.padStart(2, "0");
	const seconds = (totalSeconds % 60).toString().padStart(2, "0");
	return `${hours}:${minutes}:${seconds}`;
};

export const getLogInUserDetails = (type) => {
	const LOGGED_IN_USER = localStorage.getItem(
		LOCAL_STORAGE_KEYS.LOGGED_IN_USER
	);
	if (!LOGGED_IN_USER) return "";
	const user = JSON.parse(LOGGED_IN_USER);
	const detailsMap = {
		type: user.type,
		id: user.id,
		fullname: user.fullname,
	};
	return detailsMap[type] || "";
};

export const isManager = (roleName) => {
	const role = ROLES_LIST.find((role) => role.name === roleName);
	return role && role.type === "manager";
};

export const isInDepartment = (roleName, department) => {
	const role = ROLES_LIST.find((role) => role.name === roleName);
	return role && role.department === department;
};

export const getUserTypeInfo = (type) => {
	return USER_TYPE_OPTIONS.find((userType) => userType.value === type) || null;
}

export const getRoleInfo = (roleName) => {
	return ROLES_LIST.find((role) => role.name === roleName) || null;
};

export const getScanfloeStatusInfo = (key, value) => {
	return SCANFLOW_STATUS_LIST.find((status) => status[key] === value) || null;
};

export const convertGMTToLocal = (gmtInput) => {
	const gmtTime = new Date(gmtInput);
	
	return gmtTime;
	// if (isNaN(gmtTime.getTime())) {
	// 	console.error("Invalid GMT input");
	// 	return null;
	// }
	// const timezoneOffsetInMinutes = new Date().getTimezoneOffset();
	// const localTime = new Date(
	// 	gmtTime.getTime() - timezoneOffsetInMinutes * 60000
	// );
	// return localTime;
};

  export const houstonDateTime = () => {
	const gmtTime = new Date();
   
	const houstonTime = new Intl.DateTimeFormat('en-US', {
	  timeZone: 'America/Chicago',
	  year: 'numeric',
	  month: '2-digit',
	  day: '2-digit',
	  hour: '2-digit',
	  minute: '2-digit',
	  second: '2-digit',
	  hour12: true, 
	}).format(gmtTime);
  
	return houstonTime;
  };

/**
 * Decodes user permissions for a given module from a JWT token.
 *
 * @param {string} module - Module name (e.g., "insurance").
 * @returns {null|object} - Returns `null` if the token or module is invalid, 
 * or an object with permissions:
 * {
 *   isCreateAllowed: boolean, // Create permission
 *   isReadAllowed: boolean,   // Read permission
 *   isUpdateAllowed: boolean, // Update permission
 *   isDeleteAllowed: boolean  // Delete permission
 * }
 */


export const decodeModuleAccessFromToken = (module) => { 
  const token = localStorage.getItem("token");
   
  if (!token) {
    console.warn("Token not found in localStorage.");
    return null;
  }

  try { 
    const decodedToken = jwtDecode(token); 
    const modules = decodedToken.modules;
 
    if (!modules || !modules.hasOwnProperty(module)) {
      console.warn(`Module "${module}" not found in token.`); 
	    return {
			isCreateAllowed: false,  
			isReadAllowed: false,   
			isUpdateAllowed: false,  
			isDeleteAllowed: false   
	    };
    }
 
    const crudValue = modules[module];
     
    if (typeof crudValue !== "string" || crudValue.length < 4) {
      console.error(`Invalid CRUD value for module "${module}".`); 
		return {
			isCreateAllowed: false,  
			isReadAllowed: false,   
			isUpdateAllowed: false,  
			isDeleteAllowed: false   
		};
    }
 
	if(crudValue === "0000"){
		console.error(`Invalid CRUD value for module "${module}".`);
		return {
			isCreateAllowed: false,  
			isReadAllowed: false,   
			isUpdateAllowed: false,  
			isDeleteAllowed: false   
		};
	}

    return {
      isCreateAllowed: crudValue[0] === '1',  
      isReadAllowed: crudValue[1] === '1',   
      isUpdateAllowed: crudValue[2] === '1',  
      isDeleteAllowed: crudValue[3] === '1'   
    };
  } catch (error) { 
    console.error("Error decoding token:", error.message);
    return null;
  }

};

export const getDepartmentId = (department) => {
	const token = localStorage.getItem("token");
  
	if (!token) {
	  console.warn("Token not found in localStorage.");
	  return null;
	}
  
	try {
	  const decodedToken = jwtDecode(token);  
		return decodedToken.department || null;  
	} catch (error) {
	  console.error("Error decoding token:", error.message);
	  return null;
	}
  };

  export const formatAndValidateDate = (inputDate) => { 
	
	const sanitizedDate = inputDate.replace(/[^0-9]/g, '');
   
	if (sanitizedDate.length !== 8) { 
	  return "";
	}
   
	const month = sanitizedDate.slice(0, 2);
	const day = sanitizedDate.slice(2, 4);
	const year = sanitizedDate.slice(4);
   
	const date = new Date(`${year}-${month}-${day}`);
	 
	if (date.getMonth() + 1 !== parseInt(month) || date.getDate() !== parseInt(day) || date.getFullYear() !== parseInt(year)) {
	  return "";
	}
   
	return `${year}-${month}-${day}`;
  }
     
  
	export const handleDatePickerChange = (setValue, key, date, e) => {  
		if (e?.target?.value !== undefined) {  
			if (e?.target?.value) {  
				setValue(key, formatAndValidateDate(e.target.value));
			} else { 
				setValue(key, "");
			} 
		} else if (date) {  
			setValue(key, formatISO(date, { representation: "date" }));
		} else { 
			setValue(key, "");
		}
	};

	export const getPBFUSAaccess = () => {
		const token = localStorage.getItem("token");
	  
		if (!token) {
		  return false;
		}
	  
		try {
		  const decodedToken = jwtDecode(token);
	  
		  const { userName } = decodedToken;
	  
		  if (PBFUSA_EMAIL_ARRAY.includes(userName)) {
			return true;
		  } else {
			return false;
		  }
		} catch (error) {
		  console.error("Failed to decode token:", error);
		  return false;
		}
	  };
	  
	  export const formatRateUSD = (value, currency = "USD") => {
		if (value == null || isNaN(value)) {
			return "NA"; 		// Check if value is a valid number and not null/undefined

		}
		const formattedValue = Number(value).toFixed(2);
	
		return `${formattedValue} ${currency}`;
	};

	export const jsonToQueryParams = (json) => {
		const queryParams = Object.keys(json)
			.filter((key) => json[key] !== null && json[key] !== "")
			.map(
				(key) =>
					`${encodeURIComponent(key)}=${encodeURIComponent(json[key])}`
			)
			.join("&");
		return queryParams;
	};

	export const getRandomColor = () => {
		const array = ["#84B8A4", "#72B5E5", "#CBA5DB", "#CFA96D", "#E8823D"];
		const randomIndex = Math.floor(Math.random() * array.length);
		return array[randomIndex];
	};

	export function getRandomTenDigitNumber() {
		return Math.floor(1000000000 + Math.random() * 9000000000); // Ensures the number is 10 digits
	}