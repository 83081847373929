import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useEffect, useState } from "react";  
import { API_URL } from "../../opl-utilities/constants"; 
import LoaderIcon from "../../components/common/ui/LoaderIcon";
import { formatRateUSD, formatDate, getRandomTenDigitNumber } from "../../opl-utilities/helpers";
import { useUI } from "../../context/ui";
import { withRouter } from "react-router-dom";
import ButtonLightTextIcon from "../../components/common/ui/ButtonLightTextIcon";
import ButtonTextIcon from "../../components/common/ui/ButtonTextIcon";

const Label = ({ title, required }) => {
	return (
		<p
			className='color-primary f-w-600'
			style={{ paddingBottom: "5px" }}>
			{title}
			{required && (
				<span style={{ color: "red", marginLeft: "4px" }}>*</span>
			)}
		</p>
	);
};


const FullScreenUpdateBillFormModal = ({
  id=null, 
  title = "", 
  setShow,
}) => {
 
    const [billData, setBillData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  
    const fetchBillData = async (id) => {
      try {
        const resp = await fetch(
          API_URL + `/api/1.0.0/bill/${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "X-JWT-Assertion": localStorage.getItem("token"),
          },
        });
        if (resp.status === 401 || resp.status === 403) {
          if (resp.status === 401) {
            localStorage.clear();
            window.location.href = "/login";
          } else if (resp.status === 403) {
            setLoading(false);
            setError("Access denied. You do not have permission to perform this action.");
          }
          return false;
        }
        if (resp.ok) {
          const response = await resp.json();
          if (response) {
            setBillData(response.data);
            setLoading(false);
          }else{
            setError("Failed to fetch bill data.");
          }
        }else{
          setError("Failed to fetch bill data.");
        }
      } catch (e) {
        setError("Failed to fetch bill data.");
        setLoading(false);
        console.log("Error:", e);
      } finally {
        setLoading(false);
      }
    };
  
    useEffect(() => {
      fetchBillData(id);
    }, [id]);

  return (
    <section id="opl_coremax">
      <section
        style={{
          position: "fixed",
          inset: 0,
          zIndex: 9999,
          backgroundColor: "rgba(255, 255, 255, 0.86)",
        }}
      >
        <section
          className="col-1-1 flex center-center"
          style={{
            height: "100vh",
            width: "100vw",
            overflowY: "auto",
          }}
        >
          <div
            style={{
              height: "100vh",
              width: "100vw",
              margin: "10px",
              padding: "15px",
              border: "solid 1px #f0f0f0",
              background: "white",
              borderRadius: "8px",
              margin: "0 auto",
              boxShadow: "0px 0px 20px rgba(0,0,0,0.08)",
            }}
          >
            <div
              className="col-1-1 flex center-left"
              style={{
                borderBottom: "solid 1px #f0f0f0",
                paddingBottom: "12px",
                marginBottom: "16px",
                gap: "10px",
              }}
            >
              <h6 className="color-primary f-w-700 t-t-u l-s-n">
                {title || ""}
              </h6>
              <div
                className="mar-l-a cursor-pointer "
                onClick={() => setShow(false)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z"
                    fill="#283f54"
                  />
                </svg>
              </div>
            </div>
            <section id="add-activity">
              <div className="col-1-1">
                {!error && !loading && billData && <MedicalBill  setShow={setShow} data={billData} />}

                {!error && loading && !billData && (
                  <p
                    style={{
                      height: "100vh",
                      paddingBottom: "20vh",
                      backgroundColor: "#fafaff",
                    }}
                    className="flex center-center"
                  >
                    <LoaderIcon
                      style={{
                        height: "32px",
                        width: "32px",
                      }}
                    />
                  </p>
                )}
                {error && !loading && !billData && (
                  <p
                    style={{
                      height: "100vh",
                      paddingBottom: "20vh",
                      backgroundColor: "#fafaff",
                    }}
                    className="flex center-center"
                  >
                    {error || "Error"}
                  </p>
                )}
              </div>
            </section>
          </div>
        </section>
      </section>
    </section>
  );
};

export default FullScreenUpdateBillFormModal;

 

const MedicalBill = withRouter(({history, location, data, setShow}) =>  { 
   const [loading , setLoading] = useState(false);
   const [status , setStatus] = useState("");
   const [initialValues,setInitialValues] = useState({
     amount: 0.00,
     comment: ""
   });
  const [ammount, setAmmount] = useState(0.00);
  const {addToastMessage} = useUI();
  const queryParams = new URLSearchParams(location.search);
  const updateQueryParam = (key, value) => {
      value ? queryParams.set(key, value) : queryParams.delete(key);
      history.push({ search: queryParams.toString() });
  };

   const validationSchema = Yup.object({
      amount: Yup.number()
        .typeError("Amount must be a number")
        .test("is-positive", "Amount must be greater than 0", (value) => value > 0)
        .test(
          "two-decimals",
          "Amount must have at most two decimal places",
          (value) => /^\d+(\.\d{1,2})?$/.test(value)
        )
       .required("Amount is required"),
     comment: Yup.string().max(255, "Comment must be less than 255 characters").required("Comment is required"),
    });


    const updateBill = async (values) => {
      setLoading(true);
      setStatus("");
      try {

        const payload = JSON.stringify({
          charge: parseFloat(values.amount).toFixed(2),
          comment: values.comment,
        });
    
        const resp = await fetch(
          API_URL + `/api/1.0.0/bill/${data.id}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "X-JWT-Assertion": localStorage.getItem("token")
          },
          body: payload,
        });
        if (resp.status === 401 || resp.status === 403) {
          if (resp.status === 401) {
            localStorage.clear();
            window.location.href = "/login";
          } else if (resp.status === 403) {
            addToastMessage({
              status: false,
              message:
                "Access denied. You do not have permission to perform this action.",
            });
            setLoading(false);
            setStatus(
              "Access denied. You do not have permission to perform this action."
            )
          }
          return false;
        }

        if (resp.ok) {
          const response = await resp.json();
          if (response) {
            return response.result.responseCode === 200 ? true : false;
          } else {
            const response = await resp.json();
            if (response) {
              setStatus(`${response.responseDescription || "Something went wrong, please try again later."}`);
              addToastMessage({
                status: false,
                message: `${response.responseDescription || "Something went wrong, please try again later."}`,
              });
              return false;
            }
            setStatus("Something went wrong, please try again later.");
            addToastMessage({
              status: false,
              message: `Something went wrong, please try again later.`,
            });
            return false;
          }
        } else {
          const response = await resp.json();
          if (response) {
            setStatus(`${response.responseDescription || "Something went wrong, please try again later."}`);
            addToastMessage({
              status: false,
              message: `${response.responseDescription || "Something went wrong, please try again later."}`,
            });
            return false;
          }
          setStatus("Something went wrong, please try again later.");
          addToastMessage({
            status: false,
            message: `Something went wrong, please try again later.`,
          });
          return false;
        }
      } catch (e) {
        setStatus("Something went wrong, please try again later.");
        addToastMessage({
          status: false,
          message: `Something went wrong, please try again later.`,
        });
        console.log("Error:", e);
        return false;
      } finally {
        setLoading(false);
      }
    };

   useEffect(()=>{
     if(data){
       setInitialValues({
        amount: data?.amount ? data?.amount.toFixed(2) : 0.00,
        comment: ""
       })
       setAmmount(parseInt(data?.amount).toFixed(2));
     }
   }, [data])

  return (
    <div
      style={{
        maxWidth: "700px",
        margin: "20px auto",
        padding: "20px",
        backgroundColor: "white",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        fontSize: "11px",
        border: "1px solid #ccc",
        textTransform: "uppercase",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          borderBottom: "1px solid #ccc",
          paddingBottom: "10px",
        }}
      >
        <div>
          <h1 style={{ fontSize: "18px", fontWeight: "bold", color: "#333" }}>
            Medical Bill
          </h1>
          {/* <p style={{ fontSize: "10px", color: "#666", marginTop: "4px" }}>
            Bill Id: #{data.id}
          </p> */}
        </div>
      </div>

      <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
            gap: "16px",
            marginTop: "20px",
          }}
        >
          <div>
            <h2
              style={{
                fontWeight: "600",
                fontSize: "12px",
                color: "#555",
                marginBottom: "8px",
                textTransform: "uppercase",
              }}
            >
              Patient Information
            </h2>
            <div style={{ color: "#666", lineHeight: "1.5" }}>
              {[
                { label: "Name:", value: `${data?.activity?.patient?.firstName || "NA"} ${data?.activity?.patient?.middleName || ""} ${data?.activity?.patient?.lastName || ""}` },
                { label: "DOB:", value: data?.activity?.patient?.dateOfBirth ? formatDate(data?.activity?.patient?.dateOfBirth) : "NA" },
                { label: "Chart No:", value: data?.activity?.patient?.patientChartNo || "NA" },
                { label: "Service Date:", value: data?.activity?.patient?.serviceDate ? formatDate(data?.activity?.patient?.serviceDate) : "NA" },
              ].map((item, idx) => (
                <p key={idx} style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                  <span
                    style={{
                      display: "inline-block",
                      minWidth: "100px",
                      fontWeight: "500",
                      flexShrink: 0,
                    }}
                  >
                    {item.label}
                  </span>
                  <span>{item.value}</span>
                </p>
              ))}
            </div>
          </div>
          <div>
            <h2
              style={{
                fontWeight: "600",
                fontSize: "12px",
                color: "#555",
                marginBottom: "8px",
                textTransform: "uppercase",
              }}
            >
              Activity Details
            </h2>
            <div style={{ color: "#666", lineHeight: "1.5" }}>
              {[
                { label: "Facility:", value: data?.facility?.facilityName || "NA" },
                { label: "Specialty:", value: data?.speciality?.name || "NA" },
                { label: "Procedures:", value: data?.procedures?.length ? data?.procedures.map(proc => proc.procedureName).join(", ") : "NA" },
              ].map((item, idx) => (
                <p key={idx} style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                  <span
                    style={{
                      display: "inline-block",
                      minWidth: "100px",
                      fontWeight: "500",
                      flexShrink: 0,
                    }}
                  >
                    {item.label}
                  </span>
                  <span>{item.value}</span>
                </p>
              ))}
            </div>
          </div>
        </div>

      <hr style={{ border: "0.5px solid #ccc", margin: "20px 0" }} />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={async (values, { resetForm }) => {
          setLoading(true);
          const api =  await updateBill(values);
          if (api) {
            resetForm();
            setStatus("You have successfully updated a bill, thank you.");
            addToastMessage({
              status: true,
              message: `You have successfully updated a bill, thank you.`,
            });
            setShow(false)
            updateQueryParam("r", getRandomTenDigitNumber());
          }
        }}
      >
        {({
          values,
          setFieldValue,
          setValues,
          errors,
          touched,
          handleSubmit,
          validateForm,
          setFieldTouched,
        }) => (
          <Form>
            <div>
              <h2
                style={{
                  fontWeight: "600",
                  fontSize: "12px",
                  color: "#555",
                  marginBottom: "12px",
                  textTransform: "uppercase",
                }}
              >
                Charges
              </h2>
              <table
                style={{
                  width: "100%",
                  fontSize: "11px",
                  color: "#666",
                  borderCollapse: "collapse",
                }}
              >
                <thead>
                  <tr style={{ backgroundColor: "#f9f9f9" }}>
                    <th
                      className="color-primary"
                      style={{
                        textAlign: "left",
                        padding: "8px",
                        fontWeight: "600",
                        color: "#555",
                      }}
                    >
                      Description
                    </th>
                    <th
                      className="color-primary"
                      style={{
                        textAlign: "right",
                        padding: "8px",
                        fontWeight: "600",
                        color: "#555",
                      }}
                    >
                      Amount
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.oncallCharge ? (
                    <tr>
                      <td
                        className="color-primary"
                        style={{
                          padding: "8px",
                          borderBottom: "1px solid #ddd",
                        }}
                      >
                        On-call Charge
                      </td>
                      <td
                        className="color-primary"
                        style={{
                          textAlign: "right",
                          padding: "8px",
                          borderBottom: "1px solid #ddd",
                        }}
                      >
                        <div className="flex center-rigth">
                          <div style={{ marginLeft: "auto" }}>
                            <Field
                              style={{
                                width: "170px",
                                height: "26px",
                                marginLeft: "0",
                              }}
                              type="number"
                              name="amount"
                              className="primary"
                            />
                          </div> 
                        </div>
                        <div className="flex center-rigth">
                        <div style={{ marginLeft: "auto" }}>
                            <p style={{color:"red", fontSize:"10px"}}> {errors.amount}&nbsp;</p>
                        </div>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <></>
                  )}

                  {data.procedureCharge ? (
                    <tr>
                      <td
                        className="color-primary"
                        style={{
                          padding: "8px",
                          borderBottom: "1px solid #ddd",
                        }}
                      >
                        Specialty Charge
                      </td>
                      <td
                        className="color-primary"
                        style={{
                          textAlign: "right",
                          padding: "8px",
                          borderBottom: "1px solid #ddd",
                        }}
                      >
                        <div className="flex center-rigth">
                          <div style={{ marginLeft: "auto" }}>
                            <Field
                              style={{
                                width: "170px",
                                height: "26px",
                                marginLeft: "0",
                              }}
                              type="number"
                              name="amount"
                              className="primary"
                            />
                          </div> 
                        </div>
                        <div className="flex center-rigth">
                        <div style={{ marginLeft: "auto" }}>
                            <p style={{color:"red", fontSize:"10px"}}> {errors.amount}&nbsp;</p>
                        </div>
                        </div>

                      </td>
                    </tr>
                  ) : (
                    <></>
                  )}

                  <tr style={{ fontWeight: "bold", color: "#333" }}>
                    <td className="color-primary" style={{ padding: "8px" }}>
                      Total Amount Due
                    </td>
                    <td
                      className="color-primary"
                      style={{ textAlign: "right", padding: "8px" }}
                    >
                      {formatRateUSD(values.amount)}
                    </td>
                  </tr>
                </tbody>
              </table>
              <Label title="Comments" required={true}/>
                <Field
                  className={`primary ${errors.comment && touched.comment ? "error" : ""}`}
                  style={{
                    width: "100%",
                    marginLeft: "0",
                  }}
                  as="textarea"
                  name="comment"
                  value={values.comment}
                  placeholder="Comments"
                  onChange={(e) => {
                    setFieldValue("comment", e.target.value);
                  }}
              />
              <div className="flex center-rigth">
                <div style={{ marginLeft: "auto" }}>
                  {errors.comment && touched.comment && (
                    <p style={{ color: "red", fontSize: "10px" }}>
                      {errors.comment}&nbsp;
                    </p>
                  )}
                </div>
              </div>
            </div>
            <br/>
            <div className="flex center-left">
            <ButtonTextIcon
              type="button"
              className="light"
              title="Cancel"
              onClick={() => { setShow(false)} }
              icon={
                <svg
                  clipRule="evenodd"
                  fillRule="evenodd"
                  strokeLinejoin="round"
                  strokeMiterlimit="2"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  width="19.995"
                  height="19.996"
                >
                  <path
                    d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 1.5c-4.69 0-8.497 3.807-8.497 8.497s3.807 8.498 8.497 8.498 8.498-3.808 8.498-8.498-3.808-8.497-8.498-8.497zm0 7.425 2.717-2.718c.146-.146.339-.219.531-.219.404 0 .75.325.75.75 0 .193-.073.384-.219.531l-2.717 2.717 2.727 2.728c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.384-.073-.53-.219l-2.729-2.728-2.728 2.728c-.146.146-.338.219-.53.219-.401 0-.751-.323-.751-.75 0-.192.073-.384.22-.531l2.728-2.728-2.722-2.722c-.146-.147-.219-.338-.219-.531 0-.425.346-.749.75-.749.192 0 .385.073.531.219z"
                    fillRule="nonzero"
                  />
                </svg>
              }
            />
              <div style={{marginLeft:"auto"}}>
                <ButtonTextIcon
                  title="Update Bill"
                  disabled={(parseFloat(ammount) === parseFloat(values.amount)) || loading }
                  onClick={handleSubmit}
                  icon={
                    loading ? (
                      <LoaderIcon />
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="19.995"
                        height="19.996"
                        viewBox="0 0 19.995 19.996"
                      >
                        <path
                          d="M12.012,2a10,10,0,1,0,10,10,10,10,0,0,0-10-10Zm0,1.5a8.5,8.5,0,1,1-8.5,8.5,8.5,8.5,0,0,1,8.5-8.5ZM13.54,8.21s1.5,1.505,3.255,3.259a.752.752,0,0,1,0,1.061c-1.753,1.754-3.254,3.258-3.254,3.258a.742.742,0,0,1-.527.217.752.752,0,0,1-.534-1.278l1.978-1.977H7.764a.75.75,0,0,1,0-1.5h6.694L12.479,9.271a.746.746,0,0,1,.006-1.054.754.754,0,0,1,.531-.222.738.738,0,0,1,.524.215Z"
                          transform="translate(-2.014 -1.995)"
                        />
                      </svg>
                    )
                  }
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
})
