import { useEffect, useState } from "react";
import ButtonLightTextIcon from "../../components/common/ui/ButtonLightTextIcon";
import LoaderIcon from "../../components/common/ui/LoaderIcon";
import { API_URL } from "../../opl-utilities/constants";
import ButtonTextIcon from "../../components/common/ui/ButtonTextIcon";
import { formatDateTime, formatRateUSD } from "../../opl-utilities/helpers";

const Caution = ({ title }) => (
  <p
    className="t-a-c"
    style={{
      fontSize: "12px",
      padding: "5px",
      color: "#777",
    }}
  >
    {title}
  </p>
);

const BillHistory = ({ id, setShow }) => {
  const [datacount, setDatacount] = useState(0);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [pageNo, setPageNo] = useState(0);
  const [noMoreOptions, setNoMoreOptions] = useState(false);

  const callApi = async () => {
    setLoading(true);
    try {
      const resp = await fetch(
        `${API_URL}/api/1.0.0/bill/history/${id}?pageNo=${pageNo}&pageSize=5&sortOrder=DESC`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "X-JWT-Assertion": localStorage.getItem("token"),
          },
        }
      );
      if (resp.status === 401 || resp.status === 403) {
        if (resp.status === 401) {
          localStorage.clear();
          window.location.href = "/login";
        } else if (resp.status === 403) {
          setLoading(false);
          setError(
            "Access denied. You do not have permission to perform this action."
          );
        }
        return false;
      }
      if (!resp.ok) throw new Error("Failed to fetch history");
      const { data } = await resp.json();
      if (data.totalPages - 1 === pageNo) {
        setNoMoreOptions(true);
      }
      setData((prevData) =>
        pageNo === 0 ? data.list : [...prevData, ...data.list]
      );
      setDatacount((prevDataCount) =>
        pageNo === 0 ? data.list.length : prevDataCount + data.list.length
      );
    } catch (e) {
      setError("An error occurred while retrieving Bill history.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) callApi();
  }, [id, pageNo]);

  const handleScroll = () => {
    setPageNo((prevPage) => prevPage + 1);
  };

  if (!id) return null;

  return (
    <section id="opl_coremax">
      <section id="custom-modal">
        <div className="inner">
          <div className="box" id="activity-detail-box">
            <div
              className="col-1-1 flex center-left"
              style={{
                borderBottom: "solid 1px #f0f0f0",
                paddingBottom: "12px",
                marginBottom: "16px",
              }}
            >
              <h6 className="color-primary f-w-300">Bill History</h6>
              <div
                className="mar-l-a cursor-pointer "
                onClick={() => setShow(false)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z"
                    fill="#283f54"
                  />
                </svg>
              </div>
            </div>
            <div
              style={{
                height: "492px",
                overflowY: "auto",
                background: "white",
                padding: " 0px 10px",
                alignContent: "center",
              }}
            >
              <section>
                {loading && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <LoaderIcon
                      style={{
                        width: "20px",
                        height: "20px",
                      }}
                    />
                  </div>
                )}
                {!loading && error && (
                  <p
                    className="f-w-300 color-primary"
                    style={{ color: "#c0392b" }}
                  >
                    {error}
                  </p>
                )}
                {!error &&
                  data &&
                  datacount !== 0 &&
                  data?.map(
                    (
                      {
                        amount,
                        updatedBy,
                        facilityName,
                        speciality,
                        comment,
                        updatedAt
                      },
                      i
                    ) => (
                      <div className="col-1-1" key={i}>
                        <div>
                          <div className="flex center-left">
                            <div
                              className="b-r-20"
                              style={{
                                padding: "12px 16px",
                                backgroundColor:  "#DA914E",
                              }}
                            >
                              <p
                                className="color-white f-w-400 l-h-1 t-t-u"
                                style={{
                                  fontSize: "11px",
                                }}
                              >
                                {"Updated AT: " + formatDateTime(updatedAt)}
                              </p>
                            </div>
                          </div>
                          <div
                            style={{
                              height: "16px",
                              width: "4px",
                              borderLeft: "dashed 2px rgb(196, 196, 196)",
                              marginLeft: "20px",
                            }}
                          ></div>
                          <div
                            className="b-r-5"
                            style={{
                              display: "grid",
                              gridTemplateColumns: "repeat(2, 1fr)",
                              gridTemplateRows: "repeat(auto-fill, auto)",
                              gridColmnGap: "12px",
                              gridRowGap: "12px",
                              background: "#f4fbff",
                              padding: "16px",
                              border: "solid 1px #f0f0f0",
                            }}
                          >
                            <div>
                              <DetailRow label={`Amount ${speciality? "(Procudure Charge)":"(On Call Charge)"}`} value={formatRateUSD(amount) || "-"} />
                            </div>
                            <div>
                              <DetailRow
                                label="Facility"
                                value={facilityName || "NA"}
                              />
                            </div>
                            <div>
                              <DetailRow
                                label="Specialty"
                                value={speciality || "NA"}
                              />
                            </div>
                            <div>
                              <DetailRow
                                label="Updated By"
                                value={updatedBy || "NA"}
                              />
                            </div>
                            <div>
                              <DetailRow
                                label="Comment"
                                value={comment || "NA"}
                              />
                            </div>
                          </div>
                          {datacount!==i+1 && (
                            <div
                              style={{
                                height: "48px",
                                width: "4px",
                                borderLeft: "dashed 2px rgb(196, 196, 196)",
                                marginLeft: "20px",
                              }}
                            ></div>
                          )}
                        </div>
                      </div>
                    )
                  )}
                {!error && data && datacount !== 0 && (
                  <>
                    {!loading && !noMoreOptions && (
                      <div
                        className="cursor-pointer"
                        onClick={() => {
                          handleScroll();
                        }}
                      >
                        <Caution title="Click to load more." />
                      </div>
                    )}
                    {loading && (
                      <Caution
                        title={
                          <>
                            Loading{" "}
                            <LoaderIcon
                              style={{
                                width: "10px",
                                height: "10px",
                              }}
                            />
                          </>
                        }
                      />
                    )}
                    {!loading && noMoreOptions && (
                      <Caution title="No more entries available." />
                    )}
                  </>
                )}

                {!loading && !error && data && datacount === 0 && (
                  <p className="f-w-300 color-primary flex center-center">
                    No history available.
                  </p>
                )}
              </section>
            </div>
            <div
              className=" flex"
              style={{
                justifyContent: "space-between",
                marginTop: "10px",
              }}
            >
              <ButtonLightTextIcon
                title="Cancel"
                onClick={() => setShow(false)}
                icon={
                  <svg
                    clipRule="evenodd"
                    fillRule="evenodd"
                    strokeLinejoin="round"
                    strokeMiterlimit="2"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    width="19.995"
                    height="19.996"
                  >
                    <path
                      d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 1.5c-4.69 0-8.497 3.807-8.497 8.497s3.807 8.498 8.497 8.498 8.498-3.808 8.498-8.498-3.808-8.497-8.498-8.497zm0 7.425 2.717-2.718c.146-.146.339-.219.531-.219.404 0 .75.325.75.75 0 .193-.073.384-.219.531l-2.717 2.717 2.727 2.728c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.384-.073-.53-.219l-2.729-2.728-2.728 2.728c-.146.146-.338.219-.53.219-.401 0-.751-.323-.751-.75 0-.192.073-.384.22-.531l2.728-2.728-2.722-2.722c-.146-.147-.219-.338-.219-.531 0-.425.346-.749.75-.749.192 0 .385.073.531.219z"
                      fillRule="nonzero"
                    />
                  </svg>
                }
              />
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};
const DetailRow = ({ label, value }) => (
  <div>
    <p
      className="f-w-300 l-h-1"
      style={{
        fontSize: "12px",
        paddingBottom: "4px",
        color: "#aaaaaa",
      }}
    >
      {label}:
    </p>
    <p className="f-w-400 color-primary l-h-1" style={{}}>
      {value}
    </p>
  </div>
);
export default BillHistory;
